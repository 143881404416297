import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
// const vehicleIcon = ['sedan', 'minivan', 'van', 'bus', 'wheelchair', 'stretcher']
const VehicleIcon = ({ vehicleType, size, heading }) => {
  // const [vehicleIconState, setVehicleIconState] = useState(<>&#128661;</>)
  const [fontSize, setFontSize] = useState('fs-20')

  useEffect(() => {
    switch (size) {
      case 'sm':
        setFontSize('fs-13')
        break
      case 'xl':
        setFontSize('fs-20')
        break
      case 'xxl':
        setFontSize('fs-24')
        break
      default:
        setFontSize('fs-20')
    }
  }, [size])

  // useEffect(() => {
  //   console.log(vehicleType)
  //   switch (vehicleType) {
  //     case 'sedan':
  //       setVehicleIconState(<>&#128661;</>)
  //       break
  //     case 'minivan':
  //       setVehicleIconState(<>&#128652;</>)
  //       break
  //     case 'van':
  //       setVehicleIconState(<>&#128656;</>)
  //       break
  //     case 'bus':
  //       setVehicleIconState(<>&#128657;</>)
  //       break
  //     case 'wheelchair':
  //       setVehicleIconState(<>&#128657;</>)
  //       break
  //     case 'stretcher':
  //       setVehicleIconState(<>&#128657;</>)
  //       break
  //     case 'truck':
  //       setVehicleIconState(<>&#128666;</>)
  //       break
  //     default:
  //       setVehicleIconState(<>&#128666;</>)
  //   }
  // }, [vehicleType])

  return (
    <span
      style={{ lineHeight: '0' }}
      className={`d-flex align-content-center align-items-center ${fontSize} rotate-${heading} ${
        heading === 'West' ? '' : 'flip-x'
      }`}
    >
      {vehicleType === 'sedan' ? (
        <>&#128661;</>
      ) : vehicleType === 'minivan' ? (
        <>&#128656;</>
      ) : vehicleType === 'van' ? (
        <>&#128656;</>
      ) : vehicleType === 'bus' ? (
        <>&#128652;</> //<>&#128657;</> //<>&#128652;</>
      ) : vehicleType === 'wheelchair' ? (
        <>&#128657;</>
      ) : vehicleType === 'stretcher' ? (
        <>&#128657;</>
      ) : vehicleType === 'truck' ? (
        <>&#128666;</>
      ) : (
        <>&#128656;</>
      )}
    </span>
  )
}

VehicleIcon.propTypes = {
  vehicleType: PropTypes.any,
  size: PropTypes.any,
  heading: PropTypes.any,
}

export default VehicleIcon
