import { updateSearchTermsAction } from 'src/actions/userActions'
import { isCustomer, isDriver, isSuperadmin } from './check-user-level'
import getPlanPrice from './get-plan-price'
import { tripInfo } from './get-trip-info'
import { formatToHTMLinput, isOnTimeToPickup } from './handle-date'
import handleErr from './handle-error'
import { sortByTrajectoryQueueIdx } from './handle-trajectory'
import isEmpty from './is-empty'
import toggleModal from 'src/components/toggle-modal'
import { GET_VEHICLES_RESET } from 'src/constants'

const allSpacesToOne = (str) => {
  try {
    return str && str.replace ? str.replace(/\s+/g, ' ').trim() : str
  } catch (err) {
    handleErr({ err })
    return str
  }
}

const validateEmail = (text) => text?.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
const validatePhone = (text) => text?.match(/^(\()?\d{3}(\))?(-|\s)?\d{3}(-|\s)\d{4}$/)

// const creatableFilterConfig = { //default
//   ignoreCase: true,
//   ignoreAccents: true,
//   trim: true,
//   // matchFrom: matchFromStart ? ('start' as const) : ('any' as const),
// }
const createCompanyCode = ({ name }) => {
  try {
    return !name || (name && !name.trim()) ? '' : name.split(' ').join('').toLowerCase()
  } catch (err) {
    handleErr({ err })
  }
}

const slowDown = (time) =>
  new Promise((resolve) => {
    const tOut = setTimeout(resolve, time)
  })

const isRide = (trip) => {
  try {
    return trip?.tripType === 'ride'
  } catch (err) {
    handleErr({ err })
    return false
  }
}

const isDelivery = (trip) => {
  try {
    return trip?.tripType === 'delivery'
  } catch (err) {
    handleErr({ err })
    return false
  }
}

const isPending = (trip) => {
  try {
    return trip?.tripState === 'pending'
  } catch (err) {
    handleErr({ err })
    return false
  }
}

const isEnded = (trip) => {
  try {
    return trip?.tripState === 'ended'
  } catch (err) {
    handleErr({ err })
    return false
  }
}

const isCanceled = (trip) => {
  try {
    return trip?.tripState === 'canceled'
  } catch (err) {
    handleErr({ err })
  }
}

const getActiveDrivers = ({ users, includeDeactivated }) => {
  try {
    return users?.length
      ? users
          .filter((u) => isDriver(u))
          .filter((d) => (includeDeactivated ? true : !d.isDeactivated))
          .sort(sortByFirstName)
      : []
  } catch (err) {
    handleErr({ err })
  }
}

const getActiveCustomers = ({ users, allUsersAsCustomers, includeDeactivated }) => {
  try {
    return users?.length
      ? users
          .filter((u) => (allUsersAsCustomers ? true : isCustomer(u)))
          .filter((d) => (includeDeactivated ? true : !d.isDeactivated))
          .sort(sortByFirstName)
      : []
  } catch (err) {
    handleErr({ err })
  }
}

const getActiveVehicles = ({ vehicles, includeDeactivated }) => {
  try {
    return vehicles?.length
      ? vehicles.filter((d) => (includeDeactivated ? true : !d.isDeactivated)).sort(sortByName)
      : []
  } catch (err) {
    handleErr({ err })
  }
}

const isFound = ({ list, id, byKey = '_id' }) => {
  try {
    return list?.length ? list.find((el) => el && el[byKey] === id) : ''
  } catch (err) {
    handleErr({ err })
  }
}

const getIsDriving = ({ trips, driver }) => trips?.some((t) => t?.onTrip && t.driver === driver)
const getTripsPendingByDriver = ({ trips, driver }) =>
  trips?.filter((t) => isPending(t) && t.driver === driver)

const getFullTripDist = ({ trip = {}, idx }) => {
  try {
    const { destinations = [], driverLocations = [] } = trip
    return destinations?.map((dest, i) => {
      return driverLocations?.reduce((acc, dl) => {
        return dl.distance &&
          dl?.locationType?.replace(/^\D+/g, '') &&
          Number(dl?.locationType?.replace(/^\D+/g, '')) === (idx >= 0 ? idx : i) + 1
          ? acc + (dl.distance || 0)
          : acc
      }, 0)
    })
  } catch (err) {
    handleErr({ err })
  }
}

const getPkDpDist = ({ trip = {}, idx }) => {
  try {
    return trip.driverLocations?.find(
      (dl) => dl?.locationType === `isDropoff${!idx || idx === 0 ? 1 : idx}`,
    )?.distance
  } catch (err) {
    handleErr({ err })
  }
}

const currentDriver = ({ trip, idx, users }) => {
  return users?.find(
    (u) =>
      u?._id ===
        trip?.driverHistory?.find(
          (d) =>
            formatToHTMLinput({ dateData: new Date(d?.date) }) >=
              (trip?.times[`startTime${idx >= 0 ? idx : trip?.idx}`] ||
                trip?.times[`pickupTime${idx >= 0 ? idx : trip?.idx}`]) &&
            formatToHTMLinput({ dateData: new Date(d?.date) }) <=
              trip?.times[`dropoffTime${idx >= 0 ? idx : trip?.idx}`],
        )?._id ||
      (trip?.driverHistory?.length === 1 && trip?.driverHistory?.find((d) => d?._id === u?._id)) ||
      u?._id === trip?.driver,
  )
}

const latestStartStopTime = ({ trip = {} }) => {
  try {
    const { timeIdx = 1, times = {} } = tripInfo({ trip }) || {}
    const lTime =
      times[`pickupTime${timeIdx}`] ||
      times[`arriveTime${timeIdx}`] ||
      times[`startTime${timeIdx}`] ||
      times[`expectedStartTime${timeIdx}`] ||
      times[`expectedPickupTime${timeIdx}`] ||
      times[`expectedDropoffTime${timeIdx}`] ||
      times[`expectedStartTime${timeIdx - 1}`] ||
      times[`expectedPickupTime${timeIdx - 1}`] ||
      times[`expectedDropoffTime${timeIdx - 1}`]
    // console.log(lTime, timeIdx)
    return lTime
  } catch (err) {
    handleErr({ err })
  }
}

const closest = ({ goal = Date.parse(formatToHTMLinput({})), arr }) => {
  try {
    return arr.reduce((prev, curr) => {
      return Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev
    })
  } catch (err) {
    handleErr({ err })
  }
}

const sortTripsByLatestTime = (a, b) => {
  try {
    const now = Date.parse(formatToHTMLinput({}))
    const tA = a?.t1Info || a
    const tB = b?.t1Info || b
    const timeA = Date.parse(
      formatToHTMLinput({
        dateData: new Date(latestStartStopTime({ trip: tA })),
      }),
    )
    const timeB = Date.parse(
      formatToHTMLinput({
        dateData: new Date(latestStartStopTime({ trip: tB })),
      }),
    )
    const closetsToNowA = timeA >= now && timeB < now && closest({ arr: [timeA, timeA] }) === timeA
    return !tA?.customerReady || !tB?.customerReady ? 0 : closetsToNowA ? -1 : timeA - timeB
  } catch (err) {
    handleErr({ err })
    return 0
  }
}
// const sortTripsByLatestTime = (a, b) => {
//   try {
//     const tA = a?.t1Info || a
//     const tB = b?.t1Info || b
//     const { destIdxToShow: destIdxToShowA } = tripInfo({ trip: tA })
//     const { destIdxToShow: destIdxToShowB } = tripInfo({ trip: tB })

//     return Date.parse(
//       formatToHTMLinput({
//         dateData: new Date(
//           tA.times[`pickupTime${destIdxToShowA + 1}`] ||
//             tA.times[`arriveTime${destIdxToShowA + 1}`] ||
//             tA.times[`startTime${destIdxToShowA + 1}`] ||
//             tA.times[`expectedStartTime${destIdxToShowA + 1}`] ||
//             tA.times[`expectedPickupTime${destIdxToShowA + 1}`] ||
//             tA.times[`expectedDropoffTime${destIdxToShowA + 1}`],
//         ),
//       }),
//     ) >
//       Date.parse(
//         formatToHTMLinput({
//           dateData: new Date(
//             tB.times[`pickupTime${destIdxToShowB + 1}`] ||
//               tB.times[`arriveTime${destIdxToShowB + 1}`] ||
//               tB.times[`startTime${destIdxToShowB + 1}`] ||
//               tB.times[`expectedStartTime${destIdxToShowB + 1}`] ||
//               tB.times[`expectedPickupTime${destIdxToShowB + 1}`] ||
//               tB.times[`expectedDropoffTime${destIdxToShowB + 1}`],
//           ),
//         }),
//       )
//       ? 0
//       : Date.parse(
//           formatToHTMLinput({
//             dateData: new Date(
//               tB.times[`pickupTime${destIdxToShowB + 1}`] ||
//                 tB.times[`arriveTime${destIdxToShowB + 1}`] ||
//                 tB.times[`startTime${destIdxToShowB + 1}`] ||
//                 tB.times[`expectedStartTime${destIdxToShowB + 1}`] ||
//                 tB.times[`expectedPickupTime${destIdxToShowB + 1}`] ||
//                 tB.times[`expectedDropoffTime${destIdxToShowB + 1}`],
//             ),
//           }),
//         )
//       ? -1
//       : 1
//   } catch (err) {
//     handleErr({ err })
//   }
// }

const sortByPriority = (a, b) => {
  try {
    const { priority: priorityA, routeIndex: routeIndexA } = a?.t1Info || a
    const { priority: priorityB, routeIndex: routeIndexB } = b?.t1Info || b
    // return priorityA === priorityB ? 0 : priorityA ? -1 : 1
    return (routeIndexA >= 0 && routeIndexB >= 0) || priorityA === priorityB
      ? 0
      : priorityA
      ? -1
      : 1
  } catch (err) {
    handleErr({ err })
    return 0
  }
}

const sortByOneDest = (a, b) => {
  try {
    const { destLen, isDropoff } = a?.t1Info || tripInfo({ trip: a })
    // const { destinations: destinationsB } = b?.t1Info || b
    // return priorityA === priorityB ? 0 : priorityA ? -1 : 1
    return destLen === 1 && isDropoff ? -1 : 0
  } catch (err) {
    handleErr({ err })
    return 0
  }
}

const sortByOnTrip = (a, b) => {
  try {
    const { onTrip: onTripA } = a?.t1Info || a || {}
    const { onTrip: onTripB } = b?.t1Info || b || {}
    return (a.routeIndex >= 0 && b.routeIndex >= 0) || onTripA === onTripB ? 0 : onTripA ? -1 : 1
  } catch (err) {
    handleErr({ err })
    return 0
  }
}

const getUserLastLoc = ({ uId, users }) => {
  try {
    return !uId ? null : users?.find((u) => u._id === uId)?.lastLocation
  } catch (err) {
    handleErr({ err })
    return null
  }
}

const getTripDistances = ({ trip, tripChosen, users, companyCoords, tripsDest }) => {
  const tripChosenInfo = tripChosen ? tripInfo({ trip: tripChosen, tripsDest }) : null
  const tripChosenLoc = !tripChosenInfo
    ? null
    : tripChosenInfo?.destData[
        tripChosenInfo?.destLen === 1
          ? 0
          : tripChosenInfo?.isDropoff
          ? tripChosenInfo?.idxToGo
          : tripChosenInfo?.destIdxToShow
      ]
  const driverLastLoc = tripChosenLoc || getUserLastLoc({ uId: trip?.driver, users })
  // console.log(tripChosenLoc?.name)
  // console.log(tripChosenLoc?.location)
  const driverLoc = validCoords({ coords: driverLastLoc?.location })
    ? driverLastLoc?.location
    : companyCoords
  // console.log(driverLoc)

  const t1Info = tripInfo({ trip, tripsDest })
  const t1InfoPkData = t1Info.destData[t1Info?.destLen === 1 ? 0 : t1Info.destIdxToShow]
  const t1InfoDpData =
    t1Info.destData[
      t1Info?.destLen === 1
        ? 0
        : t1Info.isStart || t1Info.isArrive
        ? t1Info.idxToGo + 1
        : t1Info.idxToGo
    ]
  // console.log(t1InfoPkData?.name)
  // console.log(t1InfoPkData?.location)
  const pklat = Number(t1InfoPkData?.location?.lat)
  const pklng = Number(t1InfoPkData?.location?.lng)
  const dplat = Number(t1InfoDpData?.location?.lat)
  const dplng = Number(t1InfoDpData?.location?.lng)
  const pkLocDist = linearDistance({
    lat1: driverLoc?.lat,
    lng1: driverLoc?.lng,
    lat2: pklat,
    lng2: pklng,
  })
  const dpLocDist = linearDistance({
    lat1: driverLoc?.lat,
    lng1: driverLoc?.lng,
    lat2: dplat,
    lng2: dplng,
  })
  // console.log(pkLocDist)
  return {
    t1Info,
    driverLastLoc: driverLoc, //info only
    dest1Name: t1InfoPkData?.name,
    dest2Name: t1InfoDpData?.name,
    headingToPk: angle({
      lat1: driverLoc?.lat,
      lng1: driverLoc?.lng,
      lat2: pklat,
      lng2: pklng,
    }),
    headingToDp: angle({
      lat1: driverLoc?.lat,
      lng1: driverLoc?.lng,
      lat2: dplat,
      lng2: dplng,
    }),
    headingFromPkToDp: angle({
      lat1: t1Info?.destLen === 1 || pklat === dplat ? driverLoc?.lat : pklat,
      lng1: t1Info?.destLen === 1 || pklng === dplng ? driverLoc?.lng : pklng,
      lat2: dplat,
      lng2: dplng,
    }),
    pkToDpDist: linearDistance({
      lat1: t1Info?.destLen === 1 || pklat === dplat ? driverLoc?.lat : pklat,
      lng1: t1Info?.destLen === 1 || pklng === dplng ? driverLoc?.lng : pklng,
      lat2: dplat,
      lng2: dplng,
    }),
    pkLocDist,
    // t1Info?.destLen > 1 &&
    // (t1Info?.isPickup || t1Info?.isDropoff) &&
    // // t1Info?.progress >= 2 &&
    // // driverLoc?.lat === pklat &&
    // // driverLoc?.lng === pklng
    // isNearBy({ distance: pkLocDist })
    //   ? dpLocDist
    //   : pkLocDist,
    dpLocDist,
  }
}

const tripsWithDists = ({ trips, tripChosen, users, companyCoords, tripsDest }) =>
  trips?.map((t) => ({
    ...t,
    ...getTripDistances({
      trip: t,
      tripChosen,
      companyCoords,
      users,
      tripsDest,
    }),
  }))

const nextDestDist = (a, b) => {
  try {
    const tInfoA = a?.t1Info || tripInfo({ trip: a })
    const tInfoB = b?.t1Info || tripInfo({ trip: b })
    // console.log(tInfoA, tInfoB)
    const {
      isStart: isStartA,
      isArrive: isArriveA,
      isPickup: isPickupA,
      isDropoff: isDropoffA,
    } = tInfoA
    const {
      isStart: isStartB,
      isArrive: isArriveB,
      isPickup: isPickupB,
      isDropoff: isDropoffB,
    } = tInfoB
    const distA =
      isStartA || a?.isStart || isArriveA || a?.isArrive
        ? a?.pkLocDist || a?.dist || { meter: 10000000 }
        : isPickupA || a?.isPickup || isDropoffA || a?.isDropoff
        ? a?.dpLocDist || a?.dist || { meter: 10000000 }
        : { meter: 10000000 }
    const distB =
      isStartB || b?.isStart || isArriveB || b?.isArrive
        ? b?.pkLocDist || b?.dist || { meter: 10000000 }
        : isPickupB || b?.isPickup || isDropoffB || b?.isDropoff
        ? b?.dpLocDist || b?.dist || { meter: 10000000 }
        : { meter: 10000000 }

    // console.log(distA)
    // console.log(distB)
    // console.log('--------------------------')
    const { meter: meterA } = distA
    const { meter: meterB } = distB
    return { meterA, meterB }
  } catch (err) {
    handleErr({ err })
    return { meterA: 10000000, meterB: 10000000 }
  }
}

const sortByDistance = (a, b) => {
  try {
    const { meterA, meterB } = nextDestDist(a, b)
    // console.log(meterA, meterB)
    // const tInfoA = a?.t1Info || tripInfo({ trip: a })
    // const tInfoB = b?.t1Info || tripInfo({ trip: b })

    // const {
    //   isStart: isStartA,
    //   isArrive: isArriveA,
    //   isPickup: isPickupA,
    //   isDropoff: isDropoffA,
    // } = tInfoA
    // const {
    //   isStart: isStartB,
    //   isArrive: isArriveB,
    //   isPickup: isPickupB,
    //   isDropoff: isDropoffB,
    // } = tInfoB
    // const distA =
    //  isStartA // || isArriveA
    //   ? a?.pkLocDist || a?.dist || a
    //   : isPickupA || isDropoffA
    //   ? a?.dpLocDist || a?.dist || a
    //   : {}
    // const distB =
    // isStartB // || isArriveB
    //   ? b?.pkLocDist || b?.dist || b
    //   : isPickupB || isDropoffB
    //   ? b?.dpLocDist || b?.dist || b
    //   : {}

    // const { meter: meterA = 0 } = distA
    // const { meter: meterB = 0 } = distB
    // return isArriveA || isArriveB ? 0 : meterA - meterB
    // console.log(meterA, meterB)
    return meterA === meterB ? 0 : meterA < meterB ? -1 : 1
  } catch (err) {
    handleErr({ err })
    return 0
  }
}

const sortByStart = (a, b) => {
  try {
    const { isStart: isStartA } = a?.t1Info || tripInfo({ trip: a })
    const { isStart: isStartB } = b?.t1Info || tripInfo({ trip: b })
    // return isStartA === isStartB ? 0 : isStartA ? -1 : 1
    return isStartA === isStartB || !isStartA ? 0 : sortByDistance(a, b)
  } catch (err) {
    handleErr({ err })
    return 0
  }
}

const sortByPkTime = (a, b, leg) => {
  try {
    const tripA = a?.t1Info || a?.t2Info || a
    const tripB = b?.t1Info || b?.t2Info || b
    const {
      timeIdx: idxA,
      // destIdxToShow: idxA,
      isStart: isStartA,
      isArrive: isArriveA,
      isPickup: isPickupA,
      isDropoff: isDropoffA,
    } = tripInfo({ trip: tripA })
    const {
      timeIdx: idxB,
      // destIdxToShow: idxB,
      isStart: isStartB,
      isArrive: isArriveB,
      isPickup: isPickupB,
      isDropoff: isDropoffB,
    } = tripInfo({ trip: tripB })
    const tA = new Date(
      tripA?.times[`expectedStartTime${leg || idxA || 1}`] ||
        tripA?.times[`expectedPickupTime${leg || idxA || 1}`],
    )
    const tB = new Date(
      tripB?.times[`expectedStartTime${leg || idxB || 1}`] ||
        tripB?.times[`expectedPickupTime${leg || idxB || 1}`],
    )
    // const tA = new Date(
    //   isStartA || isArriveA
    //     ? tripA?.times[`expectedStartTime${idxA || 1}`] ||
    //       tripA?.times[`expectedPickupTime${idxA || 1}`]
    //     : 0,
    // )
    // const tB = new Date(
    //   isStartB || isArriveB
    //     ? tripB?.times[`expectedStartTime${idxB || 1}`] ||
    //       tripB?.times[`expectedPickupTime${idxB || 1}`]
    //     : 0,
    // )
    // return isArriveA ||
    //   isArriveB ||
    //   isPickupA ||
    //   isPickupB ||
    //   isDropoffA ||
    //   isDropoffB ||
    //   !tA?.customerReady ||
    //   !tB?.customerReady
    //   ? 0
    // console.log('isStartA: ', isStartA)
    // console.log('idxA: ', idxA, tA)
    // console.log('idxB: ', idxA, tB)

    return (isPickupA && isPickupB) || (isDropoffA && isDropoffB) || tA === tB
      ? 0
      : tA < tB
      ? -1
      : 1
  } catch (err) {
    handleErr({ err })
    return 0
  }
}

const sortByRouteIndex = ({ a, b, leg = 1 }) => {
  try {
    const { routeIndex: aRIdx, tripState: tStateA } = a?.t1Info || a
    const { routeIndex: bRIdx, tripState: tStateB } = b?.t1Info || b
    // console.log(a, aRIdx, b, bRIdx, aRIdx === bRIdx ? 0 : aRIdx < bRIdx ? -1 : 1)

    // return sortedByTrajectoryQueueIdx !== 0
    //   ? sortedByTrajectoryQueueIdx
    return (tStateA === tStateB && tStateA !== 'pending') || aRIdx === bRIdx
      ? 0
      : aRIdx < bRIdx
      ? -1
      : 1
  } catch (err) {
    handleErr({ err })
    return 0
  }
}

const sortByPickupDistance = (a, b) => {
  try {
    const tInfoA = a?.t1Info || tripInfo({ trip: a })
    const tInfoB = b?.t1Info || tripInfo({ trip: b })
    const { isPickup: isPkA, isStart: isStartA, priority: priorityA } = tInfoA
    const { isPickup: isPkB, isStart: isStartB, priority: priorityB } = tInfoB
    return priorityA || priorityB
      ? sortByPriority(a, b)
      : (isPkA && isPkB) || (isPkA && isStartB) || (isPkB && isStartA)
      ? sortByDistance(a, b)
      : 0
    // return sortByDistance(a, b)
  } catch (err) {
    handleErr({ err })
    return 0
  }
}

const sortByDropoff = (a, b) => {
  try {
    const { isDropoff: isDropoffA } = a?.t1Info || tripInfo({ trip: a })
    const { isDropoff: isDropoffB } = b?.t1Info || tripInfo({ trip: b })
    return isDropoffA === isDropoffB ? 0 : isDropoffA ? -1 : 1
  } catch (err) {
    handleErr({ err })
    return 0
  }
}

const sortByArrive = (a, b) => {
  try {
    const { isArrive: isArriveA } = a?.t1Info || tripInfo({ trip: a })
    const { isArrive: isArriveB } = b?.t1Info || tripInfo({ trip: b })
    return isArriveA === isArriveB ? 0 : isArriveA ? -1 : 1
  } catch (err) {
    handleErr({ err })
    return 0
  }
}

const sortByNext = (a, b) => {
  try {
    const { isNext: isNextA } = a?.t1Info || a
    const { isNext: isNextB } = b?.t1Info || b
    // console.log(isNextA, isNextB)
    return isNextA === isNextB ? 0 : isNextA ? -1 : 1
  } catch (err) {
    handleErr({ err })
    return 0
  }
}

const sortByCustomerReady = (a, b) => {
  try {
    const { customerReady: customerReadyA, driver: driverA } = a?.t1Info || a
    const { customerReady: customerReadyB, driver: driverB } = b?.t1Info || b
    // console.log(driverA, driverB)
    return customerReadyA === customerReadyB || driverA || driverB ? 0 : customerReadyA ? -1 : 1
  } catch (err) {
    handleErr({ err })
    return 0
  }
}

const sortByDriver = (a, b) => {
  try {
    const { driver: driverA } = a?.t1Info || a
    const { driver: driverB } = b?.t1Info || b
    // console.log(isNextA, isNextB)
    return driverA === driverB ? 0 : driverA ? -1 : 1
  } catch (err) {
    handleErr({ err })
    return 0
  }
}

const sortByEnded = (a, b) => {
  try {
    const { tripState: tripStateA } = a?.t1Info || a
    const { tripState: tripStateB } = b?.t1Info || b
    // console.log(isNextA, isNextB)
    return tripStateA === tripStateB ? 0 : tripStateA === 'ended' ? 1 : -1
  } catch (err) {
    handleErr({ err })
    return 0
  }
}

const sortByCanceled = (a, b) => {
  try {
    const { tripState: tripStateA } = a?.t1Info || a
    const { tripState: tripStateB } = b?.t1Info || b
    // console.log(isNextA, isNextB)
    return tripStateA === tripStateB ? 0 : tripStateA === 'canceled' ? 1 : -1
  } catch (err) {
    handleErr({ err })
    return 0
  }
}

const sortByName = (a, b) => {
  try {
    const nameA = a?.name || a?.firstName || a
    const nameB = b?.name || b?.firstName || b
    return nameA.localeCompare(nameB)
  } catch (err) {
    handleErr({ err })
    return 0
  }
}

const sortByFirstName = (a, b) => {
  try {
    if (!a || !a?.firstName) return 1
    return a?.firstName?.localeCompare(b?.firstName)
  } catch (err) {
    handleErr({ err })
    return 0
  }
}

const isGroup = ({ trip }) => {
  try {
    return trip?.times
      ? Object.entries(trip.times).some(([key, val]) => {
          const { timeIdx } = tripInfo({ trip })
          const keyIdx = key.replace(/^\D+/g, '') ? Number(key.replace(/^\D+/g, '')) : ''
          return key.indexOf('group') !== -1 && keyIdx === timeIdx
        })
      : false
  } catch (err) {
    handleErr({ err })
  }
}

const isNotGroupPickedUp = ({ trip }) => {
  const { timeIdx: timeIdxPendingQ, times: timesPendingQ = {} } = tripInfo({ trip })
  return Array.from(Object.keys(timesPendingQ))?.some((tTime) => {
    return (
      tTime?.indexOf(`groupPkTime${timeIdxPendingQ}`) === -1 &&
      !timesPendingQ[`groupPkTime${timeIdxPendingQ}`]
    )
  })
}

const getTripCust = ({ users, id }) => {
  try {
    return users?.length ? users.find((cust) => cust?._id === id) : ''
  } catch (err) {
    handleErr({ err })
  }
}

const getFullName = ({ user }) => {
  try {
    const { firstName, middleName, lastName } = user || {}
    return `${firstName || ''} ${middleName || ''} ${lastName || ''}`?.replace(/  +/g, ' ')?.trim()
  } catch (err) {
    handleErr({ err })
  }
}

const getNameInitials = ({ name }) => {
  try {
    if (!name) return ''
    const rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu')
    const initials = [...name?.matchAll(rgx)] || []
    return ((initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')).toUpperCase()
  } catch (err) {
    handleErr({ err })
  }
}

const shortName = ({ user, maxLen }) => {
  try {
    const { firstName, lastName } = user || {}
    const lastNameParts = lastName?.split(' ')
    const shortN = `${firstName || ''} ${
      lastNameParts?.length && lastNameParts[0] ? lastNameParts[0][0]?.toUpperCase() : ''
    }${
      lastNameParts?.length && lastNameParts[1] ? `${lastNameParts[1][0]?.toUpperCase()}.` : ''
    }`.trim()
    return !maxLen
      ? shortN
      : `${shortN?.substring(0, maxLen)?.trim() ? `${shortN?.substring(0, maxLen)?.trim()}.` : ''}`
  } catch (err) {
    handleErr({ err })
  }
}

const getPhone = ({ data }) => {
  try {
    const { cellPhone, homePhone, phone } = data || {}
    return cellPhone || homePhone || phone
  } catch (err) {
    handleErr({ err })
  }
}

const getFullAddress = ({ data, showCountry }) => {
  try {
    const { address, city, state, zipcode, country } = data || {}
    return `${address ? `${address},` : ''} ${city ? `${city},` : ''} ${state ? `${state},` : ''} ${
      zipcode ? `${zipcode}` : ''
    }${showCountry && country ? `, ${country}` : ''}`
      ?.replace(/  +/g, ' ')
      ?.trim()
  } catch (err) {
    handleErr({ err })
  }
}

const getTripDestination = ({ tripsDest, tripDestId }) => {
  try {
    return tripsDest?.length ? tripsDest.find((dest) => dest?._id === tripDestId) : ''
  } catch (err) {
    handleErr({ err })
  }
}

const linearDistance = ({ lat1, lng1, lat2, lng2 }) => {
  try {
    const deg2rad = (deg) => deg * (Math.PI / 180)
    const R = 6371 // Radius of the earth in km
    const dLat = deg2rad(Number(lat2) - Number(lat1)) // deg2rad below
    const dLon = deg2rad(Number(lng2) - Number(lng1))
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(Number(lat1))) *
        Math.cos(deg2rad(Number(lat2))) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2)
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    const d = R * c // Distance in km
    return {
      mile: Number((d / 1.609).toFixed(1)) || 0,
      km: Number(d.toFixed(1)) || 0,
      meter: Number((d * 1000).toFixed(1)) || 0,
    }
  } catch (err) {
    handleErr({ err })
  }
}

const isNearBy = ({ distance, meter = 150 }) =>
  distance?.meter === 0 || distance?.meter === '0' || distance.meter <= meter
    ? true
    : distance
    ? distance <= meter
    : false

// get objs different from arr1
const getRemainingItems = (arr1, arr2) => {
  try {
    return arr1?.filter((el) => !arr2?.find((element) => element?._id === el?._id))
  } catch (err) {
    handleErr({ err })
  }
}

const ObjOrdered = (unordered) => {
  try {
    const objReduced = (uo) =>
      Object.keys(uo)
        .sort()
        .reduce((obj, key) => {
          obj[key] = uo[key]
          return obj
        }, {})
    return !unordered ? '' : unordered?.length ? unordered.map(objReduced) : objReduced(unordered)
  } catch (err) {
    handleErr({ err })
  }
}

const areEquals = (a, b) => {
  try {
    if (a?.length && b?.length) return JSON.stringify(a) === JSON.stringify(b)
    return (
      (!a && !b) ||
      (a &&
        b &&
        (ObjOrdered(a) === ObjOrdered(b) ||
          JSON.stringify(ObjOrdered(a)) === JSON.stringify(ObjOrdered(b))))
    )
  } catch (err) {
    handleErr({ err })
  }
}

const sameLocations = (a = {}, b = {}) => {
  try {
    const { lng: lngA, lat: latA } = a
    const { lng: lngB, lat: latB } = b
    return +lngA === +lngB && +latA === +latB
  } catch (err) {
    handleErr({ err })
    return false
  }
}

const arrUniq = (arr) => [...new Set(arr)]

const uniqueById = (arr) => {
  try {
    const map = new Map()
    return arr?.filter((t) => {
      if (t?._id && map.get(t?._id)) return false
      t?._id && map.set(t._id, t._id)
      return t?._id ? true : false
    })
  } catch (err) {
    handleErr({ err })
    return arr
  }
}

const uniqueByKey = ({ arr, key }) => {
  try {
    const map = new Map()
    return arr?.filter
      ? arr?.filter((item) => {
          if (map.get(item[key])) return false
          map.set(item[key], item)
          return true
        })
      : arr
  } catch (err) {
    handleErr({ err })
  }
}

const isScheduled = (trip) => {
  try {
    const { schedule } = trip || {}
    return schedule && schedule.length ? true : false
  } catch (err) {
    handleErr({ err })
    return false
  }
}

const isSameCustomerAndPk = ({ bookedTrip, trips }) => {
  try {
    return trips?.find((t) => {
      const { customer, times = {} } = t || {}
      const { expectedStartTime1: st1, expectedPickupTime1: pk1, expectedDropoffTime1: dp1 } = times
      const { customer: tripCustomer, times: tripTimes = {} } = bookedTrip || {}
      const {
        expectedStartTime1: st2,
        expectedPickupTime1: pk2,
        expectedDropoffTime1: dp2,
      } = tripTimes
      return (
        String(customer) === String(tripCustomer) &&
        Date.parse(pk1) === Date.parse(pk2) &&
        Date.parse(dp1) === Date.parse(dp2)
      )
    })
  } catch (err) {
    handleErr({ err })
  }
}

const sortRouteAI = ({ trips, leg }) => {
  try {
    return (
      uniqueById(trips)
        ?.sort(sortByDistance)
        .sort((a, b) => sortByPkTime(a, b, leg))
        .sort(sortByStart)
        .sort(sortByOneDest)
        .sort(sortByPriority)
        .sort((a, b) => sortByRouteIndex({ a, b, leg }))
        .sort(sortByPickupDistance)
        .sort((a, b) => sortByTrajectoryQueueIdx({ a, b, leg }))
        // .sort(sortByDistance)
        // .sort(sortByPkTime)
        .sort(sortByDropoff)
        .sort(sortByArrive)
    )
  } catch (err) {
    handleErr({ err })
    return trips
  }
}

const uniqueByCustomerAndPickup = (trips) => {
  try {
    const map = new Map()
    return uniqueById(trips)
      ?.sort((a, b) => (a.tripState === b.tripState ? 0 : isCanceled(a) ? -1 : 1))
      .sort((a, b) => (a.tripState === b.tripState ? 0 : isEnded(a) ? -1 : 1))
      .sort((a, b) => (a.tripState === b.tripState ? 0 : isPending(a) ? -1 : 1))
      .sort((a, b) => (isScheduled(a) === isScheduled(b) ? 0 : isScheduled(b) ? -1 : 1))
      .filter((trip) => {
        const { expectedStartTime1, expectedPickupTime1, expectedDropoffTime1 } = trip.times || {}
        // console.log({ expectedStartTime1, expectedPickupTime1, expectedDropoffTime1 })
        const mapKey = `${String(trip.customer)}_${
          expectedStartTime1 || ''
        }_${expectedPickupTime1}_${expectedDropoffTime1}`
        if (map.get(mapKey)) return false
        map.set(mapKey, mapKey)
        return true
      })
  } catch (err) {
    handleErr({ err })
  }
}

const groupBy = (objectArray, property) => {
  try {
    return objectArray.reduce((r, a) => {
      r[a[property]] = r[a[property]] || []
      r[a[property]].push(a)
      return r
    }, Object.create(null))
  } catch (err) {
    handleErr({ err })
  }
}

const docEl = ({ id, elClass, tagName, all }) => {
  try {
    return id
      ? document.getElementById(id)
      : elClass && all
      ? document.querySelectorAll(`.${elClass}`)
      : elClass
      ? document.querySelector(`.${elClass}`)
      : tagName && all
      ? document.getElementsByTagName(`${tagName}`)
      : tagName
      ? document.getElementsByTagName(`${tagName}`)[0]
      : ''
  } catch (err) {
    handleErr({ err })
  }
}

const calcOilLife = ({ oilLife, odo1, odo2 }) => {
  try {
    const milesDiff = +odo2 > +odo1 ? +odo2 - +odo1 : ''
    return milesDiff ? oilLife - milesDiff : oilLife
  } catch (err) {
    handleErr({ err })
  }
}

const userIsTripDriver = ({ trip, id }) => {
  try {
    const { driver, driverHistory } = trip || {}
    return (
      (isPending(trip) && String(id) === String(driver)) ||
      (!isPending(trip) && driverHistory?.some((d) => String(d?._id) === String(id)))
    )
  } catch (err) {
    handleErr({ err })
  }
}

const debounce = (fn, delay) => {
  const myObj = {}
  return function (...args) {
    if (myObj.timerId) clearTimeout(myObj.timerId)
    myObj.timerId = setTimeout(() => {
      fn(...args)
    }, delay)
  }
}

const onlyDestinations = (dests) => {
  try {
    return dests?.length
      ? dests
          .filter((d) => !d?.level) //no customers
          .sort(sortByName)
          .sort((a, b) => (a.isDeactivated === b.isDeactivated ? 0 : b.isDeactivated ? -1 : 1))
      : ''
  } catch (err) {
    handleErr({ err })
  }
}

const onlyUsers = (dests) => {
  try {
    return dests?.length
      ? dests
          .filter((d) => d?.level) //customers
          .sort(sortByName)
          .sort((a, b) => (a.isDeactivated === b.isDeactivated ? 0 : b.isDeactivated ? -1 : 1))
      : ''
  } catch (err) {
    handleErr({ err })
  }
}

const getCompanyName = ({ list, compId }) => {
  try {
    return list?.find((c) => c?._id === compId)?.name
  } catch (err) {
    handleErr({ err })
  }
}

const validVin = (vin) => {
  const re = new RegExp('^[A-HJ-NPR-Z\\d]{8}[\\dX][A-HJ-NPR-Z\\d]{2}\\d{6}$')
  return vin.match(re)
}

const uInfo = (userInfo) => {
  try {
    return {
      _id: userInfo?._id,
      company: userInfo?.company?._id || userInfo?.company,
      level: userInfo?.level,
      firstName: userInfo?.firstName,
      lastName: userInfo?.lastName,
      facility: userInfo?.facility,
    }
  } catch (err) {
    handleErr({ err })
  }
}

const usersByLevel = ({ level, users = [], all }) => {
  try {
    return users.filter((u) => u?.level === level)?.filter((u) => (all ? true : !u?.isDeactivated))
  } catch (err) {
    handleErr({ err })
    return []
  }
}

const isDisability = (customerOrVehicleData) => {
  try {
    const { features = [], level } = customerOrVehicleData || {}
    const hasDisability = features?.length
    const wheelchair = features.some((f) => f?.toLowerCase() === 'wheelchair')
    const bariatricWheelchair = features.some((f) => f === 'bariatricWheelchair')
    const stretcher = features.some((f) => f?.toLowerCase() === 'stretcher')
    const bariatricStretcher = features.some((f) => f === 'bariatricStretcher')
    const bariatric =
      !bariatricWheelchair &&
      !bariatricStretcher &&
      level === 'Customer' &&
      customerOrVehicleData?.weight &&
      Number(customerOrVehicleData?.weight) >= 250
        ? true
        : false
    if (!hasDisability && !bariatric) return false
    const walker = features.some((f) => f?.toLowerCase() === 'walker')
    const blind = features.some((f) => f?.toLowerCase() === 'blind')
    const deaf = features.some((f) => f?.toLowerCase() === 'deaf')
    return {
      bariatric,
      wheelchair,
      bariatricWheelchair,
      stretcher,
      bariatricStretcher,
      walker,
      deaf,
      blind,
    }
  } catch (err) {
    handleErr({ err })
  }
}

const matchCustomerVehicle = ({ trip, customerData, vehicleData }) => {
  try {
    return isEmpty(trip) ||
      trip?.tripType === 'delivery' ||
      isEmpty(customerData) ||
      !customerData?.features?.length ||
      isEmpty(vehicleData) // if error
      ? true
      : (isDisability(customerData)?.bariatricWheelchair &&
          !isDisability(vehicleData)?.bariatricWheelchair) ||
        (isDisability(customerData)?.wheelchair &&
          !isDisability(vehicleData)?.wheelchair &&
          !isDisability(vehicleData)?.bariatricWheelchair) ||
        (isDisability(customerData)?.stretcher &&
          !isDisability(vehicleData)?.stretcher &&
          !isDisability(vehicleData)?.bariatricStretcher) ||
        (isDisability(customerData)?.bariatricStretcher &&
          !isDisability(vehicleData)?.bariatricStretcher)
      ? false
      : true
  } catch (err) {
    handleErr({ err })
  }
}

const matchCustomerVehicleMsg = ({ customerData, vehicleData }) => {
  try {
    const { stretcher, bariatricStretcher, wheelchair, bariatricWheelchair } =
      isDisability(customerData)
    return `Error! ${vehicleData?.name} is NOT acceptable for ${getFullName({
      user: customerData,
    })}. Please use another vehicle ${
      stretcher || wheelchair || bariatricStretcher || bariatricWheelchair
        ? `with ${
            stretcher
              ? 'Stretcher'
              : bariatricStretcher
              ? 'Bariatric Stretcher'
              : bariatricWheelchair
              ? 'Bariatric Wheelchair'
              : 'Wheelchair'
          }`
        : ''
    }.`
  } catch (err) {
    handleErr({ err })
  }
}

const getFeature = (customerOrVehicleData) => {
  try {
    const { features = [] } = customerOrVehicleData || {}
    const bariatricStretcher = features.find(
      (f) => f?.toLowerCase() === 'bariatricStretcher'?.toLowerCase(),
    )
    if (bariatricStretcher) return bariatricStretcher
    const stretcher = features.find((f) => f?.toLowerCase() === 'stretcher'?.toLowerCase())
    if (stretcher) return stretcher
    const bariatricWheelchair = features.find(
      (f) => f?.toLowerCase() === 'bariatricWheelchair'?.toLowerCase(),
    )
    if (bariatricWheelchair) return bariatricWheelchair
    const wheelchair = features.find((f) => f?.toLowerCase() === 'wheelchair'?.toLowerCase())
    if (wheelchair) return wheelchair
    const walker = features.find((f) => f?.toLowerCase() === 'walker'?.toLowerCase())
    if (walker) return walker
    const deaf = features.find((f) => f?.toLowerCase() === 'deaf'?.toLowerCase())
    if (deaf) return deaf
    const blind = features.find((f) => f?.toLowerCase() === 'blind'?.toLowerCase())
    if (blind) return blind
    return ''
    // switch (features) {
    // case features.some((f) => f?.toLowerCase() === 'bariatricStretcher'?.toLowerCase()):
    //   return 'bariatricStretcher'
    // case features.some((f) => f?.toLowerCase() === 'stretcher'):
    //   return 'stretcher'
    // case features.some((f) => f?.toLowerCase() === 'bariatricWheelchair'?.toLowerCase()):
    //   return 'bariatricWheelchair'
    // case features.some((f) => f?.toLowerCase() === 'wheelchair'):
    //   return 'wheelchair'
    // case features.some((f) => f?.toLowerCase() === 'walker'):
    //   return 'walker'
    // case features.some((f) => f?.toLowerCase() === 'deaf'):
    //   return 'deaf'
    // case features.some((f) => f?.toLowerCase() === 'blind'):
    //   return 'blind'
    // default:
    //   return ''
    // }
  } catch (err) {
    handleErr({ err })
  }
}

const featuresToFare = ({ cust, trip }) => {
  try {
    const ft = getFeature(cust)
    const { tripType } = trip || {}
    return !ft || ft === 'walker' || ft === 'deaf' || ft === 'blind'
      ? tripType === 'ride'
        ? 'standard'
        : tripType // delivery
      : ft
  } catch (err) {
    handleErr({ err })
  }
}

const radians = (n) => n * (Math.PI / 180)
const degrees = (n) => n * (180 / Math.PI)

const angle = ({ lat1, lng1, lat2, lng2 }) => {
  try {
    // function getBearing(startLat, startLong, endLat, endLong) {
    const startLat = radians(Number(lat1))
    const startLong = radians(Number(lng1))
    const endLat = radians(Number(lat2))
    const endLong = radians(Number(lng2))

    var dLong = endLong - startLong

    var dPhi = Math.log(
      Math.tan(endLat / 2.0 + Math.PI / 4.0) / Math.tan(startLat / 2.0 + Math.PI / 4.0),
    )
    if (Math.abs(dLong) > Math.PI) {
      if (dLong > 0.0) dLong = -(2.0 * Math.PI - dLong)
      else dLong = 2.0 * Math.PI + dLong
    }

    return (degrees(Math.atan2(dLong, dPhi)) + 360.0) % 360.0
  } catch (err) {
    handleErr({ err })
  }
}

const validCoords = ({ coords }) => {
  try {
    const coordLat = coords?.lat || coords?.latitude
    const coordLng = coords?.lng || coords?.lon || coords?.longitude
    return (
      coordLat &&
      +coordLat !== 0 &&
      +coordLat !== -1000 &&
      coordLng &&
      +coordLng !== 0 &&
      +coordLng !== -1000
    )
  } catch (err) {
    handleErr({ err })
  }
}

const modifyDeliveryTripPkTime = ({ tripsData, updateTrip, userInfo }) => {
  try {
    const countTrip = { qtt: 1 }
    return tripsData?.map((tr, i, arr) => {
      const equalizeTimes = { time: {} }
      if (isDelivery(tr) && isPending(tr)) {
        const tripsBefore = arr
          .filter(
            (t, aidx) => t?._id !== tr?._id && aidx < i && String(t?.driver) === String(tr?.driver),
          )
          .reverse()
        // const rideAfter = arr.filter(
        //   (t, aidx) => aidx > i && String(t?.driver) === String(tr?.driver) && isRide(tr),
        // )[0]
        const latestTimeTripBefore = tripsBefore[0]
        // const latestTimeTripBefore = tripsBefore?.sort(sortTripsByLatestTime)?.reverse()[0]
        // const latestTimeTripAfter = rideAfter?.sort(sortTripsByLatestTime)?.reverse()[0]
        // const latestPkTrip = tripsBefore?.sort(sortByPkTime)?.reverse()[0]
        if (
          !isEmpty(latestTimeTripBefore) &&
          !isEmpty(latestTimeTripBefore.times) &&
          String(latestTimeTripBefore?.driver) === String(tr?.driver)
        ) {
          const { timeIdx = 1, times } = tripInfo({ trip: tr })
          // const { timeIdx: latestTimeIdx } = tripInfo({ trip: latestTimeTripBefore })
          const tTime = latestStartStopTime({ trip: tr })
          // tr?.times[`expectedStartTime${timeIdx}`] || tr?.times[`expectedPickupTime${timeIdx}`]
          const latestPkAvDpTimeBefore = latestStartStopTime({ trip: latestTimeTripBefore })
          // const latestPkAvDpTimeAfter = rideAfter ? latestStartStopTime({ trip: rideAfter }) : ''
          // latestPkTrip?.times[`expectedStartTime${latestTimeIdx}`] ||
          // latestPkTrip?.times[`expectedPickupTime${latestTimeIdx}`]
          // console.log(tTime, latestPkAvDpTimeBefore)
          if (
            new Date(tTime) < new Date(latestPkAvDpTimeBefore)
            // ||
            // (latestTimeTripBefore?.tripType === 'ride' &&
            //   (!latestPkAvDpTimeAfter || (latestPkAvDpTimeAfter && tTime >= latestPkAvDpTimeAfter)))
          ) {
            equalizeTimes.time = {
              [`expectedStartTime${timeIdx}`]: times[`expectedStartTime${timeIdx}`]
                ? latestPkAvDpTimeBefore
                : null,
              [`expectedPickupTime${timeIdx}`]: latestPkAvDpTimeBefore,
              [`expectedDropoffTime${timeIdx}`]: latestPkAvDpTimeBefore,
            }
            arr[i] = {
              ...arr[i],
              times: {
                ...arr[i].times,
                ...equalizeTimes.time,
              },
            }
          }
          if (
            updateTrip &&
            userIsTripDriver({ trip: tr, id: userInfo?._id }) &&
            !areEquals(tr.times, arr[i].times)
          ) {
            setTimeout(() => {
              updateTrip({
                trips: [tr],
                formData: {
                  time: equalizeTimes.time,
                },
                autoUpdate: true,
              })
            }, countTrip * 3303)
            countTrip.qtt++
          }
        }
      }
      return {
        ...tr,
        times: {
          ...tr.times,
          ...equalizeTimes.time,
        },
        // autoRouted: false,
      }
      // }
    })
  } catch (err) {
    handleErr({ err })
  }
}

const cleanTrip = (trip) => {
  if (isEmpty(trip)) return trip
  const tCopy = JSON.parse(JSON.stringify(trip))
  delete tCopy.editors
  delete tCopy.notes
  delete tCopy.destData
  delete tCopy.dest1
  delete tCopy.dest2
  delete tCopy.tripCustomer
  delete tCopy.inspectionState
  delete tCopy.removeDriver
  delete tCopy.createRoute
  delete tCopy.destIdxToShow
  delete tCopy.destPending
  delete tCopy.destLen
  delete tCopy.idxToGo
  delete tCopy.isStart
  delete tCopy.isArrive
  delete tCopy.isPickup
  delete tCopy.isDropoff
  delete tCopy.lastProgress
  delete tCopy.lastProgress
  delete tCopy.timeIdx
  delete tCopy.stepVal
  delete tCopy.expectedPickupTime1
  delete tCopy.expectedDropoffTime1
  return tCopy
}

const cleanTrips = (trips) => {
  try {
    if (trips?.length >= 0) return trips.map((t) => cleanTrip(t))
    if (!isEmpty(trips)) return cleanTrip(trips)
  } catch (err) {
    handleErr({ err })
  }
}

const duplicateTrip = ({ trip, newDate, userInfo }) => {
  try {
    const tCleaned = cleanTrip(trip)
    delete tCleaned._id
    delete tCleaned.createdAt
    delete tCleaned.driverHistory
    delete tCleaned.driverLocations

    const toRemove = ['startTime', 'arriveTime', 'pickupTime', 'dropoffTime', 'group']

    const filteredTimes = Object.keys(trip?.times)
      .filter((key) => !toRemove.find((x) => key.indexOf(x) !== -1))
      .reduce((obj, key) => {
        const newDay = new Date(newDate).getDate()
        const newMonth = new Date(newDate).getMonth()
        const newYear = new Date(newDate).getFullYear()
        const newTime = new Date(trip?.times[key])
        newTime.setDate(newDay)
        newTime.setMonth(newMonth)
        newTime.setFullYear(newYear)
        obj[key] = formatToHTMLinput({ dateData: newTime })
        return obj
      }, {})
    return {
      ...tCleaned,
      creator: {
        id: userInfo?._id,
        name: getFullName({ user: userInfo }),
      },
      onTrip: false,
      times: filteredTimes,
      tripState: 'pending',
      customerReady: true,
      started: false,
      routeIndex: -1,
      confirmed: false,
      requested: false,
      sendTo: [],
      sendToBcc: [],
      downloadedManifest: {},
      downloadedInvoice: {},
    }
  } catch (err) {
    handleErr({ err })
  }
}

const getPlanName = ({ agreement }) => agreement?.description?.split(' ')[1]
const getOwnGps = ({ agreement }) => agreement?.description?.indexOf('Own Gps') > -1

const getVehiclesPaid = ({ agreement, discount, ownGps }) => {
  const planName = getPlanName({ agreement })
  const hasOwnGps = ownGps || getOwnGps({ agreement })
  const agreementAmount = Number(
    agreement?.plan?.payment_definitions[agreement.plan.payment_definitions.length - 1]?.amount
      ?.value || 0,
  )
  const planPrice = getPlanPrice({ activeAgreement: agreement })
  const price =
    hasOwnGps && planName?.toLowerCase().indexOf('dashcam') >= 0
      ? planPrice - 45 // 10 = gps + 35 = dashcam
      : hasOwnGps && planName?.toLowerCase().indexOf('gps') >= 0
      ? planPrice - 10
      : planPrice
  if (!discount) return parseFloat((+agreementAmount / +price)?.toFixed(3))
  let i = 1
  const calcAmount = (i) => parseFloat((i * +price - (+discount / 100) * i * +price)?.toFixed(3))
  while (agreementAmount >= calcAmount(i)) {
    // console.log(agreementAmount, calcAmount(i), i)
    if (+agreementAmount <= calcAmount(i)) return i
    i++
  }
}

const someFacility = ({ facility, user }) => {
  try {
    return (
      (!facility?.length && !user?.facility?.length) ||
      (facility?.length && facility?.some((f) => user?.facility?.some((uf) => uf === f))) ||
      isSuperadmin(user)
    )
  } catch (err) {
    handleErr({ err })
    return false
  }
}

const insuranceData = ({ user, trip }) => {
  try {
    const { insurance: userInsurance, insuranceId: userInsuranceId } = user || {}
    const { insurance: tripInsurance, insuranceId: tripInsuranceId } = trip || {}
    return !isEmpty(userInsurance)
      ? {
          ...userInsurance,
          insuranceId: userInsuranceId,
        }
      : !isEmpty(tripInsurance)
      ? {
          ...tripInsurance,
          insuranceId: tripInsuranceId,
        }
      : null
  } catch (err) {
    handleErr({ err })
    return null
  }
}

const brokerData = ({ user, trip }) => {
  try {
    const { broker: userBroker, brokerId: userBrokerId } = user || {}
    const { broker: tripBroker, brokerId: tripBrokerId } = trip || {}
    return !isEmpty(userBroker)
      ? {
          ...userBroker,
          brokerId: userBrokerId,
        }
      : !isEmpty(tripBroker)
      ? {
          ...tripBroker,
          brokerId: tripBrokerId,
        }
      : null
  } catch (err) {
    handleErr({ err })
    return null
  }
}

const getFare = ({ user, trip, idx, brokerPricingData }) => {
  try {
    const { tripIdx, times } = trip || {}
    const tIdx = idx || tripIdx
    const isTripCanceled = trip?.tripState === 'canceled'
    const tripDist = idx
      ? getFullTripDist({ trip })?.length
        ? getFullTripDist({ trip })[tIdx ? tIdx - 1 : 0]
        : 0
      : getFullTripDist({ trip })?.length
      ? getFullTripDist({ trip })?.reduce((acc, val) => acc + val)
      : 0
    const isArriveTime = times[`arriveTime${tIdx || 1}`] || tripDist // if canceled going to customer
    const halfPrice = (price = 0) => (isTripCanceled && isArriveTime ? price / 2 : price)
    return user?.fixedFare && user?.fare
      ? user?.fare
      : isTripCanceled && !isArriveTime && !tripDist
      ? 0
      : trip?.tripType === 'delivery'
      ? Number(halfPrice(brokerPricingData?.delivery) || 0) //|| user?.fare || brokerPricingData?.standard
      : user?.fare
      ? Number(halfPrice(user?.fare))
      : user?.features?.find((f) => f === 'bariatricStretcher') &&
        brokerPricingData?.bariatricStretcher
      ? Number(halfPrice(brokerPricingData?.bariatricStretcher))
      : user?.features?.find((f) => f?.toLowerCase() === 'stretcher') &&
        brokerPricingData?.stretcher
      ? Number(halfPrice(brokerPricingData?.stretcher))
      : user?.features?.find((f) => f === 'bariatricWheelchair') &&
        brokerPricingData?.bariatricWheelchair
      ? Number(halfPrice(brokerPricingData?.bariatricWheelchair))
      : user?.features?.find((f) => f?.toLowerCase() === 'wheelchair') &&
        brokerPricingData?.wheelchair
      ? Number(halfPrice(brokerPricingData?.wheelchair))
      : Number(halfPrice(brokerPricingData?.standard) || 0)
  } catch (err) {
    handleErr({ err })
  }
}

const extraMilePerFare = ({ fare, brokerPricingData }) => {
  try {
    switch (fare) {
      case 'delivery':
        return Number(brokerPricingData?.deliveryExtramile)
      case 'standard':
        return Number(brokerPricingData?.standardExtramile)
      case 'wheelchair':
        return Number(brokerPricingData?.wheelchairExtramile)
      case 'bariatricWheelchair':
        return Number(brokerPricingData?.bariatricWheelchairExtramile)
      case 'stretcher':
        return Number(brokerPricingData?.stretcherExtramile)
      case 'bariatricStretcher':
        return Number(brokerPricingData?.bariatricStretcherExtramile)
      default:
        return brokerPricingData?.standard
    }
  } catch (err) {
    handleErr({ err })
  }
}

const getTripVehicle = ({ trip = {}, driver, vehicles = [] }) => {
  try {
    return (isEmpty(trip) && driver) || isPending(trip)
      ? vehicles?.find &&
          vehicles.find((v) =>
            trip?.vehicle
              ? String(v?._id) === String(trip?.vehicle)
              : String(v?.driver) === String(driver),
          )
      : (vehicles?.find &&
          vehicles?.find(
            (v) =>
              v &&
              String(trip?.driverHistory[trip?.driverHistory?.length - 1]?.vehicle) ===
                String(v?._id),
          )) ||
          (vehicles?.find && vehicles?.find((v) => String(v?.driver) === String(driver)))
  } catch (err) {
    handleErr({ err })
  }
}

const toggleBlurryText = (el) => {
  try {
    el?.target?.classList?.toggle('blurry-text')
  } catch (err) {
    handleErr({ err })
  }
}

const calculateAge = (birthDate) => {
  try {
    if (!birthDate) return ''
    const today = new Date()
    const birthDateObj = new Date(birthDate)

    let age = today.getFullYear() - birthDateObj.getFullYear()
    const monthDiff = today.getMonth() - birthDateObj.getMonth()

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDateObj.getDate())) {
      age--
    }

    return age
  } catch (err) {
    handleErr({ err })
  }
}

const filterPkDp = ({ trips, showMapOptions, searchTerms, tripsDest }) => {
  try {
    return trips.filter((t) => {
      if (
        showMapOptions.current.all &&
        (searchTerms?.tripState === 'all' || t.tripState === searchTerms?.tripState)
      ) {
        return true
      }
      // if (showMapOptions.current.all) return t.tripState === searchTermsRef.current?.tripState
      const tInfo = tripInfo({ trip: t, tripsDest })
      if (showMapOptions.current.pickupOnly && !isEnded(t) && (tInfo?.isStart || tInfo?.isArrive)) {
        return true
      }
      if (
        showMapOptions.current.dropoffOnly &&
        (tInfo?.isPickup ||
          tInfo?.isDropoff ||
          (t?.tripState === searchTerms?.tripState &&
            isEnded({ tripState: searchTerms?.tripState })))
      ) {
        return true
      }
      return false
    })
  } catch (err) {
    handleErr({ err })
    return trips
  }
}

const filterTripsState = ({ trips, users, userInfo, searchTerms, showMapOptions, tripsDest }) => {
  const iqualizeNoDriver = filterPkDp({ trips, searchTerms, showMapOptions, tripsDest })
    ?.filter((t) => (isCustomer(userInfo) ? String(t?.customer) === String(userInfo?._id) : true))
    ?.filter((t) => {
      const custData = isFound({ list: [...users, ...tripsDest], id: t?.customer })
      return (!custData && !t?.isBooking) || custData?.isDeactivated ? false : true
    })
    ?.filter((t) => (!searchTerms?.tripType || searchTerms?.tripType === t.tripType ? true : false))
    ?.filter((t) =>
      !searchTerms?.tripState ||
      searchTerms?.tripState === 'all' ||
      searchTerms?.tripState === t.tripState
        ? true
        : false,
    )
    ?.map((t) => ({
      ...t,
      driver: !t?.driver ? null : t.driver,
    }))

  const tripsFiltered = iqualizeNoDriver.filter((trip) =>
    !isDriver(userInfo) ? true : userIsTripDriver({ trip, id: userInfo?._id }),
  )
  // .filter((trip) =>
  //   !searchTermsRef?.current?.tripState || searchTermsRef.current.tripState === 'all'
  //     ? true
  //     : searchTermsRef.current.tripState === trip.tripState,
  // )

  // .filter((trip) => {
  //   if (!isEmpty(trip?.times)) {
  //     // const { timeIdx } = tripInfo({ trip })
  //     // !trip?.times[`expectedPickupTime${timeIdx}`] ||
  //     const daysArr = isScheduled(trip)
  //       ? constraintToArr({ constraint: searchTermsRef?.current?.constraint })
  //       : ''
  //     return (
  //       (isScheduled(trip) &&
  //         daysArr?.length &&
  //         daysArr.find((d) => trip?.schedule?.some((s) => s === d.wDay || s === d.mDay))) ||
  //       isInTimeRange({
  //         startTime: Date.parse(
  //           formatToHTMLinput({
  //             dateData: new Date(
  //               latestStartStopTime({ trip }),
  //               // trip?.times[`expectedStartTime${timeIdx}`] ||
  //               //   trip?.times[`expectedPickupTime${timeIdx}`] ||
  //               //   trip?.times[`expectedStartTime${timeIdx - 1}`] ||
  //               //   trip?.times[`expectedPickupTime${timeIdx - 1}`],
  //             ),
  //           }),
  //         ),
  //         constraint: searchTermsRef?.current?.constraint,
  //       })
  //     )
  //   } else {
  //     return false
  //   }
  // })
  // .sort(sortByPkTime)

  return tripsFiltered

  // const onlineBookingTrips = trips.filter((trip) => trip.isBooking)
  // const allFilteredTrips = [...tripsFiltered, ...onlineBookingTrips]
  // return allFilteredTrips
}

const getPendingTrips = ({ trips, users, userInfo, searchTerms, showMapOptions, tripsDest }) => {
  try {
    return trips?.length
      ? uniqueById(
          filterTripsState({
            trips,
            users,
            userInfo,
            searchTerms,
            showMapOptions,
            tripsDest,
          })?.filter((t) => isPending(t)),
        )
      : []
  } catch (err) {
    handleErr({ err })
  }
}

const samePkOrDp = ({ firstTrip, tripsListData, pk, dp, leg, tripsDest, constraint }) => {
  const pendingTrips = tripsListData || []
  const triptoDrive = firstTrip || pendingTrips[0]
  try {
    // const tripsDest = destsDataRef.current
    // const isclosest = {}
    const {
      // _id,
      destLen,
      destData,
      destIdxToShow,
      idxToGo,
      isStart,
      isArrive,
      isPickup,
      isDropoff,
      customer,
      customerReady,
      driver,
      times,
      timeIdx,
    } = tripInfo({ trip: triptoDrive, tripsDest })

    // const pkdpAddr = getFullAddress({ data: destData[destIdxToShow] })
    // const pkdpAddr = getFullAddress({ data: destData[idxToGo] })
    const pkIdxByLeg = leg && leg - 1 >= 0 ? leg - 1 : ''
    const dpIdxByLeg = leg
    const pkLoc = destData[destLen === 1 ? 0 : pkIdxByLeg || destIdxToShow]?.location
    const dpLoc =
      destData[
        destLen === 1 ? 0 : dpIdxByLeg ? dpIdxByLeg : isStart || isArrive ? idxToGo + 1 : idxToGo
      ]?.location

    const tripTime = formatToHTMLinput({
      dateData: times
        ? new Date(
            times[`pickupTime${leg || timeIdx}`] ||
              times[`arriveTime${leg || timeIdx}`] ||
              times[`startTime${leg || timeIdx}`] ||
              times[`expectedPickupTime${leg || timeIdx}`],
          )
        : new Date(),
    })

    const pkdpTime = times ? tripTime : new Date()
    const tripQpending = pendingTrips.filter((t) =>
      isOnTimeToPickup({
        trip: t,
        time1: pkdpTime,
        constraint,
        leg,
      }),
    )

    const manualQtrips = tripQpending.filter((otherTrip) => {
      const {
        destLen: otherTripDestLen,
        destData: otherDestData,
        destIdxToShow: otherDestIdxToShow,
        idxToGo: otherIdxToGo,
        isStart: oTripIsStart,
        isArrive: oTripIsArrive,
        isPickup: oTripIsPickup,
        isDropoff: oTripIsDropoff,
        customer: oTripCustomer,
        customerReady: oTripCustomerReady,
        driver: oTripDriver,
        // times: otherTipsTimes,
      } = tripInfo({ trip: otherTrip, tripsDest })

      // const otherpkdpAddr = getFullAddress({ data: otherDestData[otherDestIdxToShow] })
      const otherPkLoc =
        otherDestData[otherTripDestLen === 1 ? 0 : pkIdxByLeg || otherDestIdxToShow]?.location
      const otherDpLoc =
        otherDestData[
          otherTripDestLen === 1
            ? 0
            : dpIdxByLeg
            ? dpIdxByLeg
            : oTripIsStart || oTripIsArrive
            ? otherIdxToGo + 1
            : otherIdxToGo
        ]?.location

      // const { etaDate: oTripETADate } = getETA({
      //   loc1: driverLastLoc,
      //   loc2: otherPkLoc,
      // })

      // const oTripPkdpTime = formatToHTMLinput({
      //   dateData: new Date(
      //     otherTipsTimes[`pickupTime${(destIdxToShow || 0) + 1}`] ||
      //       otherTipsTimes[`arriveTime${(destIdxToShow || 0) + 1}`] ||
      //       otherTipsTimes[`startTime${(destIdxToShow || 0) + 1}`] ||
      //       otherTipsTimes[`expectedPickupTime${(destIdxToShow || 0) + 1}`],
      //   ),
      // })

      // const timeDiff = timeDifference({
      //   startTime: new Date(pkdpTime),
      //   stopTime: new Date(oTripPkdpTime),
      // })

      // const pkDist = linearDistance({
      //   lat1: pkLoc?.lat,
      //   lng1: pkLoc?.lng,
      //   lat2: otherPkLoc?.lat,
      //   lng2: otherPkLoc?.lng,
      // })
      // const dpDist = linearDistance({
      //   lat1: dpLoc?.lat,
      //   lng1: dpLoc?.lng,
      //   lat2: otherDpLoc?.lat,
      //   lng2: otherDpLoc?.lng,
      // })

      // const tDists = linearDistance({
      //   lat1: isStart || isArrive ? pkLoc?.lat : dpLoc?.lat,
      //   lng1: isStart || isArrive ? pkLoc?.lng : dpLoc?.lng,
      //   lat2: oTripIsStart || oTripIsArrive ? otherPkLoc?.lat : otherDpLoc?.lat,
      //   lng2: oTripIsStart || oTripIsArrive ? otherPkLoc?.lng : otherDpLoc?.lng,
      // })
      const distance = linearDistance({
        lat1: dp || isPickup || isDropoff ? dpLoc?.lat : pkLoc?.lat,
        lng1: dp || isPickup || isDropoff ? dpLoc?.lng : pkLoc?.lng,
        lat2: dp || oTripIsPickup || oTripIsDropoff ? otherDpLoc?.lat : otherPkLoc?.lat,
        lng2: dp || oTripIsPickup || oTripIsDropoff ? otherDpLoc?.lng : otherPkLoc?.lng,
      })

      return (
        customer !== oTripCustomer && // prevent multiple trips for the same customer
        customerReady &&
        oTripCustomerReady &&
        driver === oTripDriver &&
        // ((oTripIsStart && Date.parse(oTripETADate) >= Date.parse(oTripPkdpTime)) ||
        //   (Math.abs(timeDiff?.days) === 0 &&
        //     Math.abs(timeDiff?.hours) === 0 &&
        //     timeDiff?.minutes <= 45) ||
        //   Date.parse(oTripPkdpTime) <= Date.now()) &&
        // ||
        // Date.parse(pkdpTime) <= Date.now()
        // pkdpAddr?.toLowerCase() === otherpkdpAddr?.toLowerCase()
        // ((((isStart && oTripIsStart) || (isArrive && oTripIsArrive)) &&
        //   Number(pkLoc?.lat) === Number(otherPkLoc?.lat) &&
        //   Number(pkLoc?.lng) === Number(otherPkLoc?.lng)) ||
        //   (((isPickup && oTripIsPickup) || (isDropoff && oTripIsDropoff)) &&
        //     Number(dpLoc?.lat) === Number(otherDpLoc?.lat) &&
        //     Number(dpLoc?.lng) === Number(otherDpLoc?.lng)))

        // ((isStart && oTripIsStart && isNearBy({ distance: pkDist })) ||
        //   (isArrive && sameLocations(pkLoc, otherPkLoc)) ||
        //   // ((isPickup || isDropoff || oTripIsStart) && isclosest.toOtherTripPk) || //&& areEquals(dpLoc, otherDpLoc)) ||
        //   (((isPickup && oTripIsPickup) || (isDropoff && oTripIsDropoff)) &&
        //     isNearBy({ distance: dpDist })))

        (isNearBy({ distance }) || (isArrive && sameLocations(pkLoc, otherPkLoc)))
      )
    })
    // console.log(manualQtrips, triptoDrive, tripQpending)
    return uniqueById([triptoDrive, ...manualQtrips])
  } catch (err) {
    handleErr({ err })
    return [triptoDrive]
  }
}

const removeSpaces = (str) => (str?.replace ? str.replace(/\s/g, '') : str)
const onTripModalOn = (document) => document?.querySelector(`[id*='onTripModal']`)

const getVechilesOnLoading = ({
  trips,
  vehicles,
  searchTerms,
  dispatch,
  getVehiclesAction,
  setShowModal,
  userInfo,
  document,
}) => {
  try {
    const uniqueByVehicle = uniqueByKey({ arr: trips, key: 'vehicle' }).filter((t) =>
      t?.vehicle ? true : false,
    )
    const uniqueByDriver = uniqueByKey({ arr: trips, key: 'driver' }).filter((t) =>
      t?.driver ? true : false,
    )
    const uniqueByVechicleAndDriver = uniqueById([...uniqueByVehicle, ...uniqueByDriver])
    // console.log(uniqueByVechicleAndDriver)
    const vehiclesByIdPulled = new Map()
    const vechilesByDriverPulled = new Map()

    if (isDriver(userInfo) && !searchTerms[`vehicleDriver${userInfo?._id}Pulled`]) {
      dispatch({ type: GET_VEHICLES_RESET }) // clear all vehicles
      dispatch(updateSearchTermsAction({ [`vehicleDriver${userInfo?._id}Pulled`]: true }))
      dispatch(
        getVehiclesAction({
          driver: userInfo?._id,
          cb: ({ vehicles }) => {
            // setShowFullLoader(null)

            if (onTripModalOn && onTripModalOn(document) && setShowModal && toggleModal) {
              setShowModal(toggleModal({ closeModal: true }))
            }
          },
        }),
      )
      return
    }
    uniqueByVechicleAndDriver.forEach((t) => {
      if (
        t?.vehicle &&
        !vehicles?.find((v) => String(v?._id) === String(t?.vehicle)) &&
        !searchTerms[`vehicle${t.vehicle}Pulled`] &&
        !vehiclesByIdPulled.get(t.vehicle)
      ) {
        vehiclesByIdPulled.set(t.vehicle, true)
        dispatch(updateSearchTermsAction({ [`vehicle${t.vehicle}Pulled`]: true }))
        dispatch(
          getVehiclesAction({
            vehicleId: t?.vehicle,
            cb: ({}) => {
              // setShowFullLoader(null)
              if (onTripModalOn && onTripModalOn(document) && setShowModal && toggleModal) {
                setShowModal(toggleModal({ closeModal: true }))
              }
            },
          }),
        )
      }
      if (
        t?.driver &&
        !vehicles?.find((v) => String(v?.driver) === String(t?.driver)) &&
        !searchTerms[`vehicleDriver${t.driver}Pulled`] &&
        !vechilesByDriverPulled.get(t.driver)
      ) {
        vechilesByDriverPulled.set(t.driver, true)
        dispatch(updateSearchTermsAction({ [`vehicleDriver${t.driver}Pulled`]: true }))
        dispatch(
          getVehiclesAction({
            driver: t.driver,
            cb: ({}) => {
              // setShowFullLoader(null)
              if (onTripModalOn && onTripModalOn(document) && setShowModal && toggleModal) {
                setShowModal(toggleModal({ closeModal: true }))
              }
            },
          }),
        )
      }
      // }
    })
  } catch (err) {
    handleErr({ err, userInfo })
  }
}

export {
  allSpacesToOne,
  onTripModalOn,
  getVechilesOnLoading,
  removeSpaces,
  filterPkDp,
  filterTripsState,
  getPendingTrips,
  samePkOrDp,
  createCompanyCode,
  getUserLastLoc,
  getTripDistances,
  tripsWithDists,
  slowDown,
  getTripVehicle,
  getFare,
  extraMilePerFare,
  insuranceData,
  brokerData,
  getActiveDrivers,
  getActiveCustomers,
  getActiveVehicles,
  isFound,
  getIsDriving,
  getTripsPendingByDriver,
  getFullTripDist,
  getPkDpDist,
  currentDriver,
  sortByPkTime,
  latestStartStopTime,
  sortTripsByLatestTime,
  sortByOneDest,
  sortByPriority,
  sortByDistance,
  sortByRouteIndex,
  sortByOnTrip,
  sortByNext,
  sortByArrive,
  sortByPickupDistance,
  sortByDropoff,
  sortByName,
  sortByFirstName,
  sortRouteAI,
  isGroup,
  isRide,
  isDelivery,
  isPending,
  isEnded,
  isCanceled,
  isNotGroupPickedUp,
  getTripCust,
  getFullName,
  getNameInitials,
  getFullAddress,
  getPhone,
  getTripDestination,
  linearDistance,
  isNearBy,
  getRemainingItems,
  ObjOrdered,
  arrUniq,
  uniqueById,
  uniqueByKey,
  areEquals,
  sameLocations,
  shortName,
  docEl,
  calcOilLife,
  userIsTripDriver,
  isSameCustomerAndPk,
  uniqueByCustomerAndPickup,
  validateEmail,
  validatePhone,
  groupBy,
  debounce,
  onlyDestinations,
  onlyUsers,
  getCompanyName,
  validVin,
  uInfo,
  usersByLevel,
  isDisability,
  matchCustomerVehicle,
  matchCustomerVehicleMsg,
  getFeature,
  featuresToFare,
  radians,
  degrees,
  angle,
  validCoords,
  modifyDeliveryTripPkTime,
  cleanTrips,
  duplicateTrip,
  getPlanName,
  getVehiclesPaid,
  sortByCustomerReady,
  sortByDriver,
  sortByEnded,
  sortByCanceled,
  someFacility,
  isScheduled,
  toggleBlurryText,
  calculateAge,
}
