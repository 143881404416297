import { toast } from 'react-toastify'
// import { sendEmailAction } from '../actions/userActions'
import errTxt from './error-text'

const handleErr = async ({
  err,
  alert,
  customFunc,
  cb,
  dispatch,
  reduxType,
  payload,
  userInfo,
}) => {
  const { firstName = '', lastName = '' } = userInfo || {}
  const errString = JSON.stringify(err)
  const { errors } = err?.response?.data || { errors: '' }
  console.log('errors', errors)
  console.log(err)
  console.log(errTxt({ err: errors || err }))
  const errSent = errors || err
  const errFlatten = errSent.flat ? errSent.flat() : errors
  const errMsg = errTxt({ err: errFlatten })
    ? errTxt({ err: errFlatten })
    : err?.message && err.message.flat
    ? err.message.flat()
    : err?.message
    ? err?.message
    : 'Error'
  console.log(errMsg)
  if (alert) {
    toast.dismiss()
    setTimeout(() => {
      toast.error(alert.msg || errMsg, {
        toastId: alert.id || 'error',
        // autoClose: alert.autoClose === false ? false : true,
      })
    }, 77)
  }
  customFunc && customFunc()
  cb && cb({})
  // const { data } =
  fetch(
    `${
      process.env.NODE_ENV === 'development'
        ? 'http://localhost:8080'
        : 'https://api.xperdriver.com'
    }/users/sendemailPublic`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        isError: true,
        to: `dev@xperdriver.com`,
        subject: `Front end Error ${firstName} ${lastName} ${errMsg}`,
        bodyContent: `${JSON.stringify(errors)} ${errString}`,
        // fileType: 'pdf',
        // fileBase64: `${fileBase64?.split('base64,')[1]}`,
        // isInvoice: true,
        // fileName: `${opt.filename}.pdf`,
        // cb: (data) => setNoPlaceholder(false),
      }),
    },
  )
  // await axiosPublic.post(`/users/sendemailPublic`, {
  //   isError: true,
  //   to: `dev@xperdriver.com`,
  //   subject: `Front end Error ${errMsg}`,
  //   bodyContent: `${JSON.stringify(errors)} ${errString}`,
  //   // fileType: 'pdf',
  //   // fileBase64: `${fileBase64?.split('base64,')[1]}`,
  //   // isInvoice: true,
  //   // fileName: `${opt.filename}.pdf`,
  //   // cb: (data) => setNoPlaceholder(false),
  // })
  if (dispatch) {
    // dispatch(
    //   sendEmailAction({
    //     isError: true,
    //     to: `dev@xperdriver.com`,
    //     subject: `Front end Error ${errMsg}`,
    //     bodyContent: `${JSON.stringify(errors)} ${errString}`,
    //     // fileType: 'pdf',
    //     // fileBase64: `${fileBase64?.split('base64,')[1]}`,
    //     // isInvoice: true,
    //     // fileName: `${opt.filename}.pdf`,
    //     // cb: (data) => setNoPlaceholder(false),
    //   }),
    // )
    if (reduxType && errMsg) {
      dispatch({
        type: reduxType,
        payload: payload || [errMsg],
      })
    }
  }
}

export default handleErr
