import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import { createRoot } from 'react-dom/client'
import { renderToString } from 'react-dom/server'
import PropTypes from 'prop-types'
import Map from 'ol/Map'
import OSM from 'ol/source/OSM'
import VectorSource from 'ol/source/Vector'
import Overlay from 'ol/Overlay'
import TileLayer from 'ol/layer/Tile'
import VectorLayer from 'ol/layer/Vector'
import { Style, Fill, Circle, Stroke } from 'ol/style'
import { FullScreen, defaults as defaultControls } from 'ol/control.js'
import { DragPan, MouseWheelZoom, defaults } from 'ol/interaction'
// import { platformModifierKeyOnly } from 'ol/events/condition'
import XYZ from 'ol/source/XYZ'
// import Zoom from 'ol/control/Zoom.js'
// import Stroke from 'ol/style/Stroke'
// import Icon from 'ol/style/Icon'
import View from 'ol/View'
import Feature from 'ol/Feature'
import Polyline from 'ol/format/Polyline'
import {
  fromLonLat,
  // toLonLat
} from 'ol/proj'
// import 'ol/ol.css'
// import { DragPan, MouseWheelZoom, defaults } from 'ol/interaction'
// import { platformModifierKeyOnly } from 'ol/events/condition'
import { formatDate, isLocTimeOff, secondsToTime, timeDifference } from 'src/utils/handle-date'
import { metersToMiles } from 'src/utils/get-miles'
import CIcon from '@coreui/icons-react'
import {
  cibBuffer,
  cibOpsgenie,
  cibShopify,
  cilBuilding,
  cilBusAlt,
  cilClock,
  cilFork,
  cilLayers,
  cilLocationPin,
  cilTerrain,
} from '@coreui/icons'
// import { CButton } from '@coreui/react'
// import Checkmark from '../../assets/images/icons/checkmark_green.svg'
// import Calculateroute from './Calculateroute'
// import getNearestPointToStreet from 'src/utils/nearest-point-to-street'
import getTrack from 'src/utils/get-track'
import mobileOS from 'src/utils/iOS'
import { getDestinationsAction } from 'src/actions/adminActions'
import handleErr from 'src/utils/handle-error'
import { updateSearchTermsAction, updateUserSettings } from 'src/actions/userActions'
import {
  isPending,
  isDelivery,
  isCanceled,
  isEnded,
  areEquals,
  validCoords,
  slowDown,
} from 'src/utils/helpers'
import { isEmpty } from 'ol/extent'
import Capitalize from 'src/utils/capitalize'
import { calculateHeading, headingDirection } from 'src/utils/calculate-heading'
import VehicleIcon from 'src/components/VehicleIcon'

const OlMap = (props) => {
  const dispatch = useDispatch()
  const { companyInfo } = useSelector((state) => state?.companyAuth)
  const { userSettings, searchTerms } = useSelector((state) => state?.user)
  const { destinationsList } = useSelector((state) => state?.admin)
  const [map, setMap] = useState(null)
  const [features, setFeatures] = useState([])
  // const [overLaysState, setOverLaysState] = useState([])
  // const [vectorLayer, setVectorLayer] = useState(new VectorLayer({}))
  // const [vectorSource, setVectorSource] = useState(new VectorSource({}))
  // const [coordIdx, setCoordIdx] = useState(-1)
  // const [coordStreet, setCoordStreet] = useState('')
  // const [points, setPoints] = useState([])
  // const [routeGeometry, setRouteGeometry] = useState('')
  const [coordsState, setCoordsState] = useState([])
  const [distances, setDistances] = useState('')
  const [showDistance, setShowDistance] = useState(0)
  const [durations, setDurations] = useState('')
  const [durationData, setDurationData] = useState(0)
  const [toggleLayerBtns, setToggleLayerBtns] = useState(null)
  const [processCoordsEnded, setProcessCoordsEnded] = useState(false)
  const [bgLayerVisible, setBgLayerVisible] = useState(
    userSettings?.globalMap?.bgLayerVisible || 'isOSM',
  )

  const mounted = useRef(false)
  // const processCoordsEnded = useRef(false)
  const moveEnded = useRef(true)
  const userSettingsRef = useRef(userSettings)
  // const coordsStateRef = useRef([])
  const featuresRef = useRef([])
  // pull refs
  // const prevCoords = useRef()
  const mapEl = useRef()

  // create state ref that can be accessed in OpenLayers onclick callback function
  //  https://stackoverflow.com/a/60643670
  const mapRef = useRef()
  // mapRef.current = map

  // const url_osrm_nearest = '//router.project-osrm.org/nearest/v1/driving/'
  // const url_osrm_route = '//router.project-osrm.org/route/v1/driving/'
  // const icon_url = 'https://img.icons8.com/ios-glyphs/2x/marker.png'
  // icon_url = '//cdn.rawgit.com/openlayers/ol3/master/examples/data/icon.png',

  const isCorp = (coordType) => coordType === 'corp'
  const isRider = (coordType, tripType) => coordType === 'Customer' && tripType === 'ride'
  // const isDelivery = tripType === 'delivery'
  const isDriverCoord = (coordType) => coordType === 'Driver'
  const isVehicle = (coordType) => coordType === 'vehicle'
  const isDestination = (coordType) => coordType === 'Destination'
  const tripHasDriver = (driver) => driver && driver !== 'headquarter'

  // const vectorSource = useMemo(() => new VectorSource({}), [])
  // const vectorLayer = useMemo(
  //   () =>
  //     new VectorLayer({
  //       source: vectorSource,
  //     }),
  //   [vectorSource],
  // )

  const styles = {
    routeGoing: new Style({
      stroke: new Stroke({
        width: 4,
        color: [7, 77, 177],
        // color: [0, 11, 135, 0.7],
      }),
    }),
    routeReturning: new Style({
      stroke: new Stroke({
        width: 4,
        // color: [255, 199, 135, 0.7],
        color: [177, 7, 7],
        // color: [77, 17, 7, 0.7],
      }),
    }),
    icon: new Style({
      image: new Circle({
        //   anchor: [0.5, 1],
        radius: 7,
        //   src: icon_url,
        fill: new Fill({
          color: 'red',
        }),
        stroke: new Stroke({
          color: 'white',
          width: 2,
        }),
      }),
    }),
    iconDestination: new Style({
      image: new Circle({
        //   anchor: [0.5, 1],
        radius: 7,
        //   src: icon_url,
        fill: new Fill({
          color: 'red',
        }),
        stroke: new Stroke({
          color: 'white',
          width: 2,
        }),
      }),
    }),
  }

  // const map = new Map({
  //       target: mapEl.current,
  //       // target: props.id || 'map',
  //       // target: undefined,
  //       loadTilesWhileAnimating: true,
  //       layers: [
  //         new TileLayer({
  //           maxZoom: 22.29,
  //           // source: new OSM(),
  //           source: new XYZ({
  //             url: 'http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}',
  //           }),
  //           // source: new XYZ({
  //           //   url: 'http://mt0.google.com/vt/lyrs=p&hl=en&x={x}&y={y}&z={z}',
  //           // }),
  //         }),
  //         vectorLayer,
  //       ],
  //       view: new View({
  //         center:
  //           getUserSettings()?.globalMap?.center ||
  //           fromLonLat([
  //             props.routeData
  //               ? props.routeData?.nearest[0]?.location[0]
  //               : coordsState && coordsState.length
  //               ? coordsState[0]?.location?.lng
  //               : companyInfo?.location?.lng || '-80.3597315',
  //             props.routeData
  //               ? props.routeData?.nearest[0]?.location[1]
  //               : coordsState && coordsState.length
  //               ? coordsState[0]?.location?.lat
  //               : companyInfo?.location?.lat || '25.7151935',
  //           ]),
  //         zoom: getUserSettings()?.globalMap?.zoom
  //           ? getUserSettings()?.globalMap?.zoom
  //           : props.zoom
  //           ? props.zoom
  //           : 12,
  //       }),
  //       controls: mobileOS() ? defaultControls() : defaultControls().extend([new FullScreen()]),
  //       interactions: defaults({
  //         dragPan: true,
  //         mouseWheelZoom: true,
  //         altShiftDragRotate: false,
  //         pinchRotate: false,
  //       }).extend([
  //         new DragPan({
  //           condition: function (e) {
  //             return this.getPointerCount() === 2 || platformModifierKeyOnly(e)
  //           },
  //         }),
  //         new MouseWheelZoom({
  //           condition: platformModifierKeyOnly,
  //         }),
  //       ]),
  //     })
  // const map = useMemo(
  //   () =>
  //     new Map({
  //       target: mapEl.current,
  //       // target: props.id || 'map',
  //       // target: undefined,
  //       loadTilesWhileAnimating: true,
  //       layers: [
  //         new TileLayer({
  //           maxZoom: 22.29,
  //           // source: new OSM(),
  //           source: new XYZ({
  //             url: 'http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}',
  //           }),
  //           // source: new XYZ({
  //           //   url: 'http://mt0.google.com/vt/lyrs=p&hl=en&x={x}&y={y}&z={z}',
  //           // }),
  //         }),
  //         vectorLayer,
  //       ],
  //       view: new View({
  //         center:
  //           getUserSettings()?.globalMap?.center ||
  //           fromLonLat([
  //             props.routeData
  //               ? props.routeData?.nearest[0]?.location[0]
  //               : coordsState && coordsState.length
  //               ? coordsState[0]?.location?.lng
  //               : companyInfo?.location?.lng || '-80.3597315',
  //             props.routeData
  //               ? props.routeData?.nearest[0]?.location[1]
  //               : coordsState && coordsState.length
  //               ? coordsState[0]?.location?.lat
  //               : companyInfo?.location?.lat || '25.7151935',
  //           ]),
  //         zoom: getUserSettings()?.globalMap?.zoom
  //           ? getUserSettings()?.globalMap?.zoom
  //           : props.zoom
  //           ? props.zoom
  //           : 12,
  //       }),
  //       controls: mobileOS() ? defaultControls() : defaultControls().extend([new FullScreen()]),
  //       interactions: defaults({
  //         dragPan: true,
  //         mouseWheelZoom: true,
  //         altShiftDragRotate: false,
  //         pinchRotate: false,
  //       }).extend([
  //         new DragPan({
  //           condition: function (e) {
  //             return this.getPointerCount() === 2 || platformModifierKeyOnly(e)
  //           },
  //         }),
  //         new MouseWheelZoom({
  //           condition: platformModifierKeyOnly,
  //         }),
  //       ]),
  //     }),
  //   [
  //     props.routeData,
  //     props.zoom,
  //     vectorLayer,
  //     coordsState,
  //     companyInfo?.location?.lng,
  //     companyInfo?.location?.lat,
  //   ],
  // )

  const toggleSlow = ({ el, action, delay = 0, cb }) => {
    setTimeout(() => {
      if (el) {
        return el?.classList[action](`show-slow-3s`)
      }
      document
        .querySelectorAll(`[id^="vehicle-label"], [id^="vehicle-marker"]`)
        ?.forEach((slowEl) => {
          slowEl?.classList[action](`show-slow-3s`)
        })
      cb && cb()
    }, delay)
  }

  const createRoute = ({ id, geometry, direction }) => {
    try {
      // trajectory is ol.geom.LineString
      const trajectory = new Polyline({
        factor: 1e5,
      }).readGeometry(geometry, {
        dataProjection: 'EPSG:4326',
        featureProjection: 'EPSG:3857',
      })
      const feature = new Feature({
        type: 'route',
        geometry: trajectory,
      })
      feature.setStyle(direction === 'going' ? styles.routeGoing : styles.routeReturning)
      feature.setId(id)
      // feature.setId(`${geometry}`)
      // vectorSource?.addFeature && vectorSource?.addFeature(feature)
      // mounted.current && setFeatures((prev) => uniqueByKey({ arr: [...prev, feature], key: 'id_' }))
      // mapRef.current?.render && mapRef.current?.render()
      // vectorLayer.getSource().changed()
      // console.log(vectorSource?.addFeature)
      // if (vectorLayer?.getSource) {
      //   vectorLayer.getSource().removeFeature(feature)
      //   if (getUserSettings()?.globalMap?.showRoute) vectorLayer.getSource().addFeature(feature)
      // }

      // vectorSource?.addFeature && setVectorSource(vectorSource?.addFeature(feature))
      return feature
    } catch (err) {
      handleErr({ err })
      return {}
    }
  }

  // const utils = {
  //   // getNearest: async (coord) => {
  //   //   const coord4326 = utils.to4326(coord)
  //   //   //make sure the coord is on street
  //   //   const response =
  //   //     mounted.current && url_osrm_nearest && coord4326
  //   //       ? await fetch(url_osrm_nearest + coord4326.join())
  //   //       : ''
  //   //   const nearest = response ? await response.json() : {}
  //   //   if (nearest.code === 'Ok') return nearest.waypoints[0].location
  //   //   else return '' //reject()
  //   // },
  //   // createFeature: (coord) => {
  //   //   const feature = new Feature({
  //   //     type: 'place',
  //   //     geometry: new Point(fromLonLat(coord)),
  //   //   })
  //   //   feature.setStyle(styles.icon)
  //   //   // vectorSource.addFeature(feature)
  //   //   vectorSource?.getSource && setVectorSource(vectorSource?.getSource()?.addFeature(feature))
  //   // },
  //   createRouteGoing: (polyline) => {
  //     // trajectory is ol.geom.LineString
  //     const trajectory = new Polyline({
  //       factor: 1e5,
  //     }).readGeometry(polyline, {
  //       dataProjection: 'EPSG:4326',
  //       featureProjection: 'EPSG:3857',
  //     })
  //     const feature = new Feature({
  //       type: 'route',
  //       geometry: trajectory,
  //     })
  //     feature.setStyle(styles.routeGoing)
  //     feature.setId(`${polyline}`)
  //     // vectorSource?.addFeature && vectorSource?.addFeature(feature)
  //     setFeatures((prev) => uniqueByKey({ arr: [...prev, feature], key: 'id_' }))
  //     // vectorLayer.getSource().changed()
  //     // console.log(vectorSource?.addFeature)
  //     // if (vectorLayer?.getSource) {
  //     //   vectorLayer.getSource().removeFeature(feature)
  //     //   if (getUserSettings()?.globalMap?.showRoute) vectorLayer.getSource().addFeature(feature)
  //     // }

  //     // vectorSource?.addFeature && setVectorSource(vectorSource?.addFeature(feature))
  //   },
  //   createRouteReturning: (polyline) => {
  //     // trajectory is ol.geom.LineString
  //     const trajectory = new Polyline({
  //       factor: 1e5,
  //     }).readGeometry(polyline, {
  //       dataProjection: 'EPSG:4326',
  //       featureProjection: 'EPSG:3857',
  //     })
  //     const feature = new Feature({
  //       type: 'route',
  //       geometry: trajectory,
  //     })
  //     feature.setStyle(styles.routeReturning)
  //     feature.setId(`${polyline}`)
  //     // vectorSource?.addFeature && vectorSource?.addFeature(feature)
  //     setFeatures((prev) => uniqueByKey({ arr: [...prev, feature], key: 'id_' }))
  //     // if (vectorLayer?.getSource) {
  //     //   vectorLayer.getSource().removeFeature(feature)
  //     //   if (getUserSettings()?.globalMap?.showRoute) vectorLayer.getSource().addFeature(feature)
  //     // }
  //     // vectorLayer?.getSource && vectorLayer.getSource().removeFeature(feature)
  //     // vectorLayer?.getSource && vectorLayer.getSource().addFeature(feature)
  //     // vectorLayer.getSource().addFeature(feature)
  //     // vectorSource?.getSource && setVectorSource(vectorSource?.getSource()?.addFeature(feature))
  //   },
  //   // to4326: (coord) =>
  //   //   transform([parseFloat(coord[0]), parseFloat(coord[1])], 'EPSG:3857', 'EPSG:4326'),
  // }
  // const domEl = { el: {} }

  const markerContent = ({ coord, uniqueCoords }) => {
    const {
      status,
      isArrive,
      isPickup,
      isDropoff,
      tripType,
      coordType,
      driver,
      vehicleType,
      heading,
    } = coord
    // const timeDiff = status
    //   ? ''
    //   : uniqueCoords
    //   ? timeDifference({
    //       startTime: new Date(),
    //       stopTime: new Date(
    //         uniqueCoords.find((c) => isVehicle(c) && c?.driver === coord?.driver)?.date ||
    //           coord.date,
    //       ),
    //     })
    //   : { days: 3, hours: 2, minutes: 100 } // any time pass 5min

    const prevCoord = isVehicle(coordType)
      ? getUserSettings()?.globalMap?.prevCoords?.find((prev) => prev?.id === coord?.id)
      : ''
    const calcHeading =
      isVehicle(coordType) && prevCoord
        ? calculateHeading({
            heading,
            lat1: prevCoord?.location?.lat,
            lng1: prevCoord?.location?.lng,
            lat2: coord?.location?.lat,
            lng2: coord?.location?.lng,
          })
        : ''
    const getHeadingDirection =
      isVehicle(coordType) && calcHeading
        ? headingDirection({
            heading: calcHeading,
          })
        : ''
    // if (coord && isVehicle(coordType)) {
    //   console.log('getUserSettings(): ', getUserSettings())
    //   console.log({ userSettings })
    //   console.log({ prevCoords: getUserSettings()?.globalMap?.prevCoords })
    //   console.log({ calcHeading })
    //   console.log({ prevCoord })
    //   console.log({ coord })
    //   console.log({ coordType })
    //   console.log({ vehicleType })
    //   console.log({ heading })
    //   console.log({ getHeadingDirection })
    //   dispatch(
    //     updateUserSettings({
    //       // ...(userSettingsRef.current || {}),
    //       globalMap: {
    //         ...getUserSettings()?.globalMap,
    //         prevCoords: [
    //           ...(getUserSettings()?.globalMap?.prevCoords?.filter((c) => c.id !== coord?.id) ||
    //             []),
    //           coord,
    //         ],
    //       },
    //     }),
    //   )
    // }

    return isVehicle(coordType) ? (
      // status === 'Idle' || status === 'Stopped' || isLocTimeOff(timeDiff) ? (
      // <CIcon icon={cilGarage} className="text-primary" size={'xl'} />
      // ) : (
      <VehicleIcon vehicleType={vehicleType} heading={getHeadingDirection} />
    ) : (
      // )
      <CIcon
        icon={
          isDelivery({ tripType })
            ? cibShopify
            : // : isVehicle(coordType)
            // ? status === 'Idle' || status === 'Stopped' || isLocTimeOff(timeDiff)
            //   ? cilGarage
            //   : vehicleType === 'sedan'
            //   ? cilCarAlt
            //   : cilBusAlt
            isRider(coordType, tripType)
            ? cibOpsgenie
            : cilLocationPin
        }
        className={`${
          isVehicle(coordType)
            ? 'text-primary'
            : isRider(coordType, tripType)
            ? `${
                tripHasDriver(driver)
                  ? `${
                      isArrive
                        ? `bg-success text-deep-green`
                        : isPickup || isDropoff
                        ? `bg-secondary text-dark`
                        : `bg-info text-primary`
                    }`
                  : 'bg-warning text-red'
              } rounded`
            : isDelivery({ tripType })
            ? `${
                tripHasDriver(driver)
                  ? `${isPickup || isDropoff ? `text-green` : `text-primary`}`
                  : 'text-red'
              }`
            : ''
        }`}
        // } ${isDelivery({ tripType }) ? '' : ''}`}
        size={'xl'}
      />
    )
  }

  const labelContent = ({ coord, uniqueCoords, count }) => {
    const {
      eta,
      name,
      note,
      date,
      speed,
      status,
      isStart,
      isArrive,
      isPickup,
      isDropoff,
      tripState,
      queueIdx,
      routeIndex,
      tripType,
      coordType,
      fuelLevel,
      driver,
      driverName,
      customerName,
      assignDriver,
    } = coord

    const timeDiff = status
      ? ''
      : uniqueCoords
      ? timeDifference({
          startTime: new Date(),
          stopTime: new Date(
            uniqueCoords.find((c) => c?.coordType === 'vehicle' && c?.driver === coord?.driver)
              ?.date || coord.date,
          ),
        })
      : { days: 3, hours: 2, minutes: 100 } // any time pass 5min

    return name || note || date
      ? `<div class="grid-1-col text-truncate w-fit px-1 ${
          isVehicle(coordType)
            ? `map-vehicle-label ${
                status === 'Idle'
                  ? 'bg-warning'
                  : status === 'Moving' ||
                    (!status && isVehicle(coordType) && !isLocTimeOff(timeDiff))
                  ? 'bg-success text-white'
                  : status === 'Stopped' ||
                    (!status && isVehicle(coordType) && isLocTimeOff(timeDiff))
                  ? 'bg-danger text-white'
                  : 'bg-danger text-white'
              }`
            : `map-label-datetime ${
                isRider(coordType, tripType) || isDelivery({ tripType })
                  ? 'bg-transparent'
                  : isEnded({ tripState })
                  ? 'bg-dark'
                  : isCanceled({ tripState })
                  ? 'bg-danger'
                  : isDriverCoord(coordType) || coordType === 'Driver-Loc'
                  ? 'bg-info'
                  : isDestination(coordType)
                  ? tripHasDriver(driver)
                    ? `${
                        isArrive
                          ? `bg-green`
                          : isPickup || isDropoff
                          ? `bg-dark text-white`
                          : 'bg-brown'
                      }`
                    : 'bg-brown'
                  : 'bg-warning text-dark'
              }`
        }">                                            

<div class="map-label-drivername text-white fw-bold px-2 ${
          isDelivery({ tripType }) ? 'bg-purple' : 'bg-success'
        }"> ${Capitalize(tripType)}</div> 

${
  coord.noIdx ||
  props?.showOrder === false ||
  coord?.driverId ||
  isDriverCoord(coordType) ||
  isVehicle(coordType) ||
  isCorp(coordType)
    ? ''
    : `
    ${
      isRider(coordType, tripType) && !isPending({ tripState })
        ? `<div class="map-label-drivername fw-bold text-uppercase ${
            isEnded({ tripState }) ? 'bg-dark' : isCanceled({ tripState }) ? 'bg-danger' : ''
          }">${tripState || ''}</div>`
        : ''
    }
        ${
          driverName
            ? `<div class="map-label-drivername assign-driver">${driverName}</div>`
            : assignDriver && !isVehicle(coordType)
            ? `<div class="map-label-drivername assign-driver"></div>`
            : ''
        }
          <div class="map-label-idx">${
            isEnded({ tripState }) || isCanceled({ tripState }) || tripHasDriver(driver)
              ? `<label class="${
                  isDelivery({ tripType })
                    ? tripHasDriver(driver)
                      ? `${isPickup || isDropoff ? `bg-green` : `bg-primary`}`
                      : 'bg-red'
                    : ''
                }">${
                  queueIdx
                    ? queueIdx
                    : coordType === 'Customer' && routeIndex >= 0
                    ? routeIndex
                    : count.idx
                }</label>`
              : // :
                //  isDelivery({ tripType })
                // ? `<div class="octagonMiniWrap ${
                //     isDestination || tripState !== 'pending'
                //       ? 'invisible'
                //       : ''
                //   }"><div class="octagon bg-danger"></div></div>`
                '' // 'invisible'
          }</div>`
}
<div class="${isVehicle(coordType) ? 'map-vehicle-name' : 'map-label-name'}">
 ${customerName ? `<div class="map-label-customername">${customerName}</div>` : ''}
  ${
    isVehicle(coordType)
      ? name?.length > 16 || note?.length > 16
        ? `${(name || note || '').substring(0, 16)}...`
        : name || note
      : `<span class="d-block w-100">${name.split('|')[0]}</span>${
          name.split('|')[1] ? `<span class="w-100">${name.split('|')[1]}</span>` : ''
        }` ||
        note ||
        ''
  } ${fuelLevel ? `<span class="fuellevel">Fuel: ${parseInt(fuelLevel)}%</span>` : ''}

</div> 
${
  driverName && isVehicle(coordType)
    ? `<div class="map-vehicle-driver-name">${driverName}</div>`
    : ``
}                      
<div class="${isVehicle(coordType) ? 'map-vehicle-date' : 'map-label-date'}">${
          date
            ? formatDate({
                dateData: new Date(date),
              })
            : ''
        } ${eta || ''} ${speed ? `${speed}mi/h` : ''} ${
          note && isVehicle(coordType)
            ? note?.length > 16
              ? `${(note || '').substring(0, 16)}...`
              : note
            : ''
        }</div>
                        
</div>`
      : ''
  }

  const addElementContent = (args) => {
    try {
      const { id, type, coord, idx, content, assignDriver, showEditTripModal, driverName } = args
      const { location } = coord || {}
      const { lng, lat } = location || {}
      const elId = `${type}-${lng}${lat}-${idx || ''}`
      const element = document.createElement('div')
      element.id = elId
      element.style.height = 'fit-content'
      element.style.display = 'grid'
      element.style.alignItems = 'center'
      element.style.alignContent = 'center'
      element.style.justifyItems = 'center'
      element.style.justifyContent = 'center'
      const innerContent = content?.reduce((prev, cont) => {
        const nString = typeof cont === 'string' ? cont : renderToString(cont)
        return `${prev}${nString}`
      }, '')
      element.innerHTML = innerContent || ''
      if (assignDriver) {
        const btn = document.createElement('button')
        btn.classList.add('bg-primary', 'text-white', 'm-1')
        btn.textContent = driverName || 'Assign Driver'
        btn.addEventListener('click', assignDriver)
        const box = element.querySelector('.assign-driver')
        if (box) {
          if (driverName) box.innerHTML = ``
          box.prepend(btn)
        }
      }
      if (showEditTripModal) {
        const btn = document.createElement('button')
        btn.classList.add('bg-info', 'text-white', 'm-1')
        btn.textContent = 'Edit'
        btn.addEventListener('click', showEditTripModal)
        const box = element.querySelector('.assign-driver')
        if (box) box.append(btn)
      }
      return element
    } catch (err) {
      handleErr({ err })
    }
  }

  const createEl = (args) => {
    try {
      const { id, coord, type } = args
      const { location } = coord
      if (!isEmpty(location)) {
        const { lng, lat } = location || {}
        if (lng && lat) {
          const foundOverLay = findOverLayInMap({ id })
          const position = fromLonLat([lng, lat])
          if (foundOverLay) {
            if (id?.indexOf(`facility-label`) >= 0 || id?.indexOf(`facility-marker`) >= 0) return
            if (validCoords({ coords: location })) {
              const overlayPos = foundOverLay.getPosition()
              // overlay.setPosition([lng, lat])
              if (overlayPos[0] !== position[0] || overlayPos[1] !== position[1]) {
                const el = document.getElementById(
                  foundOverLay.getProperties()?.element?.id,
                )?.parentNode
                if (
                  el &&
                  (isVehicleMarker({ overlay: foundOverLay }) ||
                    isVehicleLabel({ overlay: foundOverLay }))
                ) {
                  toggleSlow({ el, action: 'add' })
                  toggleSlow({
                    el,
                    action: 'remove',
                    delay: 3003,
                    // cb: () => {
                    //   dispatch(
                    //     updateUserSettings({
                    //       globalMap: {
                    //         ...getUserSettings()?.globalMap,
                    //         prevCoords: [
                    //           ...(getUserSettings()?.globalMap?.prevCoords?.filter(
                    //             (c) => c.id !== coord.id,
                    //           ) || []),
                    //           coord,
                    //         ],
                    //       },
                    //     }),
                    //   )
                    // console.log(prevCoords.current)
                    // prevCoords.current = !prevCoords.current?.length
                    //   ? [coord]
                    //   : prevCoords.current?.map((c) => {
                    //       if (c.id === coord.id) {
                    //         return { ...coord }
                    //       }
                    //       return { ...c } || { ...coord }
                    //     })
                    // },
                  })
                }
                // setTimeout(() => {
                foundOverLay.setPosition(position)
                foundOverLay.changed()
                // refreshMap()
                // }, 7)
              }
              if (
                foundOverLay.getElement &&
                !foundOverLay.getElement().isEqualNode(addElementContent(args))
              ) {
                foundOverLay.setElement(addElementContent(args))
                foundOverLay.changed()
              }
            }
          } else {
            const overlay = new Overlay({
              id,
              // className: type || '', memory leak
              position,
              positioning: 'center-center',
              element: addElementContent(args),
              stopEvent: false,
              insertFirst: false, // default true
              // autoPan: coordsStateRef?.current?.length === 1,
              // autoPan: onlyOneCustomer() || onlyOneDestination() || onlyOneVehicle(),
              // geometry: new Point(pos).transform('EPSG:3857', 'EPSG:4326'),
              // geometry: new Point(transform(pos, 'EPSG:3857', 'EPSG:4326')),
              // autoPan: true,
              // autoPanAnimation: {
              //   duration: 100,
              // },
            })
            // overlay.setElement(addElementContent(args))
            // overlay.setId(id)
            if (mapRef.current?.addOverlay) mapRef.current?.addOverlay(overlay)
            // const extent = marker.getOptions().getGeometry().getExtent()
            // mapRef?.current?.getView().fit(extent, mapRef?.current?.getSize(), {
            //   padding: [303, 303, 303, 303],
            // })
            // var boundingExtent = Extend.boundingExtent(coord)
            // boundingExtent = ol.proj.transformExtent(
            //   boundingExtent,
            //   ol.proj.get('EPSG:4326'),
            //   ol.proj.get('EPSG:3857'),
            // )
            // if (mapRef.current?.render) mapRef.current?.render()
            // const extent = marker.bounds.getExtent()
            // if (mapRef.current?.getView) mapRef.current?.getView().fit(boundingExtent, map.getSize())
            // if (mapRef.current?.getView) mapRef.current?.getView().fit(pos, mapRef.current.getSize())
            // mapRef.current?.getView().fit(vectorLayer.getSource().getExtent(), {
            //   padding: [100, 100, 100, 100],
            // })
          }
        }
      }
    } catch (err) {
      handleErr({ err })
    }
  }

  // map click handler
  // const handleMapClick = (event) => {
  //   // get clicked coordinate using mapRef to access current React state inside OpenLayers callback
  //   //  https://stackoverflow.com/a/60643670
  //   const clickedCoord = mapRef.current?.getCoordinateFromPixel(event.pixel)
  //   if (clickedCoord?.length) {
  //     console.log(clickedCoord)
  //     // transform coord to EPSG 4326 standard Lat Long
  //     const transormedCoord = transform(clickedCoord, 'EPSG:3857', 'EPSG:4326')

  //     // set React state
  //     setCoordsState(transormedCoord)

  //     console.log(transormedCoord)
  //   }
  // }

  const onlyDelivery = useCallback(
    () => coordsState?.filter((c) => c?.tripType === 'delivery')?.length === 1,
    [coordsState],
  )
  const onlyOneCustomer = useCallback(
    () => coordsState?.filter((c) => c?.coordType === 'Customer')?.length === 1,
    [coordsState],
  )
  const onlyOneVehicle = useCallback(
    () => coordsState?.filter((c) => c?.coordType === 'vehicle')?.length === 1,
    // ||      coordsState?.filter((c) => c?.coordType === 'Customer')?.length === 1,
    [coordsState],
  )
  const onlyOneVehicleAndOneCustomer = useCallback(
    () => onlyOneVehicle() && onlyOneCustomer(),
    [onlyOneVehicle, onlyOneCustomer],
  )
  const onlyOneVehicleisStart = useCallback(
    () => onlyOneVehicle() && coordsState?.length && coordsState[0]?.isStart,
    [coordsState, onlyOneVehicle],
  )
  const onlyOneVehicleisPkDp = useCallback(
    () => onlyOneVehicle() && coordsState?.some((c) => c?.isPickup || c?.isDropoff),
    [coordsState, onlyOneVehicle],
  )

  useEffect(() => {
    mounted.current = true
    return () => {
      // if (mounted.current) setCoordsState([])
      // if (mounted.current && onlyOneVehicleAndOneCustomer()) setCoordsState([])
      mounted.current = false
    }
  }, [])

  const showFacilities = useCallback(({ dests }) => {
    try {
      return dests
        ?.filter((d) => d.isFacility)
        .forEach((facility, i) => {
          const coord = {
            location: { lng: facility.location.lng, lat: facility.location.lat },
          }
          // setCoordsState((prev) => ({ ...prev, coord }))
          createEl({
            id: `facility-label-${i}`,
            // type: 'facility-label',
            coord,
            content: [
              `<label class="map-facility-label bg-info text-truncate">${facility.name}</label>`,
            ],
          })
          createEl({
            id: `facility-marker-${i}`,
            // type: 'facility-marker',
            coord,
            // coord: { location: { lng: '-80.3597315', lat: '25.7151935' } },
            content: [<CIcon key={i} icon={cilBuilding} size="xl" className="text-info" />],
          })
        })
    } catch (err) {
      handleErr({ err })
    }
  }, [])

  const getFacilities = useCallback(() => {
    if (companyInfo) {
      const cb = ({ dests = destinationsList?.data }) => {
        mounted.current && showFacilities({ dests })
      }
      if (searchTerms?.allFacilitiesPulled) {
        mounted.current && cb({})
      } else {
        dispatch(
          getDestinationsAction({
            isFacility: true,
            cb,
          }),
        )
        dispatch(updateSearchTermsAction({ allFacilitiesPulled: true }))
      }
    }
  }, [
    companyInfo,
    searchTerms?.allFacilitiesPulled,
    destinationsList?.data,
    showFacilities,
    dispatch,
  ])

  useEffect(() => {
    try {
      if (mounted.current && !areEquals(featuresRef.current, features)) {
        featuresRef.current = features
        // if (mounted.current && vectorLayer?.setSource) {
        // if (features?.length) {
        // vectorLayer.getSource().clear()
        // vectorLayer.setSource(undefined)
        //REMOVE OLD DATA
        const oldLayer = mapRef.current?.getLayers().getArray()[
          mapRef.current?.getLayers().getArray().length - 1
        ]
        if (oldLayer) mapRef.current.removeLayer(oldLayer)
        // vectorSource.clear()
        // vectorSource.addFeatures(features?.length ? { features } : {})
        // vectorLayer.setSource(vectorSource)
        // vectorLayer.setSource(new VectorSource(features?.length ? { features } : {}))

        //ADD NEW LAYER
        // const vectorSource = new VectorSource({})
        // vectorSource.addFeatures(features?.length ? { features } : {})
        const vectorLayer = new VectorLayer({
          source: new VectorSource(features?.length ? { features } : {}),
          // renderMode: 'image',
        })
        mapRef.current.addLayer(vectorLayer)
      }
    } catch (err) {
      handleErr({ err })
    }
    // }, [features, vectorLayer, vectorSource])
  }, [features])

  useEffect(() => {
    try {
      if (coordsState?.length === 1) {
        mapRef.current?.getView().setZoom(12.888)
        return mapRef.current
          ?.getView()
          ?.setCenter(fromLonLat([coordsState[0]?.location?.lng, coordsState[0]?.location?.lat]))
      }
      const noCustomers = !coordsState?.filter((c) => c?.coordType === 'Customer')?.length
      if (onlyOneVehicle() && noCustomers) {
        const vehiclesCoords = coordsState?.filter((c) => c?.coordType === 'vehicle')
        const firstVehicle = vehiclesCoords[0]
        mapRef.current?.getView().setZoom(12.888)
        return mapRef.current
          ?.getView()
          ?.setCenter(fromLonLat([firstVehicle?.location?.lng, firstVehicle?.location?.lat]))
      }
      if (
        mounted.current &&
        features?.length &&
        processCoordsEnded &&
        (props.autoPan || onlyOneVehicleAndOneCustomer())
      ) {
        const firstExtend = features[0]?.getGeometry()?.getExtent() || []
        const extend1 = features[features.length - 2]?.getGeometry()?.getExtent() || []
        const lastExtend = features[features.length - 1]?.getGeometry()?.getExtent() || []
        const extend =
          onlyOneVehicleAndOneCustomer() || props.autoPan
            ? !isEmpty([...firstExtend?.slice(0, 2), ...lastExtend?.slice(-2)])
              ? [...firstExtend?.slice(0, 2), ...lastExtend?.slice(-2)]
              : // ? [...extend1?.slice(0, 2), ...lastExtend?.slice(-2)]
              // : (onlyOneVehicleisPkDp() || features.length === 2) && lastExtend.length
              // ? lastExtend
              extend1?.length
              ? extend1
              : ''
            : ''
        if (extend) {
          mapRef.current?.getView()?.fit(extend, {
            // mapRef.current?.getView().fit([...extend1?.slice(0, 2), ...lastExtend?.slice(2)], {
            // size: [window.innerWidth, window.innerHeight],
            size: mapRef.current.getSize(),
            padding: [77, 77, 77, 77],
          })
        }
      }

      // vectorLayer?.getSource && vectorLayer?.getSource().changed()
      // mapRef.current?.renderSync && mapRef.current?.renderSync()
      // mapRef.current?.render && mapRef.current?.render()
    } catch (err) {
      handleErr({ err })
    }
  }, [
    props.autoPan,
    features,
    onlyOneVehicle,
    onlyOneVehicleisPkDp,
    onlyOneVehicleAndOneCustomer,
    coordsState,
    processCoordsEnded,
  ])

  useEffect(() => {
    mounted.current && setCoordsState(props.coords)
    // coordsStateRef.current = props.coords
  }, [props.coords])

  useEffect(() => {
    try {
      dispatch(
        updateUserSettings({
          globalMap: {
            ...getUserSettings()?.globalMap,
            bgLayerVisible,
          },
        }),
      )
    } catch (err) {
      handleErr({ err })
    }
  }, [bgLayerVisible])

  useEffect(() => {
    try {
      if (userSettings?.globalMap?.bgLayerVisible && map?.getLayers) {
        if (!mapRef?.current) mapRef.current = map
        mapRef?.current?.getLayers &&
          mapRef?.current
            ?.getLayers()
            // ?.slice()
            ?.forEach((l) => {
              // if (l?.get('isBg')) l?.setVisible(false)
              if (l?.get(`${userSettings?.globalMap?.bgLayerVisible}` || 'isOSM')) {
                l.setVisible(true)
              } else if (l?.get('isBg')) {
                l?.setVisible(false)
              }
            })
      }
    } catch (err) {
      handleErr({ err })
    }
  }, [userSettings?.globalMap?.bgLayerVisible, map])

  useEffect(() => {
    // h = roads only
    // m = standard roadmap
    // p = terrain
    // r = somehow altered roadmap
    // s = satellite only
    // t = terrain only
    // y = hybrid
    if (mounted.current) {
      mapRef.current = new Map({
        // target: mapEl.current,
        // target: props.id || 'map',
        // target: undefined,
        loadTilesWhileAnimating: true,
        layers: [
          new TileLayer({
            visible: false,
            isBg: true,
            hybrid: true,
            maxZoom: 22.22,
            // opacity: 0.77,
            source: new XYZ({
              url: '//mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}',
              // url: `https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'`,
            }),
          }),
          new TileLayer({
            visible: false,
            isBg: true,
            streetOnly: true,
            maxZoom: 22.22,
            // opacity: 0.7,
            source: new XYZ({
              // url: 'https://a.tile.openstreetmap.org/{z}/{x}/{y}.png',
              url: '//mt0.google.com/vt/lyrs=p&hl=en&x={x}&y={y}&z={z}',
              // url: `https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'`,
            }),
          }),
          new TileLayer({
            isBg: true,
            isOSM: true,
            maxZoom: 22.22,
            // opacity: 0.77,
            source: new OSM(),
            visible: false,
          }),
          // always last one
          new VectorLayer({
            source: new VectorSource(),
          }),
        ],
        view: new View({
          center:
            getUserSettings()?.globalMap?.center ||
            fromLonLat([
              props.routeData
                ? props.routeData?.nearest[0]?.location[0]
                : // : props.coords && props.coords?.length
                  // ? props.coords[props.coords?.length - 1]?.location?.lng
                  // : coordsState && coordsState.length
                  // ? coordsState[0]?.location?.lng
                  companyInfo?.location?.lng || '-80.3597315',
              props.routeData
                ? props.routeData?.nearest[0]?.location[1]
                : // : props.coords && props.coords?.length
                  // ? props.coords[props.coords?.length - 1]?.location?.lat
                  // : coordsState && coordsState.length
                  // ? coordsState[0]?.location?.lat
                  companyInfo?.location?.lat || '25.7151935',
            ]),
          zoom: getUserSettings()?.globalMap?.zoom
            ? getUserSettings()?.globalMap?.zoom
            : props.zoom
            ? props.zoom
            : 12.888,
          // padding: [77, 77, 77, 77],
        }),
        controls: mobileOS() ? defaultControls() : defaultControls().extend([new FullScreen()]),
        interactions: defaults({
          dragPan: true,
          mouseWheelZoom: true,
          altShiftDragRotate: false,
          pinchRotate: false,
        }),
        // .extend([
        //   new DragPan({
        //     condition: function (e) {
        //       return this.getPointerCount() === 2 || platformModifierKeyOnly(e)
        //     },
        //   }),
        // new MouseWheelZoom({
        //   condition: platformModifierKeyOnly,
        // }),
        // ]),
      })
      setMap(mapRef.current)
      mapRef.current?.setTarget(mapEl.current)
      if (mapRef.current?.on) {
        mapRef.current?.on('movestart', (e) => {
          e?.preventDefault && e.preventDefault()
          moveEnded.current = false
          toggleSlow({ action: 'remove' })
        })

        mapRef.current?.on('moveend', (e) => {
          e?.preventDefault && e.preventDefault()
          // mapRef.current?.on('loadstart', () => {
          //   mapRef.current?.getTargetElement().classList.add('spinner')
          // })
          // mapRef.current?.on('loadend', () => {
          //   mapRef.current?.getTargetElement().classList.remove('spinner')
          // })
          moveEnded.current = true
          const zoom = mapRef.current?.getView()?.getZoom()
          const center = mapRef.current?.getView()?.getCenter()
          if (zoom > 22.22) {
            mapRef.current?.getView().setZoom(22.22)
          }
          if (
            getUserSettings()?.globalMap?.zoom !== zoom ||
            JSON.stringify(getUserSettings()?.globalMap?.center) !== JSON.stringify(center)
          ) {
            dispatch(
              updateUserSettings({
                globalMap: {
                  ...getUserSettings()?.globalMap,
                  zoom,
                  center,
                },
              }),
            )
          }
        })

        // mapRef.current?.on('click', handleMapClick)
      }
      // setTimeout(async () => getFacilities(), 777)
    }
    return () => {
      mapRef.current?.setTarget(null)
      mapRef.current?.dispose()
      mapRef.current = null
    }
  }, [
    dispatch,
    props.zoom,
    props.routeData,
    companyInfo?.location?.lat,
    companyInfo?.location?.lng,
    // vectorLayer,
  ])

  const findOverLayInMap = ({ id }) =>
    mapRef.current
      ?.getOverlays()
      .getArray()
      .slice()
      ?.find((ov) => ov?.id === id)

  const isMarker = ({ overlay }) => overlay?.getProperties()?.element?.id?.indexOf(`marker`) >= 0
  const isVehicleMarker = ({ overlay }) =>
    overlay?.getProperties()?.element?.id?.indexOf(`vehicle-marker`) >= 0

  const isLabel = ({ overlay }) => overlay?.getProperties()?.element?.id?.indexOf(`label`) >= 0
  const isVehicleLabel = ({ overlay }) =>
    overlay?.getProperties()?.element?.id?.indexOf(`vehicle-label`) >= 0

  const updateOverlays = ({ coordsState }) =>
    new Promise((resolve) => {
      try {
        const count = { idx: 0 }
        const overLays = mapRef.current
          ?.getOverlays()
          .getArray()
          .slice()
          .filter(
            (ov, i) =>
              ov?.id?.indexOf(`facility-label`) === -1 && ov?.id?.indexOf(`facility-marker`) === -1,
            // (ov, i) => ov?.id !== `facility-label-${i}` && ov?.id !== `headquarter-marker-${i}`,
          )
        if (!overLays?.length) return resolve(true)
        overLays.forEach((overlay, i) => {
          const overlayCoord = coordsState?.find((c) => c?.id === overlay.id)
          if (!overlayCoord) {
            overlay?.getRenderer && overlay.getRenderer().dispose()
            overlay.setSource && overlay.setSource(null)
            overlay.setMap && overlay.setMap(null)
            mapRef.current?.removeOverlay(overlay)
          } else {
            const { location } = overlayCoord
            const { lng, lat } = location
            if (validCoords({ coords: location })) {
              const overlayPos = overlay.getPosition()
              const cFromLonLat = fromLonLat([lng, lat])
              // overlay.setPosition([lng, lat])
              if (overlayPos[0] !== cFromLonLat[0] || overlayPos[1] !== cFromLonLat[1]) {
                const el = document.getElementById(overlay.getProperties()?.element?.id)?.parentNode

                // if (isVehicleMarker({ overlay }) || isVehicleLabel({ overlay })) {
                //   console.log('isVehicleMarker({ overlay }): ', isVehicleMarker({ overlay }))
                //   dispatch(
                //     updateUserSettings({
                //       // ...(userSettingsRef.current || {}),
                //       globalMap: {
                //         ...getUserSettings()?.globalMap,
                //         prevCoords: [
                //           ...(getUserSettings()?.globalMap?.prevCoords?.filter(
                //             (c) => c.id !== overlayCoord?.id,
                //           ) || []),
                //           overlayCoord,
                //         ],
                //       },
                //     }),
                //   )
                // }
                if (el && (isVehicleMarker({ overlay }) || isVehicleLabel({ overlay }))) {
                  toggleSlow({ el, action: 'add' })
                  toggleSlow({
                    el,
                    action: 'remove',
                    delay: 3003,
                    // cb: () => {
                    //   dispatch(
                    //     updateUserSettings({
                    //       globalMap: {
                    //         ...getUserSettings()?.globalMap,
                    //         prevCoords: [
                    //           ...(getUserSettings()?.globalMap?.prevCoords?.filter(
                    //             (c) => c?.id !== overlayCoord?.id,
                    //           ) || []),
                    //           overlayCoord,
                    //         ],
                    //       },
                    //     }),
                    //   )
                    // console.log(prevCoords.current)
                    // prevCoords.current = !prevCoords.current?.length
                    //   ? [coord]
                    //   : prevCoords.current?.map((c) => {
                    //       if (c.id === coord.id) {
                    //         return { ...coord }
                    //       }
                    //       return { ...c } || { ...coord }
                    //     })
                    // },
                  })
                }
                setTimeout(() => {
                  overlay.setPosition(cFromLonLat)
                  overlay.changed()
                  // refreshMap()
                }, 7)
              }
            }
            // overlay.setCoordinates(fromLonLat([overlayCoord?.lng, overlayCoord?.lat]))
            // mapRef.current?.render()
            // overlay.getGeometry().setCoordinates([overlayCoord?.lng, overlayCoord?.lat])
          }
          if (count.idx === i) {
            // toggleSlow({ action: 'add' })
            // toggleSlow({ action: 'remove', delay: 3003 })
            // document.querySelectorAll(`.vehicle-label, .vehicle-marker`)?.forEach((slowEl) => {
            //   slowEl?.classList?.add(`show-slow-3s`)
            // })
            // setTimeout(() => {
            //   document.querySelectorAll(`.vehicle-label, .vehicle-marker`)?.forEach((slowEl) => {
            //     slowEl?.classList?.remove(`show-slow-3s`)
            //   })
            // }, 3003)
            // mapRef?.current?.getSource && mapRef?.current?.getSource().changed()
            // mapRef?.current?.updateSize && mapRef?.current?.updateSize()
            // refreshMap()

            resolve(true)
          }
          count.idx++
        })
      } catch (err) {
        handleErr({ err })
        resolve(true)
      }
    })

  const updateRoutesTrack = ({ coordsState }) =>
    new Promise((resolve) => {
      try {
        const vehiclesCoords = coordsState?.filter((c) => isVehicle(c))
        const count = { idx: 0 }
        if (!features?.length) resolve(true)
        const foundFeatures = []
        features.forEach((track, i) => {
          const trackCoords = track.getGeometry().getCoordinates()
          // const trackStartCoord = fromLonLat(trackCoords[0])
          // const trackEndCoord = fromLonLat(trackCoords[trackCoords?.length - 1])
          const vehicleOnTrack = vehiclesCoords.some((v) => {
            const { location } = v
            const { lng, lat } = location
            const vLoc = fromLonLat([lng, lat])
            const foundIdx = trackCoords.findIndex((tCoord) => {
              const tFromLonLat = fromLonLat([tCoord[0], tCoord[1]])
              return vLoc[0] === tFromLonLat[0] && vLoc[1] === tFromLonLat[1]
              // return vLoc[0] === tCoord[0] && vLoc[1] === tCoord[1]
            })
            if (foundIdx >= 0) {
              const updatedTrackCoords = trackCoords.slice(0, foundIdx)
              // console.log('updatedTrackCoords: ', updatedTrackCoords)
              track.getGeometry().setCoordinates(updatedTrackCoords)
              return true
            }
            return false
            // return (
            //   vLoc[0] !== trackStartCoord[0] &&
            //   vLoc[1] !== trackStartCoord[1] &&
            //   vLoc[0] !== trackEndCoord[0] &&
            //   vLoc[1] !== trackEndCoord[1]
            // )
          })

          console.log('vehicleOnTrack: ', vehicleOnTrack)
          if (vehicleOnTrack) {
            foundFeatures.push(track)
          }
          if (count.idx === i) {
            setFeatures(foundFeatures)
            resolve(true)
          }
          count.idx++
        })
      } catch (err) {
        handleErr({ err })
      }
    })

  const cleanMap = ({ coordsState }) =>
    new Promise(async (resolve) => {
      setDistances('')
      setDurations('')
      await updateOverlays({ coordsState })
      await updateRoutesTrack({ coordsState })
      resolve(true)
    })

  const processCoords = ({ coordsState }) =>
    new Promise(async (resolve) => {
      try {
        setProcessCoordsEnded(false)
        const count = { idx: 1, cordCounters: 1, cIdx: 1 }
        const distances = []
        const durations = []
        const routesCreated = []
        const uniqueCoords = coordsState //uniqueByKey({ arr: coordsState, key: 'id' })
        if (uniqueCoords?.length) {
          const points = []
          for (const coord of uniqueCoords) {
            count.cIdx++
            if (coord) {
              const {
                // eta,
                // name,
                // note,
                // date,
                // speed,
                // status,
                // driver,
                // isArrive,
                // isPickup,
                // isDropoff,
                location,
                tripState,
                tripType,
                coordType,
                // fuelLevel,
                driverName,
                customerName,
                tripDirection,
                assignDriver,
                showEditTripModal,
                isLocationPin,
              } = coord
              const { lat, lng } = location || {}
              if (lng && lat) {
                const coord_street = [lng, lat]
                const coordFromStreet = {
                  location: {
                    lng: coord_street[0] || coord_street?.lng,
                    lat: coord_street[1] || coord_street?.lat,
                  },
                }
                const overlay = findOverLayInMap({ id: coord.id })
                const commonArgs = {
                  id: coord.id,
                  coord: coordFromStreet,
                  customerName,
                  driverName,
                  assignDriver,
                  showEditTripModal,
                }
                const args =
                  isDelivery({ tripType }) ||
                  isRider(coordType, tripType) ||
                  isVehicle(coordType) ||
                  isLocationPin ||
                  isMarker({ overlay })
                    ? {
                        ...commonArgs,
                        type: isVehicle(coordType) ? 'vehicle-marker' : 'marker',
                        className: isVehicle(coordType) ? 'vehicle-marker' : 'marker',
                        content: [
                          markerContent({ coord, uniqueCoords }),
                          labelContent({ coord, uniqueCoords, count }),
                        ],
                      }
                    : !isDriverCoord(coordType) && !isCorp(coordType)
                    ? {
                        ...commonArgs,
                        type: isVehicle(coordType) ? 'vehicle-label' : 'label',
                        className: isVehicle(coordType) ? 'vehicle-label' : 'label',
                        content: [labelContent({ coord, uniqueCoords, count })],
                      }
                    : ''

                if (args) {
                  if (!overlay) {
                    createEl(args)
                  } else if (
                    overlay.getElement &&
                    !overlay.getElement().isEqualNode(addElementContent(args))
                  ) {
                    overlay.setElement(addElementContent(args))
                    overlay.changed()
                  }

                  count.idx =
                    isEnded({ tripState }) || isCanceled({ tripState })
                      ? count.idx + 1
                      : uniqueCoords[count.cordCounters - 1]?.driver !==
                        uniqueCoords[count.cordCounters]?.driver
                      ? 1
                      : isRider(coordType, tripType) ||
                        isDelivery({ tripType }) ||
                        isDestination(coordType)
                      ? count.idx + 1
                      : count.idx
                }

                const last_point = points[points.length - 1]
                points.push(coord_street)
                const point1 = last_point
                const point2 = coord_street

                const trajectoryId = `trajectory-${coord.id}`
                if (
                  !features?.find((f) => f?.id === trajectoryId) &&
                  getUserSettings()?.globalMap?.showRoute &&
                  coord.driver &&
                  coord.driver !== 'headquarter' &&
                  props?.showRoute !== false &&
                  (coord.startRoute ||
                    ((coord.startRoute !== false || isDelivery({ tripType })) &&
                      uniqueCoords[count.cIdx - 2]?.coordType === 'vehicle' &&
                      uniqueCoords[count.cIdx - 1]?.coordType !== 'vehicle' &&
                      uniqueCoords[count.cIdx - 2]?.driver ===
                        uniqueCoords[count.cIdx - 1]?.driver) === coord.driver) &&
                  point1 &&
                  mounted.current
                ) {
                  const track = await getTrack(point1, point2)
                  if (track.code === 'Ok' && track.routes?.length && mounted.current) {
                    count.cIdx++
                    const { distance, duration, geometry } = track.routes[0]
                    if (
                      onlyOneVehicleisStart() ||
                      (!onlyOneVehicleisPkDp() && count.idx === (onlyDelivery() ? 1 : 2)) ||
                      (onlyOneVehicleisPkDp() && count.cIdx === uniqueCoords?.length)
                    ) {
                      distances.push(metersToMiles(distance))
                      durations.push(duration)
                    }

                    routesCreated.push({
                      id: trajectoryId,
                      geometry,
                      direction:
                        !tripDirection || tripDirection !== 'returning' ? 'going' : 'returning',
                    })
                  }
                }

                if (count.cordCounters === uniqueCoords?.length && mounted.current) {
                  // const newFeatures = []
                  // routesCreated.forEach((r) => {
                  //   newFeatures.push(createRoute(r))
                  // })
                  setFeatures(routesCreated.map((r) => createRoute(r)))
                  setProcessCoordsEnded(true)
                  // coordsStateRef.current = coordsState
                  // dispatch(
                  //   updateUserSettings({
                  //     // ...(userSettingsRef.current || {}),
                  //     globalMap: {
                  //       ...getUserSettings()?.globalMap,
                  //       prevCoords: coordsState,
                  //     },
                  //   }),
                  // )
                  resolve(true)
                }
              }
              count.cordCounters++
            }
          }

          if (mounted.current && onlyOneVehicle()) {
            setDistances(distances)
            setDurations(durations)
          }
        } else {
          setProcessCoordsEnded(true)
          // coordsStateRef.current = coordsState
          // dispatch(
          //   updateUserSettings({
          //     // ...(userSettingsRef.current || {}),
          //     globalMap: {
          //       ...getUserSettings()?.globalMap,
          //       prevCoords: coordsState,
          //     },
          //   }),
          // )
          resolve(true)
        }
      } catch (err) {
        handleErr({ err })
        // coordsStateRef.current = coordsState
        // dispatch(
        //   updateUserSettings({
        //     // ...(userSettingsRef.current || {}),
        //     globalMap: {
        //       ...getUserSettings()?.globalMap,
        //       prevCoords: coordsState,
        //     },
        //   }),
        // )
        resolve(true)
      }
    })

  useEffect(() => {
    if (mounted.current && map?.on) {
      const filteredCoords = userSettings?.globalMap?.showVehiclesOnly
        ? coordsState?.filter((c) => isVehicle(c?.coordType))
        : coordsState
      // console.log(
      //   coordsState,
      //   filteredCoords,
      //   userSettings?.globalMap?.coords,
      //   userSettings?.globalMap?.prevCoords,
      //   !areEquals(filteredCoords, userSettings?.globalMap?.prevCoords),
      //   // !areEquals(coordsState, getUserSettings()?.globalMap.coords),
      // )
      const diffCoords = filteredCoords
        ?.map((coord) => {
          const foundCoord = userSettings?.globalMap?.prevCoords?.find((c) => c?.id === coord?.id)
          if (foundCoord && areEquals(foundCoord, coord)) {
            return {
              coord,
              foundCoord,
            }
          } else {
            return null
          }
        })
        .flat()
      // console.log(diffCoords)
      // console.log(diffCoords.some((c) => c === null))
      if (
        // !areEquals(coordsState, coordsStateRef.current) ||
        // !areEquals(coordsState, getUserSettings()?.globalMap.coords) ||
        // !areEquals(filteredCoords, userSettings?.globalMap.prevCoords) ||
        (filteredCoords?.length && !userSettings?.globalMap?.prevCoords?.length) ||
        diffCoords.some((c) => c === null) ||
        onlyOneVehicleAndOneCustomer()
      ) {
        // mapRef.current?.getTargetElement().classList.add('spinner')
        // updateOverlays({ coordsState })

        setTimeout(async () => {
          // await slowDown(77)
          await cleanMap({ coordsState: filteredCoords })
          await processCoords({ coordsState: filteredCoords })
          // refreshMap()
          dispatch(
            updateUserSettings({
              globalMap: {
                ...userSettings?.globalMap,
                coords: coordsState,
                prevCoords: filteredCoords,
              },
            }),
          )
          // coordsStateRef.current = coordsState
        }, 0)
      }
    }
  }, [
    coordsState,
    dispatch,
    map?.on,
    userSettings?.globalMap.coords,
    userSettings?.globalMap?.showVehiclesOnly,
  ])

  useEffect(() => {
    if (mounted.current && map?.on) {
      // if (!areEquals(coordsState, getUserSettings()?.globalMap.coords)) {
      setTimeout(async () => getFacilities(), 88)
      // }
    }
  }, [getFacilities, map?.on])

  // useEffect(() => {
  //   setVectorSource(new VectorSource())
  // }, [coordsState])

  // useEffect(() => {
  //   console.log(userSettings?.globalMap?.source)
  //   const map = new Map({
  //     target: mapElement.current,
  //     // target: props.id || 'map',
  //     // target: undefined,
  //     loadTilesWhileAnimating: true,
  //     layers: [
  //       new TileLayer({
  //         source: userSettings?.globalMap?.source || new OSM(),
  //         // source: new OSM(),
  //         // source: new XYZ({
  //         //   url: 'http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}',
  //         // }),
  //         // source: new XYZ({
  //         //   url: 'http://mt0.google.com/vt/lyrs=p&hl=en&x={x}&y={y}&z={z}',
  //         // }),
  //       }),
  //       vectorLayer,
  //     ],
  //     view: new View({
  //       center:
  //         getUserSettings()?.globalMap?.center ||
  //         fromLonLat([
  //           props.routeData
  //             ? props.routeData?.nearest[0].location[0]
  //             : coordsState && coordsState.length
  //             ? coordsState[0]?.location?.lng
  //             : companyInfo?.location?.lng || '-80.3597315',
  //           props.routeData
  //             ? props.routeData?.nearest[0].location[1]
  //             : coordsState && coordsState.length
  //             ? coordsState[0]?.location?.lat
  //             : companyInfo?.location?.lat || '25.7151935',
  //         ]),
  //       zoom: getUserSettings()?.globalMap?.zoom
  //         ? getUserSettings()?.globalMap?.zoom
  //         : props.zoom
  //         ? props.zoom
  //         : 12,
  //     }),
  //   })
  //   mapRef.current = map
  //   // mapElement.current = map
  //   setMap(map)
  // }, [
  //   companyInfo?.location?.lat,
  //   companyInfo?.location?.lng,
  //   coordsState,
  //   props.id,
  //   props.routeData,
  //   props.zoom,
  //   userSettings?.globalMap?.source,
  //   vectorLayer,
  // ])

  // useEffect(() => {
  //   return () => {
  //     // mapRef.current?.setTarget(undefined)
  //   }
  // }, [map])

  useEffect(() => {
    // console.log(userSettings)
    userSettingsRef.current = { ...userSettings } // clone userSettings to avoid mutation
  }, [userSettings])

  // const getUserSettings = () => userSettings
  const getUserSettings = () => userSettingsRef.current

  // useEffect(() => {
  //   // if (mapRef.current?.setTarget) mapRef.current?.setTarget(mapEl.current)
  //   // const view = mapRef.current?.getView()
  //   // const extent = view.calculateExtent(mapRef.current?.getSize())
  //   //hold the current resolution
  //   // const res = view.getResolution()
  //   //if you use older versions of ol3 use `fitExtent` istead of `fit`
  //   // view.fit(extent, mapRef.current?.getSize())
  //   //apply the resolution back
  //   // view.setResolution(res)
  //   // mapRef.current?.getView().fit(vectorSource.getExtent())
  //   // var newBound = mapRef.current?.myLayer.getDataExtent()
  //   // mapRef.current?.zoomToExtent(newBound)
  //   // console.log(mapRef.current?.getView(), mapRef.current?.getView().getCenter(), mapRef.current?.getView().getResolution())
  //   if (mapRef.current?.on) {
  //     mapRef.current?.on('movestart', (e) => {
  //       e?.preventDefault && e.preventDefault()
  //       moveEnded.current = false
  //     })

  //     mapRef.current?.on('moveend', (e) => {
  //       e?.preventDefault && e.preventDefault()
  //       // mapRef.current?.on('loadstart', () => {
  //       //   mapRef.current?.getTargetElement().classList.add('spinner')
  //       // })
  //       // mapRef.current?.on('loadend', () => {
  //       //   mapRef.current?.getTargetElement().classList.remove('spinner')
  //       // })
  //       moveEnded.current = true
  //       const zoom = mapRef.current?.getView()?.getZoom()
  //       const center = mapRef.current?.getView()?.getCenter()
  //       if (zoom > 22.22) {
  //         mapRef.current?.getView().setZoom(22.22)
  //       }
  //       console.log('globalMap', getUserSettings())
  //       if (
  //         getUserSettings()?.globalMap?.zoom !== zoom ||
  //         JSON.stringify(getUserSettings()?.globalMap?.center) !== JSON.stringify(center)
  //       ) {
  // dispatch(
  //   updateUserSettings({
  // globalMap: {
  //               ...getUserSettings()?.globalMap,
  //               zoom,
  //               center,
  //             }
  //   },
  //   }),
  // )

  //       }
  //     })

  //     // mapRef.current?.on('click', handleMapClick)
  //   }
  // }, [map])

  // useEffect(() => {
  //   if (mounted.current) {
  //     mapRef.current?.getOverlays &&
  //       map
  //         .getOverlays()
  //         .getArray()
  //         .slice(0)
  //         .forEach((overlay) => {
  //           mapRef.current?.removeOverlay(overlay)
  //         })
  //     // if (!userSettings.globalMap.showRoute) {
  //     // console.log(userSettings.globalMap.showRoute)
  //     vectorSource?.clear && vectorSource.clear(true)
  //     // console.log(vectorLayer)
  //     vectorLayer?.getSource && console.log(vectorLayer?.getSource()?.clear(true))
  //     // }
  //     // setTimeout(async () => {
  //     //   mapRef.current?.renderSync && mapRef.current?.renderSync()
  //     //   // mapRef.current?.render && mapRef.current?.render()
  //     // }, 707)
  //   }
  // }, [map, uniqueCoords, userSettings.globalMap.showRoute, vectorSource, vectorLayer])

  useEffect(() => {
    if (mounted.current) {
      setShowDistance(
        distances?.length
          ? Number(
              distances.reduce(
                (totalDistance, distance) => Number(totalDistance) + Number(distance),
              ),
            )
          : '',
      )
    }
  }, [distances])

  useEffect(() => {
    if (mounted.current) {
      setDurationData(
        durations?.length
          ? Number(
              durations.reduce(
                (totalDuration, duration) => Number(totalDuration) + Number(duration),
              ),
            )
          : '',
      )
    }
  }, [durations])

  return (
    <>
      {(props?.showDuration || userSettings.globalMap.showRoute) && durationData ? (
        <div className={`map-header`}>
          <div className="distance-box d-inline-block row text-center justify-content-center">
            <span className="me-2 fw-bold">
              {showDistance && Number(showDistance) > 0 ? (
                <>{Number(showDistance).toFixed(2)} mi</>
              ) : (
                ''
              )}
            </span>
            <span className="me-2 fw-bold">
              {durationData && Number(durationData) > 0 ? (
                <>
                  <CIcon icon={cilClock} /> {secondsToTime(Number(durationData))} min
                </>
              ) : (
                ''
              )}
            </span>

            {/* {showDistance?.length
          ? showDistance.map((distance, idx) => (
              <label key={idx} className="col-2 m-2">
                {idx + 1}: {distance} Miles
              </label>
            ))
          : null} */}
          </div>
        </div>
      ) : null}
      <div className="map-handlers">
        <button
          onClick={(e) => {
            e.preventDefault()
            const showRoute = userSettings?.globalMap?.showRoute ? false : true
            dispatch(
              updateUserSettings({
                globalMap: { ...userSettings?.globalMap, showRoute },
              }),
            )
            setTimeout(async () => {
              if (mapRef.current) {
                await cleanMap({ coordsState })
                await processCoords({ coordsState })
              }
            }, 77)
          }}
        >
          <CIcon
            icon={cilFork}
            size="xxl"
            className={`p-1 ${
              userSettings?.globalMap?.showRoute
                ? 'bg-deep-green text-white'
                : 'bg-white text-secondary'
            }`}
          />
        </button>
        <button
          onClick={(e) => {
            e?.preventDefault()
            const showVehiclesOnly = userSettings?.globalMap?.showVehiclesOnly ? false : true
            const filteredCoords = showVehiclesOnly
              ? coordsState?.filter((c) => isVehicle(c?.coordType))
              : coordsState
            setTimeout(async () => {
              // console.log({ filteredCoords })
              if (mapRef.current) {
                await cleanMap({ coordsState: [] })
                await processCoords({ coordsState: filteredCoords })
                dispatch(
                  updateUserSettings({
                    globalMap: { ...userSettings?.globalMap, showVehiclesOnly },
                  }),
                )
              }
            }, 77)
          }}
        >
          <CIcon
            icon={cilBusAlt}
            size="xxl"
            className={`p-1 ${
              userSettings?.globalMap?.showVehiclesOnly
                ? `bg-green text-white`
                : `bg-white text-secondary`
            }`}
          />
        </button>
        <button
          onClick={(e) => {
            e?.preventDefault()
            setToggleLayerBtns((prev) => !prev)
          }}
        >
          <CIcon
            icon={cibBuffer}
            size="xxl"
            // className="p-1 text-deep-green bg-white"
            className={`p-1 ${
              toggleLayerBtns ? 'bg-deep-green text-white' : 'bg-white text-secondary'
            }`}
          />
        </button>
        {!toggleLayerBtns ? null : (
          <>
            <button
              onClick={(e) => {
                e.preventDefault()
                setBgLayerVisible('hybrid')
              }}
            >
              <CIcon
                icon={cilTerrain}
                size="xxl"
                className={`p-1 ${
                  bgLayerVisible === 'hybrid' ? 'bg-success text-white' : 'bg-white text-deep-green'
                }`}
              />
            </button>
            <button
              onClick={(e) => {
                e.preventDefault()
                setBgLayerVisible('streetOnly')
              }}
            >
              <CIcon
                icon={cilLayers}
                size="xxl"
                className={`p-1 ${
                  bgLayerVisible === 'streetOnly'
                    ? 'bg-success text-white'
                    : 'bg-white text-primary'
                }`}
              />
            </button>
            <button
              onClick={(e) => {
                e.preventDefault()
                setBgLayerVisible('isOSM')
              }}
            >
              <CIcon
                icon={cilLayers}
                size="xxl"
                className={`p-1 ${
                  bgLayerVisible === 'isOSM' ? 'bg-success text-white' : 'bg-white text-info'
                }`}
              />
            </button>
          </>
        )}
      </div>
      <div
        ref={mapEl}
        tabIndex="1"
        id={`${props.id || `map${Date.now()}`}`}
        // className="show-easy"
        className={`${props.rounded ? `rounded overflow-hidden` : ``}`}
        style={{
          width: props.width || '100%',
          minHeight: props.height || '50vh',
          height: props.height || `${document?.body?.scrollHeight}px` || '50vh',
          overflow: 'hidden',
          // opacity: 0.5,
        }}
      ></div>
    </>
  )
  //   return <div className="row">{mapBox}</div>
}

OlMap.propTypes = {
  id: PropTypes.any,
  coords: PropTypes.any,
  rounded: PropTypes.bool,
  autoPan: PropTypes.bool,
  width: PropTypes.any,
  height: PropTypes.any,
  zoom: PropTypes.number,
  routeData: PropTypes.any,
  showOrder: PropTypes.bool,
  showDuration: PropTypes.bool,
  showRoute: PropTypes.bool,
  showLoader: PropTypes.bool,
}
export default React.memo(OlMap)
