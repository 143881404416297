import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { List, arrayMove } from 'react-movable'
import getTrack from 'src/utils/get-track'
import '../../scss/stripe-card.css'
// import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import { CCard, CCardBody, CFormSelect } from '@coreui/react'
import {
  cilUser,
  cilGroup,
  cilLocationPin,
  cilClock,
  cilPlus,
  cilSearch,
  cilXCircle,
  cilMenu,
  cilArrowThickRight,
  cilLoopCircular,
  cilBullhorn,
  cibShopify,
  cilInfo,
} from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import handleErr from 'src/utils/handle-error'
import { metersToMiles } from 'src/utils/get-miles'
import Capitalize from 'src/utils/capitalize'
import OlMap from '../widget/OlMap'
import { formatToHTMLinput, getETA, secondsToTime } from 'src/utils/handle-date'
import { getAddressSuggestions, getPlaceDetails, sendBookingReq } from 'src/actions/userActions'
import {
  slowDown,
  validateEmail,
  validatePhone,
  validCoords,
  areEquals,
  isRide,
} from 'src/utils/helpers'
import isEmpty from 'src/utils/is-empty'
import toggleModal from 'src/components/toggle-modal'
import vehicleMaxCapacity from 'src/utils/vehicle-max-capacity'
import vehicleTypes from 'src/utils/vehicle-types'
import LogoBig from 'src/components/LogoBig'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import geoLocation from 'src/utils/geoLocation'
import formatPhone from 'src/utils/format-phone'
import { ThreeDots } from 'react-loader-spinner'
import CheckoutForm from './CheckoutForm'
import notify from 'src/utils/notify'
import { mileToMeters } from 'src/utils/get-meters'
import VehicleIcon from 'src/components/VehicleIcon'
import { BASE_URL } from 'src/services/api'
import axios from 'axios'
import OtpModal from '../pages/login/OtpModal'

// const YOUR_STRIPE_PUBLISHABLE_KEY =
//   process.env.NODE_ENV === 'development'
//     ? 'pk_test_51QNodaGOePl3XYUX3GaCwSPmS7hnEX3rw7G23203zCBy8CEbd10XndSVQ0INf0NsvCzThqlOTpf2ohMuimuA3Dwo00Jdn9DDeF'
//     : 'pk_live_51QNodaGOePl3XYUXGUY4cLr7878odOQYglPATQN0aNt6CvTIXalToGZ8XnuLucM1MFDbPVxdvdWJHdfE3NbKOdCQ00nGLcKZ0v'
// const stripePromise = loadStripe(YOUR_STRIPE_PUBLISHABLE_KEY)

const Booking = ({ brokerPricing, vehicleType, companyData }) => {
  const dispatch = useDispatch()
  const { userInfo, companyInfo } = useSelector((state) => state?.companyAuth)
  // const [startAddress, setStartAddress] = useState('')
  // const [endAddress, setEndAddress] = useState('')
  // const [startCoords, setStartCoords] = useState(null)
  // const [endCoords, setEndCoords] = useState(null)
  const [distance, setDistance] = useState(0)
  const [duration, setDuration] = useState(0)
  const [customerExist, setCustomerExist] = useState(false)
  const [showMap, setShowMap] = useState(null)
  const [price, setPrice] = useState(0)
  const [ETA, setETA] = useState('')
  const [vehicleTypeState, setVehicleTypeState] = useState('sedan')
  const [riderCount, setRiderCount] = useState(1)
  const [coordsCount, setCoordsCount] = useState(1)
  const [addressesDescriptions, setAddressesDescriptions] = useState([])
  const [addresses, setAddresses] = useState([])
  const [addressIdx, setAddressIdx] = useState(null)
  const [coords, setCoords] = useState([])
  const [myLocationCoords, setMyLocationCoords] = useState()
  const [isCalculating, setIsCalculating] = useState(false)
  const [dateTime, setDateTime] = useState({})
  const [isSearching, setIsSearching] = useState(false)
  const [suggestions, setSuggestions] = useState([])
  const [showModal, setShowModal] = useState(null)
  const [tripMode, setTripMode] = useState('oneway') // roundtrip
  const [tripType, setTripType] = useState('ride')
  const [showMyLocationButton, setShowMyLocationButton] = useState(false)
  const [bookingCreated, setBookingCreated] = useState(false)
  const [links, setLinks] = useState({
    stripeLink: '',
    paypalLink: '',
  })

  const startTypingTime = useRef()

  const stripePromise = loadStripe(
    process.env.NODE_ENV === 'development'
      ? 'pk_test_51QNodaGOePl3XYUX3GaCwSPmS7hnEX3rw7G23203zCBy8CEbd10XndSVQ0INf0NsvCzThqlOTpf2ohMuimuA3Dwo00Jdn9DDeF'
      : 'pk_live_51QNodaGOePl3XYUXGUY4cLr7878odOQYglPATQN0aNt6CvTIXalToGZ8XnuLucM1MFDbPVxdvdWJHdfE3NbKOdCQ00nGLcKZ0v',
  )

  // const stripe = useStripe()
  // const elements = useElements()

  const locationDifferentFromMyLocation = ({ coords, myLocationCoords }) => {
    return (
      !myLocationCoords?.length ||
      !coords[0] ||
      !coords[1] ||
      (coords[0] && !areEquals(myLocationCoords, coords[0])) ||
      (coords[1] && !areEquals(myLocationCoords, coords[1]))
    )
  }

  const getClientCoords = async () => {
    try {
      // const loadingToastId = toast.loading('Getting your Location..')
      // setTimeout(() => {
      toast.loading(`Getting your Location...`, { toastId: 'getgeolocation' })
      // }, 7)
      const clientCoords = await geoLocation({ userInfo })
      // console.log(clientCoords)
      setTimeout(() => toast.dismiss('getgeolocation'), 3)
      if (!validCoords({ coords: clientCoords })) return
      const myLocationCoords = [clientCoords.lng, clientCoords.lat]
      setMyLocationCoords(myLocationCoords)
      // console.log(coords[0])
      // console.log(coords[1])
      // console.log(myLocationCoords)

      if (locationDifferentFromMyLocation({ coords, myLocationCoords })) {
        setAddressIdx(0)
        const newAddresses = ['Pin Dropped', ...addresses.filter((a) => a)]
        setAddresses(newAddresses)
        const newAddressesDescs = ['Pin Dropped', ...addressesDescriptions.filter((a) => a)]
        setAddressesDescriptions(newAddressesDescs)
        setCoords([myLocationCoords, ...coords])
        // console.log(addresses, newAddresses, newAddresses[coordsCount - 1], coordsCount)
        if (coordsCount < 2 && newAddresses[coordsCount - 1]) {
          setCoordsCount((x) => x + 1)
        }
      }
    } catch (err) {
      handleErr({ err, userInfo })
    }
  }

  useEffect(() => {
    getClientCoords()
  }, [])

  const handleDateTimeChange = (e) => {
    const { name, value } = e.target
    if (value && distance) {
      setETA(
        getETA({
          time: formatToHTMLinput({ dateData: new Date(value) }),
          meters: mileToMeters(distance),
        }),
      )
    }

    setDateTime((prev) => ({ ...prev, [name]: value }))
  }

  const calculateFare = ({ basePrice, distance, riderCount, tripMode }) => {
    try {
      const multiply = tripMode === 'roundtrip' ? 2 : 1
      const oneRiderPrice = multiply * (1.2 * distance + basePrice)
      if (+riderCount === 1) return Number(oneRiderPrice).toFixed(2)
      const ridersLeft = +riderCount - 1
      return (oneRiderPrice + oneRiderPrice * 0.25 * ridersLeft).toFixed(2)
    } catch (err) {
      handleErr({ err, userInfo })
      return 0
    }
  }

  const handleSearch = async ({ fullAddress, i }) => {
    try {
      const addressesCopy = [...addresses]
      const addressesDescsCopy = [...addressesDescriptions]
      // addressesCopy[i] = fullAddress
      // setAddresses(addressesCopy)
      // setAddressIdx(i)
      // setIsSearching(true)
      // await slowDown(1707)
      // console.log(startTypingTime.current)
      // console.log(isDelay({ lastUpdate: startTypingTime.current, limit: 1 }))
      // if (
      //   !startTypingTime.current ||
      //   (startTypingTime.current && !isDelay({ lastUpdate: startTypingTime.current, limit: 1 }))
      // ) {
      //   return
      // }
      startTypingTime.current = null
      if (!fullAddress) {
        setIsSearching(false)
        setSuggestions([])
        addressesCopy.splice(i, 1)
        setAddresses(addressesCopy)
        addressesDescsCopy.splice(i, 1)
        addressesDescsCopy(addressesDescsCopy)
        const coordsCopy = [...coords]
        coordsCopy.splice(i, 1)
        setCoords(coordsCopy)
        if (i === 0 && locationDifferentFromMyLocation({ coords: coordsCopy, myLocationCoords })) {
          setShowMyLocationButton(true)
        }
        return
      }
      const { data } = (await getAddressSuggestions({ fullAddress })) || {}
      setIsSearching(false)
      if (isEmpty(data)) return
      setSuggestions(data?.predictions?.length ? data?.predictions?.slice(0, 6) : []) // Get top 6 suggestions
    } catch (err) {
      handleErr({ err, alert: true, userInfo })
      setIsSearching(false)
    }
  }

  const handleSelect = async ({ e, suggestion }) => {
    try {
      e?.preventDefault && e.preventDefault()
      setIsSearching(true)
      // console.log(suggestion)
      setSuggestions([])
      // const addressesCopy = [...addresses]
      // addressesCopy[addressIdx] = suggestion?.description
      // setAddresses(addressesCopy)

      const { data } = (await getPlaceDetails({ placeId: suggestion.place_id })) || {}

      if (isEmpty(data) || data?.status !== 'OK') {
        await slowDown(707)
        setIsSearching(false)
        return
      }

      const addressesCopy = [...addresses]
      addressesCopy[addressIdx] = data.result?.formatted_address || suggestion?.description
      setAddresses(addressesCopy)
      const addressesDescsCopy = [...addressesDescriptions]
      addressesDescsCopy[addressIdx] = suggestion?.description
      setAddressesDescriptions(addressesDescsCopy)

      const coordsCopy = [...coords]
      coordsCopy[addressIdx] = [
        data.result.geometry.location.lng,
        data.result.geometry.location.lat,
      ]
      setCoords(coordsCopy)
      if (coordsCount <= 1) setCoordsCount(2)
      setIsSearching(false)
      // setCoords([latLng.lng, latLng.lat]) // Setting coordinates in [lng, lat] format
    } catch (error) {
      console.error('Error: ', error)
    }
  }

  useEffect(() => {
    if (addresses?.length && addressIdx >= 0 && isSearching) {
      const d = setTimeout(() => {
        handleSearch({ fullAddress: addresses[addressIdx], i: addressIdx })
      }, 3003)
      return () => clearTimeout(d)
    }
  }, [addresses, addressIdx])

  useEffect(() => {
    if (riderCount > vehicleMaxCapacity[vehicleTypeState]) {
      const suitableVehicleType = vehicleTypes.find((v) => riderCount <= vehicleMaxCapacity[v])
      if (suitableVehicleType) {
        setVehicleTypeState(suitableVehicleType)
      }
    }
  }, [riderCount])

  useEffect(() => {
    if (riderCount > vehicleMaxCapacity[vehicleTypeState]) {
      setRiderCount(vehicleMaxCapacity[vehicleTypeState])
    }
  }, [vehicleTypeState])

  const calculateDurationAndDistance = async (coords) => {
    try {
      setDistance(0)
      setDuration(0)

      if (coords.length < 2) {
        setSuggestions([])
        return
      }
      // const point1 = [-87.75136608077267, 41.92429003497408]
      // const point2 = [-87.76125806730694, 41.92406652295526]
      setIsCalculating(true)
      let sumMeters = 0

      coords.forEach(async (coord, i, arr) => {
        const coord2 = arr[i + 1]
        // console.log(coord, coord2, areEquals(coord, coord2))
        if (!coord2 || areEquals(coord, coord2)) {
          setIsCalculating(false)
          return false
        }
        const track = (await getTrack(coord, coord2)) || {}
        if (isEmpty(track) || isEmpty(track?.routes)) return
        const meters = track?.routes[0]?.distance
        const distanceInMiles = metersToMiles(meters)
        const durationTime = Number(track?.routes[0]?.duration)
        // const distanceInMiles = track.routes[0].distance / 1609
        // setDuration(durationTime)
        setDuration((duration) => duration + durationTime)
        setDistance((distance) => distance + distanceInMiles)

        const expectedPickupTime1 =
          !dateTime?.expectedPickupTime1 || new Date(dateTime?.expectedPickupTime1) < new Date()
            ? formatToHTMLinput({})
            : dateTime?.expectedPickupTime1
        // if (
        //   !dateTime?.expectedPickupTime1 ||
        //   new Date(dateTime?.expectedPickupTime1) < new Date()
        // ) {
        const ETA = getETA({ time: expectedPickupTime1, meters: sumMeters + meters })
        const expectedDropoffTime1 =
          ETA?.etaDate && new Date(ETA?.etaDate) > new Date(expectedPickupTime1)
            ? ETA?.etaDate
            : expectedPickupTime1
        setDateTime((prev) => ({ ...prev, expectedPickupTime1, expectedDropoffTime1 }))
        // }
        setETA(ETA)
        // console.log(getETA({ time: expectedPickupTime1, meters: sumMeters + meters }))
        // console.log(sumMeters)
        // console.log(meters)
        // console.log(sumMeters + meters)
        if (i >= arr.length - 1) setIsCalculating(false)
      })

      // setIsCalculating(false)
    } catch (err) {
      setIsCalculating(false)
      handleErr({ err, userInfo })
    }
  }

  useEffect(() => {
    try {
      if (isEmpty(brokerPricing)) return
      if (distance <= 0 || coords.length < 2) {
        setPrice(0)
        return
      }
      const calculatedPrice = calculateFare({
        basePrice: brokerPricing[vehicleTypeState],
        distance,
        riderCount,
        tripMode,
      })

      setPrice(calculatedPrice)
    } catch (err) {
      handleErr({ err, userInfo })
    }
  }, [brokerPricing, coords.length, distance, riderCount, vehicleTypeState, tripMode])

  useEffect(() => {
    try {
      calculateDurationAndDistance(coords)
      if (coords.length) {
        const coordsModif = coords.map((coord, i, arr) => {
          return {
            id: `${coord[0]}-${coord[1]}`,
            coordType: i === 0 ? 'Customer' : 'Destination',
            tripType: 'ride',
            name: addresses[i],
            location: { lng: coord[0], lat: coord[1] },
            startRoute: true,
            driver: 'x', // i === arr.length - 1 ? 'x' : '',
          }
        })
        setShowMap(<OlMap rounded={true} autoPan={true} coords={coordsModif} height={`303px`} />)
      } else {
        setShowMap(null)
      }
    } catch (err) {
      handleErr({ err, userInfo })
    }
  }, [coords])

  // const appearance = {
  //   theme: 'stripe',

  //   variables: {
  //     colorPrimary: '#0570de',
  //     colorBackground: '#ffffff',
  //     colorText: '#30313d',
  //     colorDanger: '#df1b41',
  //     fontFamily: 'Ideal Sans, system-ui, sans-serif',
  //     spacingUnit: '2px',
  //     borderRadius: '4px',
  //     // See all possible variables below
  //   },
  // }
  const appearance = {
    theme: 'stripe',
  }
  const options = {
    // clientSecret,
    appearance,
  }

  const openPaymentModal = async ({ links }) => {
    try {
      window.scrollTo(0, 0)
      toast.dismiss()
      // document.getElementById('card-element')?.remove()
      const clientSecret = links?.stripeLink

      // const cardElement = elements.create('card', {
      //   style: {
      //     base: {
      //       iconColor: '#c4f0ff',
      //       color: '#fff',
      //       fontWeight: '500',
      //       fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      //       fontSize: '16px',
      //       fontSmoothing: 'antialiased',
      //       ':-webkit-autofill': {
      //         color: '#fce883',
      //       },
      //       '::placeholder': {
      //         color: '#87BBFD',
      //       },
      //     },
      //     invalid: {
      //       iconColor: '#FFC7EE',
      //       color: '#FFC7EE',
      //     },
      //   },
      // })
      // const newDiv = document.createElement('div')
      // newDiv.id = 'card-element'
      // newDiv.className = 'stripe-card'
      // document.body.prepend(newDiv)
      // cardElement.mount('#card-element')

      // const result = await stripe.confirmCardPayment(clientSecret, {
      //   payment_method: {
      //     // card: CardElement,
      //     // card: elements.getElement('card'),
      //     card: elements.getElement('card'),
      //   },
      // })
      // const firstChildDiv = document.querySelector('#card-element > div:first-child')
      // firstChildDiv.style.padding = '11px'
      // console.log(result)

      // const makePayment = async (e) => {
      //   e?.preventDefault && e.preventDefault()
      //   const payload = await stripe.confirmCardPayment(clientSecret, {
      //     payment_method: {
      //       card: elements.getElement(CardElement),
      //     },
      //   })
      //   console(payload)
      // }
      if (!clientSecret) {
        return notify({
          title: 'Error',
          msg: 'Please Try Again !!!',
          type: 'error',
        })
      }

      setShowModal(
        toggleModal({
          setShowModal,
          modalTitle: 'Payment',
          modalVisible: true,
          modalType: 'filterTripByDateModal',
          modalId: 'paymentModal',
          // noHeader: true,
          modalBody: (
            <>
              <ul className="p-2 w-fit minw303 max-vw-98">
                <li className="d-flex my-4 w-100">
                  <Elements stripe={stripePromise} options={options}>
                    {/* <form id="payment-form" onSubmit={makePayment}> */}
                    {/* <CardElement id="card-element" /> */}
                    {/* <CardElement id="card-element" onChange={handleChange} /> */}
                    {/* <button id="submit-payment" className="jelly-btn"> */}
                    {/* Pay Now */}
                    {/* </button> */}
                    {/* </form> */}
                    <CheckoutForm
                      price={price}
                      clientSecret={clientSecret}
                      closeModal={() => setShowModal(toggleModal({ closeModal: true }))}
                      setBookingCreated={setBookingCreated}
                    />
                  </Elements>
                </li>
                {/* <li className="d-flex my-3">
                  <a
                    className="width222 btn rounded shadow grad-info-white py-3 fw-bold"
                    // target="_blank"
                    href={links.paypalLink}
                    rel="noreferrer"
                  >
                    Paypal
                  </a>
                </li> */}
                <li>
                  <p className="text-start fs-13 p-2">
                    <CIcon icon={cilInfo} /> We will make a temporary charge to your credid card.
                    Once your trip is approved, we will process your payment.
                  </p>
                </li>
              </ul>
            </>
          ),
        }),
      )
      // setShowModal(
      //   toggleModal({
      //     setShowModal,
      //     modalTitle: 'Payment',
      //     modalVisible: true,
      //     modalType: 'filterTripByDateModal',
      //     modalId: 'paymentModal',
      //     // noHeader: true,
      //     modalBody: (
      //       <>
      //         <ul className="p-2 mw303">
      //           <li className="d-flex my-4 justify-content-center">
      //             <a
      //               className="width222 btn rounded shadow grad-success-white py-4 fw-bold mx-auto"
      //               // target="_blank"
      //               href={links.stripeLink}
      //               rel="noreferrer"
      //             >
      //               Pay with Credit Card
      //             </a>
      //           </li>
      //           {/* <li className="d-flex my-3">
      //             <a
      //               className="width222 btn rounded shadow grad-info-white py-3 fw-bold"
      //               // target="_blank"
      //               href={links.paypalLink}
      //               rel="noreferrer"
      //             >
      //               Paypal
      //             </a>
      //           </li> */}
      //           <li>
      //             <p className="text-start fs-13">
      //               <CIcon icon={cilInfo} /> We will make a temporary charge to your credid card. Once
      //               your trip is approved, we will process your payment.
      //             </p>
      //           </li>
      //         </ul>
      //       </>
      //     ),
      //   }),
      // )
    } catch (err) {
      handleErr({ err, userInfo })
    }
  }

  // const openRequestModal = ({
  //   price,
  //   distance,
  //   duration,
  //   coords,
  //   addresses,
  //   tripMode,
  //   tripType,
  //   riderCount,
  //   vehicleTypeState,
  //   dateTime,
  // }) => {
  //   toast.dismiss()
  //   setShowModal(
  //     toggleModal({
  //       setShowModal,
  //       modalTitle: 'Personal Information',
  //       modalVisible: true,
  //       modalType: 'filterTripByDateModal',
  //       modalId: 'requestModal',
  //       // noHeader: true,
  //       modalBody: (
  //         <>
  //           <ul className="list-group p-2 mw303">
  //             <li className="d-flex my-3">
  //               <input
  //                 name="firstName"
  //                 placeholder="First Name"
  //                 autoComplete="given-name"
  //                 className="text-capitalize simple-field w-100"
  //               />
  //             </li>
  //             <li className="d-flex my-3">
  //               <input
  //                 name="lastName"
  //                 placeholder="Last Name"
  //                 autoComplete="family-name"
  //                 className="text-capitalize simple-field w-100"
  //               />
  //             </li>
  //             <li className="d-flex my-3">
  //               <input
  //                 name="cellPhone"
  //                 placeholder="Phone"
  //                 autoComplete="on"
  //                 className="simple-field w-100"
  //                 // value={formatPhone(document.getElementsByName('email')[0]?.value?.trim())}
  //                 onChange={(e) => {
  //                   document.getElementsByName('cellPhone')[0].value = formatPhone(
  //                     e.target.value?.trim(),
  //                   )
  //                 }}
  //               />
  //             </li>
  //             <li className="d-flex my-3">
  //               <input
  //                 name="email"
  //                 placeholder="Email"
  //                 autoComplete="on"
  //                 className="simple-field w-100"
  //               />
  //             </li>
  //             <li className="d-flex my-3">
  //               <input
  //                 name="emailVerify"
  //                 placeholder="verify Email"
  //                 autoComplete="off"
  //                 className="simple-field w-100"
  //               />
  //             </li>

  //             {/* <hr /> */}
  //             <li className="w-100">
  //               <button
  //                 className="jelly-btn grad-success-white"
  //                 onClick={(e) => {
  //                   e.preventDefault()
  //                   const firstName = Capitalize(document.getElementsByName('firstName')[0]?.value)
  //                   const lastName = Capitalize(document.getElementsByName('lastName')[0]?.value)
  //                   const email = document.getElementsByName('email')[0]?.value?.trim()
  //                   const emailVerify = document.getElementsByName('emailVerify')[0]?.value?.trim()
  //                   const cellPhone = document.getElementsByName('cellPhone')[0]?.value?.trim()

  //                   if (!firstName || firstName < 3)
  //                     return toast.error(`First Name is missing or too Short`)
  //                   if (!lastName || lastName < 2)
  //                     return toast.error(`Last Name is missing or too Short`)
  //                   if (!cellPhone) return toast.error(`Phone is missing`)
  //                   if (!validatePhone(cellPhone)) return toast.error(`Phone is invalid`)
  //                   if (!email) return toast.error(`Email is missing`)
  //                   if (!validateEmail(email)) return toast.error(`Email format is invalid`)
  //                   if (!emailVerify) return toast.error(`Please Enter Verify Email`)
  //                   if (email.toLowerCase() !== emailVerify.toLowerCase())
  //                     return toast.error(`Email mismatch`)

  //                   const formData = {
  //                     firstName,
  //                     lastName,
  //                     email: email.trim()?.toLowerCase(),
  //                     cellPhone,
  //                     price,
  //                     distance,
  //                     duration,
  //                     coords,
  //                     addresses,
  //                     tripMode,
  //                     tripType,
  //                     riderCount,
  //                     vehicleType: vehicleTypeState,
  //                     dateTime,
  //                   }
  //                   dispatch(
  //                     sendBookingReq({
  //                       cId: companyData?._id,
  //                       isPublic: true,
  //                       isBooking: true,
  //                       successMsg: `${Capitalize(tripType)} Request Sent! Please Wait`,
  //                       formData,
  //                       to: `contact@xperdriver.com`,
  //                       cb: (data) => {
  //                         // setLinks(data)
  //                         if (data.stripeLink && data.paypalLink) {
  //                           setShowModal(toggleModal({ closeModal: true }))

  //                           setTimeout(() => {
  //                             openPaymentModal({ links: data })
  //                           }, 707)
  //                         } else {
  //                           toast.warn('Please Try Again !!!')
  //                         }
  //                       },
  //                     }),
  //                   )
  //                   // dispatch(
  //                   //   sendEmailAction({
  //                   //     cId: companyData?._id,
  //                   //     isPublic: true,
  //                   //     isBooking: true,
  //                   //     successMsg: `${Capitalize(tripType)} Request Sent!`,
  //                   //     formData,
  //                   //     to: `contact@xperdriver.com`,
  //                   //     subject: `New ${Capitalize(tripType)} Request`,
  //                   //     bodyContent: `
  //                   //      ${firstName} ${lastName},
  //                   //      email: ${email},
  //                   //      Phone: ${cellPhone},
  //                   //      Price: ${price},
  //                   //      vehicle: ${vehicleTypeState},
  //                   //      Passengers: ${riderCount},
  //                   //      ${tripMode},
  //                   //      distance: ${distance}mi,
  //                   //      Duration: ${duration}min,
  //                   //      Pickup Time: ${dateTime?.expectedPickupTime1},
  //                   //      Return Time: ${dateTime?.expectedPickupTime2},
  //                   //      `,
  //                   //     // fileType: 'pdf',
  //                   //     // fileBase64: `${fileBase64?.split('base64,')[1]}`,
  //                   //     // isInvoice: true,
  //                   //     // fileName: `${opt.filename}.pdf`,
  //                   //     cb: (data) => {
  //                   //       // setShowModal(toggleModal({ closeModal: true }))
  //                   //     },
  //                   //   }),
  //                   // )
  //                 }}
  //               >
  //                 <CIcon icon={cilBullhorn} /> Request
  //               </button>
  //               {/* <button
  //                 className="jelly-btn grad-danger-white"
  //                 onClick={(e) => {
  //                   e.preventDefault()
  //                   setShowModal(toggleModal({ closeModal: true }))
  //                 }}
  //               >
  //                 <CIcon icon={cilXCircle} /> Cancel
  //               </button> */}
  //             </li>
  //           </ul>
  //         </>
  //       ),
  //     }),
  //   )
  // }

  const removeEl = ({ e, i }) => {
    e.preventDefault()
    if (addresses[i]) {
      const addressesCopy = [...addresses]
      addressesCopy.splice(i, 1)
      const addressesDescsCopy = [...addressesDescriptions]
      addressesDescsCopy.splice(i, 1)
      setAddressesDescriptions(addressesDescsCopy)
    }

    if (coords[i]) {
      const coordsCopy = [...coords]
      coordsCopy.splice(i, 1)
      setCoords(coordsCopy)
    }

    if (coordsCount > 1) setCoordsCount((prev) => prev - 1)
  }

  const [otpModalOpen, setOtpModalOpen] = useState(false)
  const emailExist = async (email, sendVerification) => {
    try {
      const { data } = await axios.post(`${BASE_URL}/users/userExist`, {
        email,
        sendVerification,
      })
      setOtpModalOpen(true)
      if (data.user) {
        // Auto-fill form fields if user exists
        document.getElementsByName('firstName')[0].value = data.user.firstName || ''
        document.getElementsByName('lastName')[0].value = data.user.lastName || ''
        document.getElementsByName('cellPhone')[0].value = data.user.cellPhone || ''
        // document.getElementsByName('emailVerify')[0].value = data.user.email || ''
        setCustomerExist(true)
        toast.success('User found! Details filled automatically.')
      } else {
        toast.warn('Email not found. Please enter again to confirm.')
      }
    } catch (error) {
      console.error('Error checking email:', error)
      toast.error('Something went wrong. Try again later.')
    }
  }

  return (
    <>
      {' '}
      {!otpModalOpen ? null : (
        <OtpModal
          otpModalOpen={true}
          setOtpModalOpen={setOtpModalOpen}
          userData={{
            email: document.getElementsByName('email')[0]?.value?.trim(),
          }}
          guest={true}
        />
      )}{' '}
      <Elements stripe={stripePromise} options={options}>
        {showModal}
        <div className="d-flex flex-column align-content-center align-items-center w-100 min-vh-100 p-1 grad-info">
          <LogoBig src={companyData?.logoSrc || ''} name={companyData?.name || 'XPERDRIVER'} />
          <CCard className="d-flex align-items-center m-1 w-fit minw303 max-vw-98 mx-auto">
            {/* <CCardHeader>
        <h3>Distance & Price Calculator</h3>
      </CCardHeader> */}
            <CCardBody className="w-100 p-1">
              {showMap}
              {duration > 0 && (
                <div className="w-100 d-flex justify-content-center my-3">
                  <div className="w-fit d-flex align-items-center fw-bold">
                    <CIcon size="lg" icon={cilLocationPin} className="mx-auto" />
                    {Number(distance).toFixed(2)}mi
                  </div>
                  <div className="w-fit d-flex align-items-center ms-2 fw-bold">
                    <CIcon size="lg" icon={cilClock} className="mx-1" />
                    {secondsToTime(duration)} min
                  </div>
                </div>
              )}
              <div className="addresses-box">
                <List
                  container={document.querySelector('.addresses-box')}
                  transitionDuration={0} //ms 300 default
                  lockVertically={true}
                  values={[...Array(coordsCount).keys()].map((x, i, arr) => {
                    return (
                      <div key={i} className={`w-100`}>
                        {i === 0 && showMyLocationButton && !bookingCreated && (
                          <button
                            className="bg-black m-1 p-2 text-white fw-bold"
                            onClick={(e) => {
                              e.preventDefault()
                              getClientCoords()
                              setShowMyLocationButton(false)
                            }}
                          >
                            <CIcon icon={cilLocationPin} /> Set my Location
                          </button>
                        )}
                        {/* {coords?.length > 1 ? (
                        i === 0 ? (
                          <div className="w-100">
                            <strong
                              className={`${coords?.length > 1 ? 'ms-5' : 'ms-2'} text-secondary`}
                            >
                              <CIcon icon={cilLocationPin} /> Pickup Address
                            </strong>
                          </div>
                        ) : coordsCount === i + 1 ? (
                          <div className="w-100 mt-2">
                            <strong
                              className={`${coords?.length > 1 ? 'ms-5' : 'ms-2'} text-secondary`}
                            >
                              <CIcon icon={cilFlagAlt} /> Dropoff Address
                            </strong>
                          </div>
                        ) : (
                          <div className="w-100 mt-2">
                            <strong
                              className={`${coords?.length > 1 ? 'ms-5' : 'ms-2'} text-secondary`}
                            >
                              <CIcon icon={cilFlagAlt} /> Stop {i}
                            </strong>
                          </div>
                        )
                      ) : null} */}
                        {coords?.length > 1 ? (
                          <input
                            // disabled={true}
                            readOnly
                            className={`w-100 text-start ${
                              coords?.length > 1 ? 'ms-5' : 'ms-2'
                            } mt-2 text-secondary bg-transparent`}
                            value={
                              i === 0 ? 'Pick Up' : coordsCount === i + 1 ? 'Drop Off' : `Stop ${i}`
                            }
                          />
                        ) : null}

                        {addressesDescriptions?.length &&
                        addressesDescriptions[i] &&
                        addressesDescriptions[i].toLowerCase() !== addresses[i]?.toLowerCase() ? (
                          <input
                            // disabled={true}
                            readOnly
                            className={`w-100 text-start ${
                              coords?.length > 1 ? 'ms-5' : 'ms-2'
                            } mt-n2 text-secondary bg-transparent`}
                            value={`${addressesDescriptions[i].split(',')[0]}`}
                          />
                        ) : null}
                        {/* {addressesDescriptions?.length &&
                      addressesDescriptions[i] &&
                      addressesDescriptions[i].toLowerCase() !== addresses[i]?.toLowerCase() ? (
                        <label className="w-100 text-truncate">
                          <strong
                            className={`${coords?.length > 1 ? 'ms-5' : 'ms-2'} text-secondary`}
                          >
                            <CIcon icon={cilMap} /> {addressesDescriptions[i].split(',')[0]}
                          </strong>
                        </label>
                      ) : null} */}

                        <div className={`w-100 d-flex align-items-center`}>
                          {coords?.length > 1 && (
                            <CIcon
                              size="xl"
                              className={`mx-1 bookingAddressDragger ${
                                !coords[i] ? `invisible` : ``
                              }`}
                              icon={cilMenu}
                            />
                          )}
                          <input
                            disabled={bookingCreated}
                            size=""
                            id={`addressBox${i}`}
                            placeholder="Add Destination ..."
                            className="address-box w-555 max-vw-98 p-3 rounded shadow"
                            value={addresses[i] || ''}
                            // onChange={(e) => handleOnChageAddress({ value: e.target.value, i })}
                            onChange={async (e) => {
                              const fullAddress = e.target.value
                              const addressesCopy = [...addresses]
                              addressesCopy[i] = fullAddress
                              setAddresses(addressesCopy)
                              setAddressIdx(i)
                              setIsSearching(true)
                              // console.log(startTypingTime.current)
                              // console.log(isDelay({ lastUpdate: startTypingTime.current, limit: 1 }))

                              // if (
                              //   !startTypingTime.current ||
                              //   (startTypingTime.current &&
                              //     !isDelay({ lastUpdate: startTypingTime.current, limit: 1 }))
                              // ) {
                              //   if (!startTypingTime?.current) startTypingTime.current = new Date()
                              //   return
                              // }
                              // await handleSearch({ fullAddress: addressesCopy[i], i })
                            }}
                            onFocus={(e) => {
                              e.preventDefault()
                              if (
                                locationDifferentFromMyLocation({ coords, myLocationCoords }) &&
                                +e.target.id.match(/\d+/)[0] === 0 &&
                                !addresses[0]?.trim()
                              ) {
                                setShowMyLocationButton(true)
                              }
                            }}
                          />
                          {coordsCount > 2 && (
                            <CIcon
                              role="button"
                              size="xl"
                              className="mx-1"
                              icon={cilXCircle}
                              onClick={(e) => removeEl({ e, i })}
                            />
                          )}
                        </div>
                      </div>
                    )
                  })}
                  // beforeDrag={() => { }}
                  onChange={({ oldIndex, newIndex, targetRect }) => {
                    if (oldIndex !== newIndex) {
                      if (addresses[oldIndex]) {
                        const addressesCopy = [...addresses]
                        addressesCopy.splice(oldIndex, 1)
                        addressesCopy.splice(newIndex, 0, addresses[oldIndex])
                        setAddresses(addressesCopy)
                        const addressesDescsCopy = [...addressesDescriptions]
                        addressesDescsCopy.splice(oldIndex, 1)
                        addressesDescsCopy.splice(newIndex, 0, addressesDescriptions[oldIndex])
                        setAddressesDescriptions(addressesDescsCopy)
                      }

                      if (coords[oldIndex]) {
                        const coordsCopy = [...coords]
                        coordsCopy.splice(oldIndex, 1)
                        coordsCopy.splice(newIndex, 0, coords[oldIndex])
                        setCoords(coordsCopy)
                      }
                    }
                  }}
                  renderList={({ children, props, isDragged }) => (
                    <div
                      style={{ padding: 0, cursor: isDragged ? 'grabbing' : undefined }}
                      {...props}
                    >
                      {children}
                    </div>
                  )}
                  renderItem={({ value, props, isDragged, isSelected, index }) => {
                    return (
                      <div {...props} key={index}>
                        {value}
                      </div>
                    )
                  }}
                />
              </div>

              {isSearching ? (
                <div className="mt-1 w-100 text-center fw-bold text-secondary">
                  <div style={{ width: '50px' }} className="mx-auto my-1">
                    <ThreeDots />
                  </div>
                </div>
              ) : suggestions?.length ? (
                <div className="google-suggestion-box">
                  {suggestions
                    ?.filter((s) => s.description !== addresses[addresses?.length - 2])
                    .map((suggestion, i) => (
                      <div className="google-suggestion" key={i}>
                        <button className="" onClick={(e) => handleSelect({ e, suggestion })}>
                          <CIcon icon={cilSearch} className="me-1" />{' '}
                          <div className="d-flex align-items-center justify-content-start flex-column">
                            <div className="w-100 text-start">
                              {suggestion?.description?.split(',')?.slice(0, 2)?.join(',')}
                            </div>
                            {suggestion?.description
                              ?.split(',')
                              ?.slice(2, suggestion?.description?.split(',')?.length)?.length ? (
                              <div className="w-100 mt-n1 text-start">
                                {suggestion?.description
                                  ?.split(',')
                                  ?.slice(2, suggestion?.description?.split(',')?.length)
                                  ?.join(',')}
                              </div>
                            ) : null}
                          </div>
                        </button>
                      </div>
                    ))}
                </div>
              ) : null}
              {coords.length > 1 && coords[coordsCount - 1] && (
                <button
                  disabled={bookingCreated}
                  className="my-3 fw-bold bg-transparent text-secondary"
                  onClick={async (e) => {
                    e.preventDefault()
                    const cCount = coordsCount + 1
                    setCoordsCount(cCount)
                    await slowDown(707)
                    document.getElementById(`addressBox${cCount - 1}`)?.focus()
                  }}
                >
                  <div className="d-inline-flex">
                    <CIcon role="button" size="xl" icon={cilPlus} className="me-1" />{' '}
                    <span>Add Destination</span>
                  </div>
                </button>
              )}
              {coords.length > 1 ? (
                <>
                  {price ? (
                    <div className="w-100 d-flex justify-content-center mt-3 mb-n4 fw-bold">
                      <div className="w-fit d-flex align-items-center fs-22 text-green">
                        ${price}
                      </div>
                    </div>
                  ) : null}
                  <div className="w-100 d-flex justify-content-center mt-5 mb-3">
                    <div className="w-fit shadow rounded d-flex align-items-center">
                      <div className="ms-2 mx-auto text-center">
                        <CIcon
                          size="lg"
                          icon={riderCount === 1 ? cilUser : cilGroup}
                          className="mx-auto"
                        />
                      </div>
                      <CFormSelect
                        className="ms-1 border-0 text-center fs-22"
                        value={riderCount}
                        onChange={(e) => setRiderCount(Number(e.target.value))}
                      >
                        {[...Array(77).keys()].map((i) => (
                          <option key={i + 1} value={i + 1}>
                            {i + 1}
                          </option>
                        ))}
                      </CFormSelect>
                    </div>

                    <div className="w-fit shadow rounded d-flex align-items-center">
                      <CFormSelect
                        className="ms-0 border-0 text-center"
                        value={vehicleTypeState}
                        onChange={(e) => setVehicleTypeState(e.target.value)}
                      >
                        {vehicleTypes.map((vType) => (
                          <option
                            key={vType}
                            value={vType}
                            // disabled={riderCount > vehicle.maxRiders}
                          >
                            {Capitalize(vType)}
                          </option>
                        ))}
                      </CFormSelect>
                    </div>
                  </div>

                  <div className="w-100 d-flex justify-content-center my-5">
                    <div className="jelly-box gap3">
                      <button
                        className={`jelly-btn ${
                          tripType === 'ride' ? `grad-success` : `grad-purple`
                        }`}
                        onClick={(e) => {
                          e.preventDefault()
                          const toggleTripType = tripType === 'ride' ? 'delivery' : 'ride'
                          setTripType(toggleTripType)
                          if (toggleTripType === 'delivery') {
                            setVehicleTypeState('sedan')
                            setTripMode('oneway')
                          }
                        }}
                      >
                        {/* <CIcon
                        icon={
                          tripType === 'ride'
                            ? vehicleTypeState === 'sedan'
                              ? cilCarAlt
                              : cilBusAlt
                            : cibShopify
                        }
                      />{' '} */}

                        {isRide({ tripType }) ? (
                          <VehicleIcon vehicleType={vehicleTypeState} />
                        ) : (
                          <CIcon icon={cibShopify} />
                        )}
                        {Capitalize(tripType)}
                      </button>
                      {tripType === 'ride' && (
                        <>
                          <button
                            className={`jelly-btn ${tripMode === 'oneway' ? `grad-success` : ``}`}
                            onClick={(e) => {
                              e.preventDefault()
                              setTripMode('oneway')
                            }}
                          >
                            <CIcon icon={cilArrowThickRight} /> One Way
                          </button>
                          <button
                            className={`jelly-btn ${
                              tripMode === 'roundtrip' ? `grad-success` : ``
                            }`}
                            onClick={(e) => {
                              e.preventDefault()
                              setTripMode('roundtrip')
                            }}
                          >
                            <CIcon icon={cilLoopCircular} /> Round-trip
                          </button>
                        </>
                      )}
                    </div>
                  </div>

                  <div className="w-fit d-flex flex-column justify-content-center my-4 mx-auto">
                    <p className="mb-0 fw-bold">
                      <CIcon icon={cilClock} /> PICK UP TIME
                    </p>
                    <div className="p-3 rounded shadow bg-white">
                      <input
                        disabled={bookingCreated}
                        name="expectedPickupTime1"
                        type="datetime-local"
                        min={formatToHTMLinput({})}
                        defaultValue={dateTime?.expectedPickupTime1}
                        // defaultValue={dateTime?.expectedPickupTime1 || formatToHTMLinput({})}
                        // className="p-3 rounded shadow bg-white"
                        onChange={handleDateTimeChange}
                      />
                    </div>
                    {ETA?.eta && (
                      <p className="mt-5 mb-0 fw-bold text-secondary">
                        <CIcon icon={cilClock} /> DROP OFF ETA: {ETA?.eta}
                      </p>
                    )}
                  </div>

                  {tripMode === 'roundtrip' && (
                    <div className="w-fit d-flex flex-column justify-content-center my-5 mx-auto">
                      <p className="mb-0 fw-bold">
                        <CIcon icon={cilClock} /> RETURN TIME
                      </p>
                      <div className="p-3 rounded shadow bg-white">
                        <input
                          disabled={bookingCreated}
                          name="expectedPickupTime2"
                          type="datetime-local"
                          min={dateTime?.expectedPickupTime1 || formatToHTMLinput({})}
                          defaultValue={dateTime?.expectedPickupTime2}
                          // defaultValue={dateTime?.expectedPickupTime2 || formatToHTMLinput({})}

                          onChange={handleDateTimeChange}
                        />
                      </div>
                    </div>
                  )}
                </>
              ) : null}

              {!bookingCreated && price > 0 && distance > 0 && duration && (
                <ul className="list-group p-2 mw303">
                  <li className="d-flex my-3">
                    <input
                      name="firstName"
                      placeholder="First Name"
                      autoComplete="given-name"
                      className="text-capitalize simple-field w-100"
                    />
                  </li>
                  <li className="d-flex my-3">
                    <input
                      name="lastName"
                      placeholder="Last Name"
                      autoComplete="family-name"
                      className="text-capitalize simple-field w-100"
                    />
                  </li>
                  <li className="d-flex my-3">
                    <input
                      name="cellPhone"
                      placeholder="Phone"
                      autoComplete="on"
                      className="simple-field w-100"
                      // value={formatPhone(document.getElementsByName('email')[0]?.value?.trim())}
                      onChange={(e) => {
                        document.getElementsByName('cellPhone')[0].value = formatPhone(
                          e.target.value?.trim(),
                        )
                      }}
                    />
                  </li>
                  <li className="d-flex my-3">
                    <input
                      name="email"
                      placeholder="Email"
                      autoComplete="on"
                      className="simple-field w-100"
                    />
                  </li>
                  <li className="d-flex my-3">
                    <input
                      id="emailVerify"
                      name="emailVerify"
                      placeholder="Verify Email"
                      autoComplete="off"
                      className="simple-field w-100"
                    />
                    {!customerExist && (
                      <button
                        className="grad-info-white shadow-hover"
                        onClick={async (e) => {
                          const email = document.getElementsByName('email')[0]?.value?.trim()
                          const emailVerify = document
                            .getElementsByName('emailVerify')[0]
                            ?.value?.trim()
                          if (!email || !emailVerify) {
                            return toast.error('Emails are missing!')
                          }
                          if (email.toLowerCase() !== emailVerify.toLowerCase()) {
                            return toast.error('Emails do not match!')
                          }
                          let sendVerification = false
                          emailExist(email, sendVerification)
                        }}
                      >
                        Verify
                      </button>
                    )}
                  </li>

                  {/* <hr /> */}
                  {!bookingCreated && (
                    <li className="w-100 my-3">
                      <button
                        className="jelly-btn grad-success-white"
                        onClick={(e) => {
                          e.preventDefault()
                          const firstName = Capitalize(
                            document.getElementsByName('firstName')[0]?.value,
                          )
                          const lastName = Capitalize(
                            document.getElementsByName('lastName')[0]?.value,
                          )
                          const email = document.getElementsByName('email')[0]?.value?.trim()
                          const emailVerify = document
                            .getElementsByName('emailVerify')[0]
                            ?.value?.trim()
                          const cellPhone = document
                            .getElementsByName('cellPhone')[0]
                            ?.value?.trim()

                          if (!firstName || firstName < 3) {
                            return toast.error(`First Name is missing or too Short`)
                          }
                          if (!lastName || lastName < 2) {
                            return toast.error(`Last Name is missing or too Short`)
                          }
                          if (!cellPhone) return toast.error(`Phone is missing`)
                          if (!validatePhone(cellPhone)) return toast.error(`Phone is invalid`)
                          if (!email) return toast.error(`Email is missing`)
                          if (!validateEmail(email)) return toast.error(`Email format is invalid`)
                          if (!emailVerify) return toast.error(`Please Enter Verify Email`)
                          if (email.toLowerCase() !== emailVerify.toLowerCase()) {
                            return toast.error(`Email mismatch`)
                          }

                          const formData = {
                            firstName,
                            lastName,
                            email: email.trim()?.toLowerCase(),
                            cellPhone,
                            price,
                            distance,
                            duration,
                            coords,
                            addresses,
                            tripMode,
                            tripType,
                            riderCount,
                            vehicleType: vehicleTypeState,
                            dateTime,
                          }
                          dispatch(
                            sendBookingReq({
                              cId: companyData?._id,
                              isPublic: true,
                              isBooking: true,
                              successMsg: `${Capitalize(tripType)} Request Sent! Please Wait`,
                              formData,
                              to: `contact@xperdriver.com`,
                              cb: (data) => {
                                // setLinks(data)
                                if (data.stripeLink && data.paypalLink) {
                                  setShowModal(toggleModal({ closeModal: true }))

                                  setTimeout(() => {
                                    openPaymentModal({ links: data })
                                  }, 707)
                                } else {
                                  toast.warn('Please Try Again !!!')
                                }
                              },
                            }),
                          )
                          // dispatch(
                          //   sendEmailAction({
                          //     cId: companyData?._id,
                          //     isPublic: true,
                          //     isBooking: true,
                          //     successMsg: `${Capitalize(tripType)} Request Sent!`,
                          //     formData,
                          //     to: `contact@xperdriver.com`,
                          //     subject: `New ${Capitalize(tripType)} Request`,
                          //     bodyContent: `
                          //      ${firstName} ${lastName},
                          //      email: ${email},
                          //      Phone: ${cellPhone},
                          //      Price: ${price},
                          //      vehicle: ${vehicleTypeState},
                          //      Passengers: ${riderCount},
                          //      ${tripMode},
                          //      distance: ${distance}mi,
                          //      Duration: ${duration}min,
                          //      Pickup Time: ${dateTime?.expectedPickupTime1},
                          //      Return Time: ${dateTime?.expectedPickupTime2},
                          //      `,
                          //     // fileType: 'pdf',
                          //     // fileBase64: `${fileBase64?.split('base64,')[1]}`,
                          //     // isInvoice: true,
                          //     // fileName: `${opt.filename}.pdf`,
                          //     cb: (data) => {
                          //       // setShowModal(toggleModal({ closeModal: true }))
                          //     },
                          //   }),
                          // )
                        }}
                      >
                        <CIcon icon={cilBullhorn} /> Request
                      </button>

                      {/* <button
                  className="jelly-btn grad-danger-white"
                  onClick={(e) => {
                    e.preventDefault()
                    setShowModal(toggleModal({ closeModal: true }))
                  }}
                >
                  <CIcon icon={cilXCircle} /> Cancel
                </button> */}
                    </li>
                  )}
                </ul>
              )}

              {
                isCalculating ? (
                  <strong className="p-1 fw-bold text-secondary">Please Wait...</strong>
                ) : bookingCreated ? (
                  <button
                    className="py-2 w-100 rounded shadow-hover bg-success text-white"
                    onClick={(e) => {
                      e.preventDefault()
                      toast.dismiss()
                      window.scrollTo(0, 0)
                      setBookingCreated(false)
                      setPrice(0)
                      setDistance(0)
                      setDuration(0)
                      setCoords([])
                      setAddresses([])
                      setAddressesDescriptions([])
                      setCoordsCount(1)
                      setShowModal(toggleModal({ closeModal: true }))
                    }}
                  >
                    Book Again
                  </button>
                ) : null
                // : price > 0 && distance > 0 && duration ? (
                //   <button
                //     className="py-2 w-100 rounded shadow-hover bg-success text-white"
                //     onClick={(e) => {
                //       e.preventDefault()
                //       openRequestModal({
                //         price,
                //         distance,
                //         duration: secondsToTime(duration),
                //         coords,
                //         addresses,
                //         tripMode,
                //         tripType,
                //         riderCount,
                //         vehicleTypeState,
                //         dateTime,
                //       })
                //     }}
                //   >
                //     Done <div className="fs-22 fw-bold">${price}</div>
                //   </button>
                // ) : null
              }
              {/* {links.stripeLink && links.paypalLink && (
              <div className="w-fit d-flex flex-column justify-content-center my-5 mx-auto">
                Either pay with
                <a className="btn" target="_blank" href={links.stripeLink} rel="noreferrer">
                  {' '}
                  Stripe
                </a>{' '}
                or
                <a className="btn" target="_blank" href={links.paypalLink} rel="noreferrer">
                  {' '}
                  Paypal
                </a>
              </div>
            )} */}
            </CCardBody>
          </CCard>
        </div>
      </Elements>
    </>
  )
}

Booking.propTypes = {
  companyData: PropTypes.any,
  vehicleType: PropTypes.any,
  brokerPricing: PropTypes.any,
}

export default Booking
