import { cilThumbDown, cilThumbUp } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import React from 'react'
import { toast } from 'react-toastify'

const notify = ({
  msg,
  confirm,
  isBooking,
  confirmText,
  confirmOnly,
  reject,
  type = 'success', // info, error, warning
  toastId = 'newNotification',
  autoClose = false,
  theme = 'colored',
  position = toast.POSITION.TOP_CENTER,
  withSound = 'yes',
  fail = false,
  newTrip = false,
  isNewNote = false,
  toggleSound,
}) => {
  const content = (
    <div>
      <div className={confirm ? 'my-4' : ''}>{msg}</div>
      {confirm ? (
        <div className="jelly-box my-2 gap3">
          <button
            className="jelly-btn grad-success-white"
            onClick={(e) => {
              e.preventDefault()
              // setFormState({ ...formStateRef.current, isFacility: !formStateRef?.current?.isFacility })
              confirm()
              toast.dismiss()
            }}
          >
            <CIcon icon={cilThumbUp} />
            {confirmText || 'YES'}
          </button>
          {!confirmOnly && (
            <button
              className="jelly-btn grad-danger-white"
              onClick={(e) => {
                e.preventDefault()
                reject && reject()
                toast.dismiss()
              }}
            >
              <CIcon icon={cilThumbDown} />
              NO
            </button>
          )}
        </div>
      ) : null}
    </div>
  )
  if (!toast.isActive(toastId)) {
    toast[type](content, {
      toastId,
      theme,
      position,
      autoClose,
      draggablePercent: 60,
      draggableDirection: 'y',
    })
  } else if (!confirm) {
    toast.update(toastId, { type, render: msg })
  }
  if (withSound === 'yes' && toggleSound) {
    toggleSound({ fail, bell: newTrip, cashIn: isBooking, isNewNote })
  }
}

export default notify
