import { UPDATE_TRIP_SUCCESS } from 'src/constants'
import { tripInfo } from './get-trip-info'
import handleErr from './handle-error'
import {
  getRemainingItems,
  getTripsPendingByDriver,
  samePkOrDp,
  uniqueById,
  uniqueByKey,
  validCoords,
} from './helpers'
import isEmpty from './is-empty'
import notify from './notify'

const pendingTraj = (tr) => {
  try {
    return !tr?.isArrive && !tr?.isDropoff
    // return (tr?.isStart && tr?.isArrive) || (tr?.isPickup && tr?.isDropoff) ? false : true // same as above
  } catch (err) {
    handleErr({ err })
    return false
  }
}

const tripTrajectoryPickedUp = ({ trip, trajectoryId, isStart, isPickup, isArrive, isDropoff }) => {
  try {
    const { trajectory = [] } = trip
    // if (!isArrive) return trajectory
    const trajIdx = trajectory.findIndex((tr) => tr.trajectoryId === trajectoryId)
    if (trajIdx >= 0) {
      trajectory[trajIdx].isStart = isStart || trajectory[trajIdx].isStart
      trajectory[trajIdx].isPickup = isPickup || trajectory[trajIdx].isPickup
      trajectory[trajIdx].isArrive = isArrive || trajectory[trajIdx].isArrive
      trajectory[trajIdx].isDropoff = isDropoff || trajectory[trajIdx].isDropoff
    }

    return trajectory
  } catch (err) {
    handleErr({ err })
    return trip?.trajectory || []
  }
}

const sortTrajArr = ({ arr, leg, pendingOnly }) => {
  try {
    // console.log({ arr })
    return (
      arr
        ?.filter((tr) => (leg ? leg === tr.stage : true))
        ?.filter((tr) => (pendingOnly ? pendingTraj(tr) : true))
        // ?.filter((tr) => !tr?.isArrive && !tr?.isDropoff)
        .sort((a, b) => {
          // console.log('a.queueIdx: ', a.queueIdx)
          // console.log('b.queueIdx: ', b.queueIdx)
          return a?.queueIdx - b?.queueIdx
        })
    )
  } catch (err) {
    handleErr({ err })
    return arr || []
  }
}

const sortByTrajectoryQueueIdx = ({ a, b, leg, trajectoriesState }) => {
  try {
    if (trajectoriesState && Array.isArray(trajectoriesState)) {
      return sortTrajArr({
        arr: trajectoriesState?.length ? trajectoriesState : [],
        //  leg
      })
    }
    if (trajectoriesState && Object.keys(trajectoriesState)?.length) {
      // const sortedTrajState = {}
      // console.log('trajectoriesState: ', trajectoriesState)
      const sortedTrajState = Object.keys(trajectoriesState).map((driverId) => ({
        ...trajectoriesState,
        [driverId]: trajectoriesState[driverId]?.length
          ? sortTrajArr({
              arr: trajectoriesState[driverId],
              // leg
            })
          : [],
      }))
      return sortedTrajState
    }

    const tripA = a?.t1Info || tripInfo({ trip: a })
    const tripB = b?.t1Info || tripInfo({ trip: b })

    const { trajectory: trajectoryA } = tripA
    const { trajectory: trajectoryB } = tripB

    // console.log('leg: ', leg)
    // console.log('tripA: ', tripA)
    // console.log('tripB: ', tripB)
    // console.log('trajectoryA: ', trajectoryA)
    // console.log('trajectoryB: ', trajectoryB)

    if (!trajectoryA?.length && !trajectoryB?.length) return 0
    if (!trajectoryA?.length) return 1
    if (!trajectoryB?.length) return -1

    // const stageA = getTripTrajStages({ trip: a, leg })[0] //|| tripA?.timeIdx || 1
    // const stageB = getTripTrajStages({ trip: b, leg })[0] //|| tripB?.timeIdx || 1

    // console.log('stageA: ', stageA)
    // console.log('stageB: ', stageB)

    const trajQueueIdxA = trajectoryA
      ?.filter((t) => (leg ? leg === t.stage : true))
      ?.filter((tr) => pendingTraj(tr))
      // ?.filter((tr) => !tr?.isArrive && !tr?.isDropoff)
      ?.sort((a, b) => a.queueIdx - b.queueIdx)[0]?.queueIdx
    // ?.find((traj) => traj?.stage === (leg || stageA))?.queueIdx // find the first
    const trajQueueIdxB = trajectoryB
      ?.filter((t) => (leg ? leg === t.stage : true))
      ?.filter((tr) => pendingTraj(tr))
      // ?.filter((tr) => !tr?.isArrive && !tr?.isDropoff)
      ?.sort((a, b) => a.queueIdx - b.queueIdx)[0]?.queueIdx
    // ?.find((traj) => traj?.stage === (leg || stageB))?.queueIdx // find the first
    // console.log('trajQueueIdxA: ', trajQueueIdxA)
    // console.log(
    //   'a: ',
    //   a.trajectory?.filter((tr) => pendingTraj(tr)),
    // )
    // console.log('trajQueueIdxB: ', trajQueueIdxB)
    // console.log(
    //   'b: ',
    //   b.trajectory?.filter((tr) => pendingTraj(tr)),
    // )

    // if (!trajQueueIdxA && !trajQueueIdxB) return 0
    // if (!trajQueueIdxA && trajQueueIdxB) return 1
    // if (!trajQueueIdxB && trajQueueIdxA) return -1

    const sortedTraj =
      trajQueueIdxA > 0 && trajQueueIdxB > 0 ? (trajQueueIdxA < trajQueueIdxB ? -1 : 1) : 0
    // console.log('sortedTraj: ', sortedTraj)
    return sortedTraj
  } catch (err) {
    handleErr({ err })
    return 0
  }
}

// similar to timeIdx or leg but iqualized to all trips
const getTripTrajStages = ({ trip, leg }) => {
  try {
    const { trajectory, timeIdx } = tripInfo({ trip }) || {}
    // const tripMaxTrajStage = Math.ceil(trajectory?.length / 2)
    // console.log({ trajectory })
    // console.log(tripTrajStages)
    if (leg) return [leg]
    if (!trajectory?.length) return [timeIdx || 1]
    const filteredTraj = trajectory
      ?.filter((tr) => pendingTraj(tr))
      ?.filter((t) => (t?.stage < timeIdx ? false : true))
      ?.map((traj) => traj?.stage)
      ?.sort((a, b) => a - b)
    return !filteredTraj?.length ? [timeIdx || 1] : filteredTraj
  } catch (err) {
    handleErr({ err })
    return [1]
  }
}

const getTrajCurrentStage = ({ trip, leg, trips }) => {
  try {
    // const { timeIdx: tripTimeIdx } = tripInfo({ trip })
    //: tripsDataRef.current
    if (leg) return leg
    // console.log(leg)
    // const selectedLeg = leg || 1 // getTripTrajStage({ trip })
    const driver = trip?.driver
    const smallerStage =
      getTripsPendingByDriver({ trips, driver })
        ?.map((trip) => getTripTrajStages({ trip }))
        ?.flat()
        ?.sort((a, b) => a - b)[0] || 1
    return smallerStage
  } catch (err) {
    handleErr({ err })
    return 1
  }
}

const findTripTrajectory = ({ trip, row, column, destId, leg, trips }) => {
  try {
    const stage = getTripTrajStages({ trip, leg })[0]
    // const stage = getTrajCurrentStage({ trip, leg, trips })
    // console.log('stage: ', stage, 'row: ', row, 'column ', column, 'destId ', destId)
    // console.log('stage: ', stage)
    // console.log('trip?._id: ', trip?._id)
    // console.log('trip?.customer: ', trip?.customer)
    // console.log('destId: ', destId)
    // console.log('trip?.trajectory: ', trip?.trajectory)
    return trip?.trajectory?.find
      ? trip?.trajectory?.find(
          (x) =>
            x?.stage === stage &&
            x?.tripId === trip?._id &&
            x?.customer === trip?.customer &&
            x?.destId === destId,
        )
      : null
    // return trip?.trajectory?.find(
    //   (x) => x?.stage === stage && x?.row === row && x?.column === column && x?.destId === destId,
    // )
    // return trip?.trajectory?.find((x) => x?.trajectoryId === `${stage}-${trip?._id}-${destId}`)
  } catch (err) {
    handleErr({ err })
    return ''
  }
}

const addGetTrajectories = ({ trip, trips, trajectoriesState, cb }) => {
  try {
    if (!trip && trips?.length) {
      const uniqueTripsDrivers = uniqueByKey({ arr: trips, key: 'driver' })
      const uniqueDrivers = uniqueTripsDrivers.map((t) => t.driver)
      const updatedTraj = {}
      // console.log('uniqueDrivers: ', uniqueDrivers)
      uniqueDrivers.forEach((driver) => {
        // console.log('driver: ', driver)
        const driverId = driver || 'nodriver'
        const tripsTraj = sortTrajArr({
          arr: getTripsPendingByDriver({ trips, driver })
            ?.map((t) => t?.trajectory)
            ?.flat(1),
        })
        if (tripsTraj?.length) {
          updatedTraj[driverId] = tripsTraj
        }
      })
      cb && cb({ updatedTraj })
      return updatedTraj
    }
    const driver = trip?.driver || 'nodriver'
    if (
      (isEmpty(trajectoriesState) || !trajectoriesState[driver]?.length) &&
      trip?.trajectory?.length
    ) {
      // console.log('trajectoriesState: ', trajectoriesState)
      const updatedTraj = {
        ...(trajectoriesState || {}),
        [driver]: sortTrajArr({
          arr: getTripsPendingByDriver({ trips, driver: trip?.driver || null })
            ?.map((t) => t?.trajectory)
            ?.flat(1),
        }),
      }
      // console.log('updatedTraj: ', updatedTraj)
      cb && cb({ updatedTraj })
      return updatedTraj
    }
    // console.log('trajectoriesState: ', trajectoriesState)
    cb && cb({ updatedTraj: trajectoriesState })
    return trajectoriesState
  } catch (err) {
    handleErr({ err })
    cb && cb({ updatedTraj: trajectoriesState })
    return trajectoriesState
  }
}

const getTrajectoryLastQueueIdx = ({ trip, trips, leg, trajectoriesState }) => {
  try {
    const foundtrajectories = addGetTrajectories({ trip, trips, trajectoriesState })
    // const { timeIdx: tripTimeIdx } = tripInfo({ trip })
    // const timeIdx = leg || tripTimeIdx || 1
    // const currentStage = getTrajCurrentStage({ trip, trips, leg })

    const driver = trip?.driver || 'nodriver'

    const lastQueueIdx =
      foundtrajectories[driver]
        // ?.filter((x) => +x?.stage === +currentStage)
        ?.map((x) => x?.queueIdx)
        ?.sort((a, b) => b - a)[0] || 1
    // console.log({ lastQueueIdx })
    return lastQueueIdx
  } catch (err) {
    handleErr({ err })
  }
}

const getTripsTrajectories = ({ trips, driverId, leg, noArrived, noDroppedOff }) => {
  const allTripsByDriver = trips.filter((t) => t.driver === driverId) //.slice(0, 50)
  const tTrajByLeg = allTripsByDriver
    .map(
      (t) =>
        // t?.trajectory.filter(
        //   (tr) =>
        //     tr?.stage === (leg || getTrajCurrentStage({ trip: t, leg, trips: allTripsByDriver })),
        // ),
        t?.trajectory, //.filter((tr) => tr?.stage === (leg || getTripTrajStages({ trip: t, leg })[0])),
    )
    ?.flat()
    ?.filter((tr) => (noArrived ? !tr?.isArrive : true))
    ?.filter((tr) => (noDroppedOff ? !tr?.isDropoff : true))
  // .sort((a, b) => a.queueIdx - b.queueIdx)
  // console.log({ tTrajByLeg })

  return sortTrajArr({
    arr: tTrajByLeg,
    // leg
  })
}

const insertTripInTrajectory = ({ trip, trips, leg, tripsDest, trajectoriesState, cb }) => {
  try {
    const driverId = trip?.driver || 'nodriver'
    const { _id, trajectory, destData = [] } = tripInfo({ trip, tripsDest })
    const tripStage = getTripTrajStages({ trip, leg })[0]
    const tripTrajByStage = trajectory.filter((t) => t.stage === tripStage)
    const tripsTrajectories = getTripsTrajectories({ trips, driverId })
    const tripIdx = trips.findIndex((t) => t._id === trip._id)
    const tripBefore = trips[tripIdx - 1]
    const { trajectory: tripBeforeTraj } = tripBefore || trip
    const tripBeforeStage = getTripTrajStages({ trip: tripBefore })[0]
    const tripBeforeTrajByStage = tripBeforeTraj?.filter((tr) => tr.stage === tripBeforeStage)
    const tripBeforeTrajLastQueueIdx =
      tripBeforeTrajByStage?.map((tr) => tr.queueIdx)?.sort((a, b) => b - a)[0] || 0

    const updateTripTraj = [...(trajectory || [])]
    const queueIdxAdded = []

    const loopStart = (tripStage || 1) >= 0 ? tripStage || 1 : 0
    const loopEnd = tripStage > 0 ? tripStage : 1
    for (let i = loopStart; i < loopEnd; i++) {
      const destId = trip?.destinations[i]?.id
      if (destId) {
        const trajectoryId = `${tripStage}-${_id}-${destId}`
        const nextQueueIdx = tripBeforeTrajLastQueueIdx + 1 + i
        queueIdxAdded.push(nextQueueIdx)
        const foundTraj =
          findTripTrajectory({
            trip,
            destId,
            leg: leg || tripStage,
          }) || {}
        if (isEmpty(foundTraj)) {
          foundTraj.name = destData[i]?.name
          foundTraj.tripType = trip?.tripType
          foundTraj.coordType = destData[i]?.level ? 'Customer' : 'Destination'
          foundTraj.tripId = _id
          foundTraj.customer = trip?.customer
          foundTraj.trajectoryId = trajectoryId
          foundTraj.queueIdx = nextQueueIdx
          foundTraj.stage = tripStage
          foundTraj.timeIdx = tripStage
          foundTraj.column = i < tripStage ? 1 : 2
          foundTraj.destId = destId
          foundTraj.location = destData[i]?.location
          updateTripTraj.push(foundTraj)
        }
        const foundIdx = updateTripTraj.findIndex((x) => x?.trajectoryId === trajectoryId)
        if (foundIdx) updateTripTraj[foundIdx].queueIdx = nextQueueIdx
      }
    }
    trip.trajectory = updateTripTraj

    const lastQueueIdxAdded = queueIdxAdded.sort((a, b) => b - a)[0]
    const updatedTripsTraj = trips
      ?.filter((t) => t.driver === driverId)
      .map((t, i) => {
        if (i >= tripIdx) {
          t.trajectory = t?.trajectory?.map((tr) => {
            if (tr.queueIdx <= lastQueueIdxAdded) {
              tr.queueIdx = lastQueueIdxAdded + 1 + i
              return tr
            }
            return tr
          })
          return t
        }
        return t
      })

    const updatedTraj = {
      ...(trajectoriesState || {}),
      [driverId]: sortTrajArr({
        arr: [...updatedTripsTraj, trip]?.map((t) => t?.trajectory)?.flat(1),
        // leg,
      }),
    }

    cb && cb({ updatedTraj, updatedTripsTraj })
    return { updatedTraj, updatedTripsTraj }
  } catch (err) {
    handleErr({ err })
    return { updatedTraj: trajectoriesState, updatedTripsTraj: trips }
  }
}

const createTrajectory = ({
  trip,
  trips,
  pk,
  dp,
  row,
  leg,
  trajectoriesState,
  setTrajectoriesState,
  dispatch,
  routeModeRef,
  tripsDest,
  constraint,
  updateTrip,
}) => {
  try {
    const confirm = () => {
      // console.log({ trip, pk, dp, row })
      // globalQ?.current?.trajectories
      const column = pk ? 1 : 2
      // const loc = pk || dp
      // const { location, name } = loc || {}
      const foundTrajectories = trajectoriesState?.length
        ? trajectoriesState
        : addGetTrajectories({
            trip,
            trips,
            trajectoriesState,
            // cb: ({ updatedTraj }) => setTrajectoriesState(updatedTraj),
          })
      // const lastQueueIdx = getTrajectoryLastQueueIdx({
      //   trip,
      //   trips,
      //   leg,
      //   trajectoriesState,
      // })
      // const currentStage = getTrajCurrentStage({ trip, leg, trips })
      const driver = trip.driver || 'nodriver'
      // const queueIdx = foundTrajectories[driver]?.length ? lastQueueIdx + 1 : 1
      const queueIdx = foundTrajectories[driver]?.length
        ? foundTrajectories[driver]?.sort((a, b) => b?.queueIdx - a?.queueIdx)[0]?.queueIdx + 1
        : 1
      console.log('queueIdx: ', queueIdx)
      // console.log(trajectoryId)
      // if (validCoords({ coords: location })) {

      // setTrajectoriesState(foundTrajectories)

      const tripsWithSamePkOrDp = samePkOrDp({
        firstTrip: trip,
        tripsListData: trips,
        pk,
        dp,
        leg,
        column,
        tripsDest,
        constraint,
      })

      // const pendingT = getTripsPendingByDriver({
      //   trips,
      //   driver: trip?.driver,
      // })

      // const diffPkOrDpTrips = getRemainingItems(tripsWithSamePkOrDp, pendingT)

      tripsWithSamePkOrDp.forEach((tSamePkDp) => {
        const currentStage = getTripTrajStages({ trip: tSamePkDp, leg })[0]
        console.log('currentStage: ', currentStage)
        const {
          _id,
          customer,
          tripType,
          timeIdx: tripTimeIdx,
          trajectory: tripTraj,
          destData,
          destLen,
          destIdxToShow,
          idxToGo,
          isStart,
          isArrive,
        } = tripInfo({ trip: tSamePkDp, tripsDest })
        const destIdxByLeg =
          destLen === 1
            ? 0
            : pk
            ? leg && leg - 1 >= 0
              ? leg - 1
              : destIdxToShow
            : dp
            ? leg
              ? leg
              : destIdxToShow + 1
            : isStart || isArrive
            ? idxToGo + 1
            : idxToGo
        console.log('destIdxByLeg: ', destIdxByLeg)
        const tripLoc = destData[destIdxByLeg]
        const { location, name } = tripLoc || {}
        if (validCoords({ coords: location })) {
          const timeIdx = leg || tripTimeIdx || 1
          const trajectoryId = `${currentStage}-${_id}-${tripLoc._id}`
          const foundIdxInTrajState = foundTrajectories[driver]?.findIndex(
            (x) => x?.trajectoryId === trajectoryId,
          )
          const foundIdxInTrip = tSamePkDp?.trajectory?.findIndex(
            (x) => x?.trajectoryId === trajectoryId,
          )
          const foundTrajInTrip = tSamePkDp?.trajectory?.find(
            (x) => x?.trajectoryId === trajectoryId,
          )
          // const foundIdxInTrajState = foundTrajectories[driver]?.findIndex(
          //   (x) =>
          //     x?.tripId === _id &&
          //     x?.stage === currentStage &&
          //     // x?.row === row &&
          //     x?.column === column &&
          //     x?.destId === tripLoc?._id,
          // )
          // const foundIdxInTrip = tSamePkDp?.trajectory?.findIndex(
          //   (x) =>
          //     x?.tripId === _id &&
          //     x?.stage === currentStage &&
          //     // x?.row === row &&
          //     x?.column === column &&
          //     x?.destId === tripLoc?._id,
          // )
          const foundIdx = foundIdxInTrajState >= 0 ? foundIdxInTrajState : foundIdxInTrip
          console.log('foundIdx: ', foundIdx)
          console.log('foundIdxInTrajState: ', foundIdxInTrajState)
          console.log('foundIdxInTrip: ', foundIdxInTrip)
          console.log('trajectoryId: ', trajectoryId)
          if (foundIdx >= 0) {
            console.log('foundTrajectories[driver]: ', [...foundTrajectories[driver]])
            if (foundIdxInTrajState >= 0) {
              foundTrajectories[driver].splice(foundIdx, 1)

              console.log('foundTrajectories[driver]: ', foundTrajectories[driver])
              const reQueuedTraj = foundTrajectories[driver]
                // ?.filter((x) => +x?.stage === +currentStage)
                // .map((traj, i) => ({ ...traj, queueIdx: i + 1 }))
                .map((traj, i) => ({
                  ...traj,
                  queueIdx:
                    traj.queueIdx > foundTrajInTrip?.queueIdx
                      ? traj.queueIdx - 1 > 0
                        ? traj.queueIdx - 1
                        : traj.queueIdx || 1
                      : traj.queueIdx,
                }))
              const updatedTraj = {
                ...(foundTrajectories || {}),
                [driver]: sortTrajArr({
                  arr: reQueuedTraj,
                  // leg
                }),
              }
              // console.log(reQueuedTraj)
              console.log('updatedTraj: ', updatedTraj)
              setTrajectoriesState(updatedTraj)
            }
            // diffPkOrDpTrips.forEach((diffPkOrDp) => {
            //   const { trajectory } = diffPkOrDp
            //   const reQueueTripTraj = trajectory?.map(
            //     (ttraj) =>
            //       reQueuedTraj.find((reQ) => reQ?.trajectoryId === ttraj?.trajectoryId) || ttraj,
            //   )
            //   dispatch({
            //     type: UPDATE_TRIP_SUCCESS,
            //     payload: {
            //       trip: {
            //         ...diffPkOrDp,
            //         trajectory: reQueueTripTraj || [],
            //       },
            //     },
            //   })
            // })

            if (foundIdxInTrip >= 0) {
              tSamePkDp.trajectory.splice(foundIdxInTrip, 1)
              const tripTrajRequeue = {
                ...tSamePkDp,
                trajectory: tSamePkDp?.trajectory?.map((traj) => ({
                  ...traj,
                  queueIdx:
                    traj.queueIdx > foundTrajInTrip?.queueIdx
                      ? traj.queueIdx - 1 > 0
                        ? traj.queueIdx - 1
                        : traj.queueIdx || 1
                      : traj.queueIdx,
                })),
              }
              dispatch({
                type: UPDATE_TRIP_SUCCESS,
                payload: { trip: tripTrajRequeue },
              })
              if (!routeModeRef.current && updateTrip && typeof updateTrip === 'function') {
                updateTrip({
                  trips: [tripTrajRequeue],
                  formData: {
                    createRoute: true,
                  },
                })
              }
            }
          } else {
            // do not modify fields
            const newTraj = {
              name,
              tripType,
              coordType: tripLoc.level ? 'Customer' : 'Destination',
              tripId: String(tSamePkDp?._id),
              customer,
              trajectoryId,
              // trajectoryId: `${currentStage}-${_id}-${loc._id}`,
              queueIdx,
              stage: currentStage, // similar to timeIdx or leg but iqualized to all trips
              timeIdx, // leg
              // row,
              column,
              destId: tripLoc._id,
              location,
            }
            // console.log('newTraj: ', newTraj)
            // const updatedTraj = {
            //   ...trajectories,
            //   [driver]: [...(trajectories[driver] || []), newTraj],
            // }
            // console.log(updatedTraj[driver])
            const reQueuedTraj = [...(foundTrajectories[driver] || []), newTraj]
            // ?.filter((tt) => tt?.stage === currentStage)
            // .map((traj, i) => ({ ...traj, queueIdx: i + 1 }))
            setTrajectoriesState({
              ...(foundTrajectories || {}),
              [driver]: sortTrajArr({
                arr: reQueuedTraj,
                // leg
              }),
            })
            // console.log(reQueuedTraj.find((tt) => tt?.trajectoryId === trajectoryId))
            // const uTripTraj = reQueuedTraj.find((tt) => tt?.trajectoryId === newTraj.trajectoryId)
            // console.log('uTripTraj: ', uTripTraj)
            // if (!isEmpty(uTripTraj)) {
            const uTrip = {
              ...tSamePkDp,
              trajectory: sortTrajArr({
                arr: [...(tSamePkDp.trajectory || []), newTraj],
                // leg,
              }),
            }
            dispatch({
              type: UPDATE_TRIP_SUCCESS,
              payload: { trip: uTrip },
            })
            if (!routeModeRef.current && updateTrip && typeof updateTrip === 'function') {
              updateTrip({
                trips: [uTrip],
                formData: {
                  createRoute: true,
                },
              })
            }
            // }
          }
        }
      })
      // }
    }
    if (routeModeRef.current) return confirm()
    notify({
      type: 'warning',
      msg: `Are You Sure you want to remove this location from trajectory ??? Activate Route Mode to remove this warning`,
      confirm,
    })
  } catch (err) {
    handleErr({ err })
  }
}

const removeTrajectories = ({ driver, trips, trajectoriesState, cb }) => {
  try {
    const remainingTrajectories = addGetTrajectories({
      trip: { driver: driver || 'nodriver' },
      trips,
      trajectoriesState,
    })
    delete remainingTrajectories[driver || 'nodriver']
    // console.log(remainingTrajectories)
    cb && cb({ remainingTrajectories })
  } catch (err) {
    handleErr({ err })
  }
}

export {
  tripTrajectoryPickedUp,
  sortByTrajectoryQueueIdx,
  getTripTrajStages,
  findTripTrajectory,
  getTrajCurrentStage,
  addGetTrajectories,
  getTrajectoryLastQueueIdx,
  getTripsTrajectories,
  insertTripInTrajectory,
  removeTrajectories,
  createTrajectory,
}
