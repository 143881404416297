// Sidebar Constants
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR'
export const TOGGLE_UNFOLDABLE = 'TOGGLE_UNFOLDABLE'

// Search Params
export const UPDATE_SEARCHTERMS = 'UPDATE_SEARCHTERMS'
export const UPDATE_USER_SETTINGS = 'UPDATE_USER_SETTINGS'

// Quicknotes Params
export const GET_QUICKNOTES_SUCCESS = 'GET_QUICKNOTES_SUCCESS'
export const GET_QUICKNOTES_START = 'GET_QUICKNOTES_START'
export const UPDATE_QUICKNOTES = 'UPDATE_QUICKNOTES'
export const DELETE_QUICKNOTES = 'DELETE_QUICKNOTES'

//Auth Constants
export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST'
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS'
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL'

export const USER_RESET_PWD_START = 'USER_RESET_PWD_START'
export const USER_RESET_PWD_SUCCESS = 'USER_RESET_PWD_SUCCESS'
export const USER_RESET_PWD_FAIL = 'USER_RESET_PWD_FAIL'

export const USER_CHANGE_PWD_START = 'USER_CHANGE_PWD_START'
export const USER_CHANGE_PWD_SUCCESS = 'USER_CHANGE_PWD_SUCCESS'
export const USER_CHANGE_PWD_FAIL = 'USER_CHANGE_PWDFAILT'

export const USER_AUTH_REQUEST = 'USER_AUTH_REQUEST'
export const USER_AUTH_SUCCESS = 'USER_AUTH_SUCCESS'
export const USER_AUTH_FAIL = 'USER_AUTH_FAIL'

export const USER_LOGOUT_REQUEST = 'USER_LOGOUT_REQUEST'
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS'
export const USER_LOGOUT_FAIL = 'USER_LOGOUT_FAIL'

//Company Constants
export const COMPANY_REGISTER_REQUEST = 'COMPANY_REGISTER_REQUEST'
export const COMPANY_REGISTER_SUCCESS = 'COMPANY_REGISTER_SUCCESS'
export const COMPANY_REGISTER_FAIL = 'COMPANY_REGISTER_FAIL'

export const COMPANY_REGISTER_CLEAN = 'COMPANY_REGISTER_CLEAN'

export const COMPANY_LOGIN_REQUEST = 'COMPANY_LOGIN_REQUEST'
export const COMPANY_LOGIN_SUCCESS = 'COMPANY_LOGIN_SUCCESS'
export const COMPANY_LOGIN_FAIL = 'COMPANY_LOGIN_FAIL'

export const COMPANY_LOGOUT_SUCCESS = 'COMPANY_LOGOUT_SUCCESS'

export const OTP_REQUEST = 'OTP_REQUEST'
export const OTP_SUCCESS = 'OTP_SUCCESS'
export const OTP_FAIL = 'OTP_FAIL'

export const OTP_VERIFY_REQUEST = 'OTP_VERIFY_REQUEST'
export const OTP_VERIFY_SUCCESS = 'OTP_VERIFY_SUCCESS'
export const OTP_VERIFY_FAIL = 'OTP_VERIFY_FAIL'

export const OTP_RESEND_REQUEST = 'OTP_RESEND_REQUEST'
export const OTP_RESEND_SUCCESS = 'OTP_RESEND_SUCCESS'
export const OTP_RESEND_FAIL = 'OTP_RESEND_FAIL'

// /////////////////////////////////// Admin Constants ////////////////////////////////////

// USERS

export const CREATE_USER_START = 'CREATE_USER_START'
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
export const CREATE_USER_FAIL = 'CREATE_USER_FAIL'
export const CREATE_USER_RESET = 'CREATE_USER_RESET'

export const GET_USERS_START = 'GET_USERS_START'
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS'
export const GET_USERS_FAIL = 'GET_USERS_FAIL'

export const GET_USER_START = 'GET_USER_START'
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS'
export const GET_USER_FAIL = 'GET_USER_FAIL'

export const DELETE_USER_START = 'DELETE_USER_START'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL'

export const DEACTIVATE_USER_START = 'DEACTIVATE_USER_START'
export const DEACTIVATE_USER_SUCCESS = 'DEACTIVATE_USER_SUCCESS'
export const DEACTIVATE_USER_FAIL = 'DEACTIVATE_USER_FAIL'

export const UPDATE_USER_START = 'UPDATE_USER_START'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL'
export const UPDATE_USER_RESET = 'UPDATE_USER_RESET'

// DESTINATION

export const GET_DESTINATION_START = 'GET_DESTINATION_START'
export const GET_DESTINATION_SUCCESS = 'GET_DESTINATION_SUCCESS'
export const GET_DESTINATION_FAIL = 'GET_DESTINATION_FAIL'

export const GET_DESTINATIONS_START = 'GET_DESTINATIONS_START'
export const GET_DESTINATIONS_SUCCESS = 'GET_DESTINATIONS_SUCCESS'
export const GET_DESTINATIONS_FAIL = 'GET_DESTINATIONS_FAIL'

export const CREATE_DESTINATIONS_START = 'CREATE_DESTINATIONS_START'
export const CREATE_DESTINATIONS_SUCCESS = 'CREATE_DESTINATIONS_SUCCESS'
export const CREATE_DESTINATIONS_FAIL = 'CREATE_DESTINATIONS_FAIL'
export const CREATE_DESTINATIONS_RESET = 'CREATE_DESTINATIONS_RESET'

export const DELETE_DESTINATION_START = 'DELETE_DESTINATION_START'
export const DELETE_DESTINATION_SUCCESS = 'DELETE_DESTINATION_SUCCESS'
export const DELETE_DESTINATION_FAIL = 'DELETE_DESTINATION_FAIL'

export const DEACTIVATE_DESTINATION_START = 'DEACTIVATE_DESTINATION_START'
export const DEACTIVATE_DESTINATION_SUCCESS = 'DEACTIVATE_DESTINATION_SUCCESS'
export const DEACTIVATE_DESTINATION_FAIL = 'DEACTIVATE_DESTINATION_FAIL'

export const UPDATE_DESTINATION_START = 'UPDATE_DESTINATION_START'
export const UPDATE_DESTINATION_SUCCESS = 'UPDATE_DESTINATION_SUCCESS'
export const UPDATE_DESTINATION_FAIL = 'UPDATE_DESTINATION_FAIL'
export const UPDATE_DESTINATION_RESET = 'UPDATE_DESTINATION_RESET'

// TRIP

export const CREATE_TRIP_START = 'CREATE_TRIP_START'
export const CREATE_TRIP_SUCCESS = 'CREATE_TRIP_SUCCESS'
export const CREATE_TRIP_FAIL = 'CREATE_TRIP_FAIL'
export const CREATE_TRIP_RESET = 'CREATE_TRIP_RESET'

export const GET_TRIPS_START = 'GET_TRIPS_START'
export const GET_TRIPS_SUCCESS = 'GET_TRIPS_SUCCESS'
export const GET_TRIPS_FAIL = 'GET_TRIPS_FAIL'

export const GET_TRIPSREPORT_SUCCESS = 'GET_TRIPSREPORT_SUCCESS'
export const GET_TRIPSREPORT_FAIL = 'GET_TRIPSREPORT_FAIL'

export const GET_TRIP_START = 'GET_TRIP_START'
export const GET_TRIP_SUCCESS = 'GET_TRIP_SUCCESS'
export const GET_TRIP_FAIL = 'GET_TRIP_FAIL'

export const UPDATE_TRIP_START = 'UPDATE_TRIP_START'
export const UPDATE_TRIP_SUCCESS = 'UPDATE_TRIP_SUCCESS'
export const UPDATE_TRIP_FAIL = 'UPDATE_TRIP_FAIL'
export const UPDATE_TRIP_RESET = 'UPDATE_TRIP_RESET'

export const DELETE_TRIP_START = 'DELETE_TRIP_START'
export const DELETE_TRIP_SUCCESS = 'DELETE_TRIP_SUCCESS'
export const DELETE_TRIP_FAIL = 'DELETE_TRIP_FAIL'

export const DELETE_SCHEDULED_TRIP_START = 'DELETE_SCHEDULED_TRIP_START'
export const DELETE_SCHEDULED_TRIP_SUCCESS = 'DELETE_SCHEDULED_TRIP_SUCCESS'
export const DELETE_SCHEDULED_TRIP_FAIL = 'DELETE_SCHEDULED_TRIP_FAIL'

export const DEACTIVATE_TRIP_START = 'DEACTIVATE_TRIP_START'
export const DEACTIVATE_TRIP_SUCCESS = 'DEACTIVATE_TRIP_SUCCESS'
export const DEACTIVATE_TRIP_FAIL = 'DEACTIVATE_TRIP_FAIL'

export const CHANGE_TRIP_STATUS_START = 'CHANGE_TRIP_STATUS_START'
export const CHANGE_TRIP_STATUS_SUCCESS = 'CHANGE_TRIP_STATUS_SUCCESS'
export const CHANGE_TRIP_STATUS_FAIL = 'CHANGE_TRIP_STATUS_FAIL'

export const PUSH_DRIVER_LOC_START = 'PUSH_DRIVER_LOC_START'
export const PUSH_DRIVER_LOC_SUCCESS = 'PUSH_DRIVER_LOC_SUCCESS'
export const PUSH_DRIVER_LOC_FAIL = 'PUSH_DRIVER_LOC_FAIL'

// INSURANCE

export const GET_INSURANCES_START = 'GET_INSURANCES_START'
export const GET_INSURANCES_SUCCESS = 'GET_INSURANCES_SUCCESS'
export const GET_INSURANCES_FAIL = 'GET_INSURANCES_FAIL'

export const CREATE_INSURANCE_START = 'CREATE_INSURANCE_START'
export const CREATE_INSURANCE_SUCCESS = 'CREATE_INSURANCE_SUCCESS'
export const CREATE_INSURANCE_FAIL = 'CREATE_INSURANCE_FAIL'
export const CREATE_INSURANCE_RESET = 'CREATE_INSURANCE_RESET'

export const UPDATE_INSURANCE_START = 'UPDATE_INSURANCE_START'
export const UPDATE_INSURANCE_SUCCESS = 'UPDATE_INSURANCE_SUCCESS'
export const UPDATE_INSURANCE_FAIL = 'UPDATE_INSURANCE_FAIL'
export const UPDATE_INSURANCE_RESET = 'UPDATE_INSURANCE_RESET'

export const DELETE_INSURANCE_START = 'DELETE_INSURANCE_START'
export const DELETE_INSURANCE_SUCCESS = 'DELETE_INSURANCE_SUCCESS'
export const DELETE_INSURANCE_FAIL = 'DELETE_INSURANCE_FAIL'

export const DEACTIVATE_INSURANCE_START = 'DEACTIVATE_INSURANCE_START'
export const DEACTIVATE_INSURANCE_SUCCESS = 'DEACTIVATE_INSURANCE_SUCCESS'
export const DEACTIVATE_INSURANCE_FAIL = 'DEACTIVATE_INSURANCE_FAIL'

// BROKER

export const GET_BROKERS_START = 'GET_BROKERS_START'
export const GET_BROKERS_SUCCESS = 'GET_BROKERS_SUCCESS'
export const GET_BROKERS_FAIL = 'GET_BROKERS_FAIL'

export const CREATE_BROKER_START = 'CREATE_BROKER_START'
export const CREATE_BROKER_SUCCESS = 'CREATE_BROKER_SUCCESS'
export const CREATE_BROKER_FAIL = 'CREATE_BROKER_FAIL'
export const CREATE_BROKER_RESET = 'CREATE_BROKER_RESET'

export const UPDATE_BROKER_START = 'UPDATE_BROKER_START'
export const UPDATE_BROKER_SUCCESS = 'UPDATE_BROKER_SUCCESS'
export const UPDATE_BROKER_FAIL = 'UPDATE_BROKER_FAIL'
export const UPDATE_BROKER_RESET = 'UPDATE_BROKER_RESET'

export const DELETE_BROKER_START = 'DELETE_BROKER_START'
export const DELETE_BROKER_SUCCESS = 'DELETE_BROKER_SUCCESS'
export const DELETE_BROKER_FAIL = 'DELETE_BROKER_FAIL'

export const DEACTIVATE_BROKER_START = 'DEACTIVATE_BROKER_START'
export const DEACTIVATE_BROKER_SUCCESS = 'DEACTIVATE_BROKER_SUCCESS'
export const DEACTIVATE_BROKER_FAIL = 'DEACTIVATE_BROKER_FAIL'

// BROKER PRICING

export const GET_BROKERS_PRICING_START = 'GET_BROKERS_PRICING_START'
export const GET_BROKERS_PRICING_SUCCESS = 'GET_BROKERS_PRICING_SUCCESS'
export const GET_BROKERS_PRICING_FAIL = 'GET_BROKERS_PRICING_FAIL'

export const UPDATE_BROKER_PRICING_START = 'UPDATE_BROKER_PRICING_START'
export const UPDATE_BROKER_PRICING_SUCCESS = 'UPDATE_BROKER_PRICING_SUCCESS'
export const UPDATE_BROKER_PRICING_FAIL = 'UPDATE_BROKER_PRICING_FAIL'

// COMPANIES

export const GET_ALL_COMPANIES_START = 'GET_ALL_COMPANIES_START'
export const GET_ALL_COMPANIES_SUCCESS = 'GET_ALL_COMPANIES_SUCCESS'
export const GET_ALL_COMPANIES_FAIL = 'GET_ALL_COMPANIES_FAIL'

export const GET_COMPANY_START = 'GET_COMPANY_START'
export const GET_COMPANY_SUCCESS = 'GET_COMPANY_SUCCESS'
export const GET_COMPANY_FAIL = 'GET_COMPANY_FAIL'

export const DEACTIVATE_COMPANY_START = 'DEACTIVATE_COMPANY_START'
export const DEACTIVATE_COMPANY_SUCCESS = 'DEACTIVATE_COMPANY_SUCCESS'
export const DEACTIVATE_COMPANY_FAIL = 'DEACTIVATE_COMPANY_FAIL'

export const UPDATE_COMPANY_RESET = 'UPDATE_COMPANY_RESET'
export const UPDATE_COMPANY_START = 'UPDATE_COMPANY_START'
export const UPDATE_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS'
export const UPDATE_COMPANY_FAIL = 'UPDATE_COMPANY_FAIL'

export const CREATE_COMPANY_RESET = 'CREATE_COMPANY_RESET'
export const CREATE_COMPANY_START = 'CREATE_COMPANY_START'
export const CREATE_COMPANY_SUCCESS = 'CREATE_COMPANY_SUCCESS'
export const CREATE_COMPANY_FAIL = 'CREATE_COMPANY_FAIL'

export const DELETE_COMPANY_START = 'DELETE_COMPANY_START'
export const DELETE_COMPANY_SUCCESS = 'DELETE_COMPANY_SUCCESS'
export const DELETE_COMPANY_FAIL = 'DELETE_COMPANY_FAIL'

// SETTINGS

export const GET_COMPANY_SETTINGS_START = 'GET_COMPANY_SETTINGS_START'
export const GET_COMPANY_SETTINGS_SUCCESS = 'GET_COMPANY_SETTINGS_SUCCESS'
export const GET_COMPANY_SETTINGS_FAIL = 'GET_COMPANY_SETTINGS_FAIL'

// COUPON

export const CREATE_COUPON_CLEAN = 'CREATE_COUPON_CLEAN'

export const CREATE_COUPON_START = 'CREATE_COUPON_START'
export const CREATE_COUPON_SUCCESS = 'CREATE_COUPON_SUCCESS'
export const CREATE_COUPON_FAIL = 'CREATE_COUPON_FAIL'

export const GET_COUPONS_START = 'GET_COUPONS_START'
export const GET_COUPONS_SUCCESS = 'GET_COUPONS_SUCCESS'
export const GET_COUPONS_FAIL = 'GET_COUPONS_FAIL'

export const GET_COUPON_START = 'GET_COUPON_START'
export const GET_COUPON_SUCCESS = 'GET_COUPON_SUCCESS'
export const GET_COUPON_FAIL = 'GET_COUPON_FAIL'

export const UPDATE_COUPON_RESET = 'UPDATE_COUPON_RESET'
export const UPDATE_COUPON_START = 'UPDATE_COUPON_START'
export const UPDATE_COUPON_SUCCESS = 'UPDATE_COUPON_SUCCESS'
export const UPDATE_COUPON_FAIL = 'UPDATE_COUPON_FAIL'

export const DELETE_COUPON_START = 'DELETE_COUPON_START'
export const DELETE_COUPON_SUCCESS = 'DELETE_COUPON_SUCCESS'
export const DELETE_COUPON_FAIL = 'DELETE_COUPON_FAIL'

export const GET_TRANSACTIONS_START = 'GET_TRANSACTIONS_START'
export const GET_TRANSACTIONS_SUCCESS = 'GET_TRANSACTIONS_SUCCESS'
export const GET_TRANSACTIONS_FAIL = 'GET_TRANSACTIONS_FAIL'

export const DELETE_TRANSACTION_START = 'DELETE_TRANSACTION_START'
export const DELETE_TRANSACTION_SUCCESS = 'DELETE_TRANSACTION_SUCCESS'
export const DELETE_TRANSACTION_FAIL = 'DELETE_TRANSACTION_FAIL'

export const GET_BILLING_AGREEMENT_START = 'GET_BILLING_AGREEMENT_START'
export const GET_BILLING_AGREEMENT_SUCCESS = 'GET_BILLING_AGREEMENT_SUCCESS'
export const GET_BILLING_AGREEMENT_FAIL = 'GET_BILLING_AGREEMENT_FAIL'

export const CANCEL_BILLING_AGREEMENT_START = 'CANCEL_BILLING_AGREEMENT_START'
export const CANCEL_BILLING_AGREEMENT_SUCCESS = 'CANCEL_BILLING_AGREEMENT_SUCCESS'
export const CANCEL_BILLING_AGREEMENT_FAIL = 'CANCEL_BILLING_AGREEMENT_FAIL'

export const UPDATE_BILLING_AGREEMENT_START = 'UPDATE_BILLING_AGREEMENT_START'
export const UPDATE_BILLING_AGREEMENT_SUCCESS = 'UPDATE_BILLING_AGREEMENT_SUCCESS'
export const UPDATE_BILLING_AGREEMENT_FAIL = 'UPDATE_BILLING_AGREEMENT_FAIL'

// export const UPDATE_AGREEMENT_PRICING_START = 'UPDATE_AGREEMENT_PRICING_START'
// export const UPDATE_AGREEMENT_PRICING_SUCCESS = 'UPDATE_AGREEMENT_PRICING_SUCCESS'
// export const UPDATE_AGREEMENT_PRICING_FAIL = 'UPDATE_AGREEMENT_PRICING_FAIL'

export const CREATE_VEHICLE_RESET = 'CREATE_VEHICLE_RESET'
export const CREATE_VEHICLE_START = 'CREATE_VEHICLE_START'
export const CREATE_VEHICLE_SUCCESS = 'CREATE_VEHICLE_SUCCESS'
export const CREATE_VEHICLE_FAIL = 'CREATE_VEHICLE_FAIL'

export const UPDATE_VEHICLE_RESET = 'UPDATE_VEHICLE_RESET'
export const UPDATE_VEHICLE_START = 'UPDATE_VEHICLE_START'
export const UPDATE_VEHICLE_SUCCESS = 'UPDATE_VEHICLE_SUCCESS'
export const UPDATE_VEHICLE_FAIL = 'UPDATE_VEHICLE_FAIL'

export const DELETE_VEHICLE_START = 'DELETE_VEHICLE_START'
export const DELETE_VEHICLE_SUCCESS = 'DELETE_VEHICLE_SUCCESS'
export const DELETE_VEHICLE_FAIL = 'DELETE_VEHICLE_FAIL'

export const GET_VEHICLES_RESET = 'GET_VEHICLES_RESET'
export const GET_VEHICLES_START = 'GET_VEHICLES_START'
export const GET_VEHICLES_SUCCESS = 'GET_VEHICLES_SUCCESS'
export const GET_VEHICLES_FAIL = 'GET_VEHICLES_FAIL'

export const GET_VEHICLE_START = 'GET_VEHICLE_START'
export const GET_VEHICLE_SUCCESS = 'GET_VEHICLE_SUCCESS'
export const GET_VEHICLE_FAIL = 'GET_VEHICLE_FAIL'

export const DEACTIVATE_VEHICLE_START = 'DEACTIVATE_VEHICLE_START'
export const DEACTIVATE_VEHICLE_SUCCESS = 'DEACTIVATE_VEHICLE_SUCCESS'
export const DEACTIVATE_VEHICLE_FAIL = 'DEACTIVATE_VEHICLE_FAIL'

export const GET_INSPECTION_START = 'GET_INSPECTION_START'
export const GET_INSPECTION_SUCCESS = 'GET_INSPECTION_SUCCESS'
export const GET_INSPECTION_FAIL = 'GET_INSPECTION_FAIL'
export const GET_INSPECTIONS_START = 'GET_INSPECTIONS_START'
export const GET_INSPECTIONS_SUCCESS = 'GET_INSPECTIONS_SUCCESS'
export const GET_INSPECTIONS_FAIL = 'GET_INSPECTIONS_FAIL'

export const CREATE_INSPECTION_RESET = 'CREATE_INSPECTION_RESET'
export const CREATE_INSPECTION_START = 'CREATE_INSPECTION_START'
export const CREATE_INSPECTION_SUCCESS = 'CREATE_INSPECTION_SUCCESS'
export const CREATE_INSPECTION_FAIL = 'CREATE_INSPECTION_FAIL'

export const UPDATE_INSPECTION_RESET = 'UPDATE_INSPECTION_RESET'
export const UPDATE_INSPECTION_START = 'UPDATE_INSPECTION_START'
export const UPDATE_INSPECTION_SUCCESS = 'UPDATE_INSPECTION_SUCCESS'
export const UPDATE_INSPECTION_FAIL = 'UPDATE_INSPECTION_FAIL'

// SIGNATURE
export const GET_SIGNATURE_SUCCESS = 'GET_SIGNATURE_SUCCESS'
export const GET_SIGNATURE_FAIL = 'GET_SIGNATURE_FAIL'

export const UPDATE_SIGNATURE_SUCCESS = 'UPDATE_SIGNATURE_SUCCESS'
export const UPDATE_SIGNATURE_FAIL = 'UPDATE_SIGNATURE_FAIL'

// PRODUCT
export const GET_PRODUCT_START = 'GET_PRODUCT_START'
export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS'
export const GET_PRODUCT_FAIL = 'GET_PRODUCT_FAIL'

export const GET_PRODUCTS_START = 'GET_PRODUCTS_START'
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS'
export const GET_PRODUCTS_FAIL = 'GET_PRODUCTS_FAIL'

export const GET_CUSTOMER_PRODUCTS_START = 'GET_CUSTOMER_PRODUCTS_START'
export const GET_CUSTOMER_PRODUCTS_SUCCESS = 'GET_CUSTOMER_PRODUCTS_SUCCESS'
export const GET_CUSTOMER_PRODUCTS_FAIL = 'GET_CUSTOMER_PRODUCTS_FAIL'

export const CREATE_PRODUCTS_START = 'CREATE_PRODUCTS_START'
export const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS'
export const CREATE_PRODUCTS_FAIL = 'CREATE_PRODUCTS_FAIL'
export const CREATE_PRODUCTS_RESET = 'CREATE_PRODUCTS_RESET'

export const DELETE_PRODUCT_START = 'DELETE_PRODUCT_START'
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS'
export const DELETE_PRODUCT_FAIL = 'DELETE_PRODUCT_FAIL'

export const DEACTIVATE_PRODUCT_START = 'DEACTIVATE_PRODUCT_START'
export const DEACTIVATE_PRODUCT_SUCCESS = 'DEACTIVATE_PRODUCT_SUCCESS'
export const DEACTIVATE_PRODUCT_FAIL = 'DEACTIVATE_PRODUCT_FAIL'

export const UPDATE_PRODUCT_START = 'UPDATE_PRODUCT_START'
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS'
export const UPDATE_PRODUCT_FAIL = 'UPDATE_PRODUCT_FAIL'
export const UPDATE_PRODUCT_RESET = 'UPDATE_PRODUCT_RESET'

export const SAVE_DEVICE_DETAILS = 'SAVE_DEVICE_DETAILS'

// PRODUCT LOGS
export const GET_PRODUCT_LOGS_START = 'GET_PRODUCT_LOGS_START'
export const GET_PRODUCT_LOGS_SUCCESS = 'GET_PRODUCT_LOGS_SUCCESS'
export const GET_PRODUCT_LOGS_FAIL = 'GET_PRODUCT_LOGS_FAIL'

export const GET_VEHICLE_PRODUCTS_START = 'GET_VEHICLE_PRODUCTS_START'
export const GET_VEHICLE_PRODUCTS_SUCCESS = 'GET_VEHICLE_PRODUCTS_SUCCESS'
export const GET_VEHICLE_PRODUCTS_FAIL = 'GET_VEHICLE_PRODUCTS_FAIL'

// CLOCK
export const FIND_CLOCK_START = 'FIND_CLOCK_START'
export const FIND_CLOCK_SUCCESS = 'FIND_CLOCK_SUCCESS'
export const FIND_CLOCK_FAIL = 'FIND_CLOCK_FAIL'
export const FIND_TIMECARD_START = 'FIND_TIMECARD_START'
export const FIND_TIMECARD_SUCCESS = 'FIND_TIMECARD_SUCCESS'
export const FIND_TIMECARD_FAIL = 'FIND_TIMECARD_FAIL'
export const CREATE_CLOCK_START = 'CREATE_CLOCK_START'
export const CREATE_CLOCK_SUCCESS = 'CREATE_CLOCK_SUCCESS'
export const CREATE_CLOCK_FAIL = 'CREATE_CLOCK_FAIL'
export const UPDATE_CLOCK_START = 'UPDATE_CLOCK_START'
export const UPDATE_CLOCK_SUCCESS = 'UPDATE_CLOCK_SUCCESS'
export const UPDATE_CLOCK_FAIL = 'UPDATE_CLOCK_FAIL'

// HOLIDAY
export const FIND_HOLIDAY_START = 'FIND_HOLIDAY_START'
export const FIND_HOLIDAY_SUCCESS = 'FIND_HOLIDAY_SUCCESS'
export const FIND_HOLIDAY_FAIL = 'FIND_HOLIDAY_FAIL'
export const CREATE_HOLIDAY_START = 'CREATE_HOLIDAY_START'
export const CREATE_HOLIDAY_SUCCESS = 'CREATE_HOLIDAY_SUCCESS'
export const CREATE_HOLIDAY_FAIL = 'CREATE_HOLIDAY_FAIL'
export const UPDATE_HOLIDAY_START = 'UPDATE_HOLIDAY_START'
export const UPDATE_HOLIDAY_SUCCESS = 'UPDATE_HOLIDAY_SUCCESS'
export const UPDATE_HOLIDAY_FAIL = 'UPDATE_HOLIDAY_FAIL'

// ABSENSE
export const FIND_ABSENSE_START = 'FIND_ABSENSE_START'
export const FIND_ABSENSE_SUCCESS = 'FIND_ABSENSE_SUCCESS'
export const FIND_ABSENSE_FAIL = 'FIND_ABSENSE_FAIL'
export const CREATE_ABSENSE_START = 'CREATE_ABSENSE_START'
export const CREATE_ABSENSE_SUCCESS = 'CREATE_ABSENSE_SUCCESS'
export const CREATE_ABSENSE_FAIL = 'CREATE_ABSENSE_FAIL'
export const UPDATE_ABSENSE_START = 'UPDATE_ABSENSE_START'
export const UPDATE_ABSENSE_SUCCESS = 'UPDATE_ABSENSE_SUCCESS'
export const UPDATE_ABSENSE_FAIL = 'UPDATE_ABSENSE_FAIL'

// REMINDER

export const GET_REMINDERS_START = 'GET_REMINDERS_START'
export const GET_REMINDERS_SUCCESS = 'GET_REMINDERS_SUCCESS'
export const GET_REMINDERS_FAIL = 'GET_REMINDERS_FAIL'

export const CREATE_REMINDER_START = 'CREATE_REMINDER_START'
export const CREATE_REMINDER_SUCCESS = 'CREATE_REMINDER_SUCCESS'
export const CREATE_REMINDER_FAIL = 'CREATE_REMINDER_FAIL'
export const CREATE_REMINDER_RESET = 'CREATE_REMINDER_RESET'

export const UPDATE_REMINDER_START = 'UPDATE_REMINDER_START'
export const UPDATE_REMINDER_SUCCESS = 'UPDATE_REMINDER_SUCCESS'
export const UPDATE_REMINDER_FAIL = 'UPDATE_REMINDER_FAIL'
export const UPDATE_REMINDER_RESET = 'UPDATE_REMINDER_RESET'

export const DELETE_REMINDER_START = 'DELETE_REMINDER_START'
export const DELETE_REMINDER_SUCCESS = 'DELETE_REMINDER_SUCCESS'
export const DELETE_REMINDER_FAIL = 'DELETE_REMINDER_FAIL'

export const DEACTIVATE_REMINDER_START = 'DEACTIVATE_REMINDER_START'
export const DEACTIVATE_REMINDER_SUCCESS = 'DEACTIVATE_REMINDER_SUCCESS'
export const DEACTIVATE_REMINDER_FAIL = 'DEACTIVATE_REMINDER_FAIL'
