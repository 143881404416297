import React from 'react'
import { ThreeDots } from 'react-loader-spinner'

const DotsLoader = () => (
  <ThreeDots
    height="20"
    width="40"
    radius="9"
    color="#333"
    ariaLabel="three-dots-loading"
    wrapperStyle={{ width: 'fit-content', margin: 'auto' }}
    wrapperClassName=""
    visible={true}
  />
)

export default React.memo(DotsLoader)
