import { CModal, CModalBody, CModalHeader, CModalTitle, CButton } from '@coreui/react'
import React, { useEffect, useRef, useState } from 'react'
import OTPInput from 'react-otp-input'
import './otp.scss'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { resendOtp, verifyOtp } from 'src/actions/companyActions'
import DotsLoader from 'src/components/DotsLoader'
import AlertMessage from 'src/components/AlertMessage'
import handleIDB from '../../../utils/handle-indexed-db'
import CIcon from '@coreui/icons-react'
import { cilX } from '@coreui/icons'
import handleErr from 'src/utils/handle-error'
import { axiosPublic } from 'src/services/api'

const OtpModal = ({ otpModalOpen, setOtpModalOpen, userData, setLoginLoading, guest }) => {
  const dispatch = useDispatch()
  // const [verifyOtpLoading, setVerifyOtpLoading] = useState(false)
  const otpExpiryTime = 60
  const [otp, setOtp] = useState('')
  const [saveDevice, setSaveDevice] = useState(true)
  // const [resendLoading, setResendLoading] = useState(false)
  // const [verifyLoading, setVerifyLoading] = useState(false)
  const [timeLeft, setTimeLeft] = useState(otpExpiryTime)
  const [otpInput, setOtpInput] = useState(null)

  const isMounted = useRef(true)

  const {
    verifyOtpLoading,
    resendOtpLoading,
    otpError = '',
  } = useSelector((state) => state?.companyAuth)

  const handleOtpSubmit = async (e) => {
    try {
      e.preventDefault()
      if (guest) {
        await axiosPublic.post('/users/verifyOtp', { email: userData.email, otp: otp })
        verifyOtpSuccessHandler()
        setOtpModalOpen(false)
      } else {
        isMounted.current &&
          dispatch(verifyOtp({ email: userData.email, otp, saveDevice }, verifyOtpSuccessHandler))
      }
    } catch (err) {
      handleErr({ err })
    }
  }

  const verifyOtpSuccessHandler = () => {
    try {
      if (saveDevice) {
        handleIDB.put({
          model: 'Device',
          data: { email: userData.email, deviceSaved: true },
          _id: userData.email,
        })
      }
    } catch (err) {
      handleErr({ err })
      setLoginLoading && setLoginLoading(false)
    }
  }

  const resendOtpHandler = (e) => {
    try {
      e?.preventDefault && e.preventDefault()
      setOtp('')
      isMounted.current && dispatch(resendOtp(userData.email, resendOtpSuccessHandler))
    } catch (err) {
      handleErr({ err })
      setLoginLoading && setLoginLoading(false)
    }
  }

  const resendOtpSuccessHandler = () => {
    setTimeLeft(otpExpiryTime)
  }

  useEffect(() => {
    isMounted.current = true
    const interval = setInterval(() => {
      isMounted.current &&
        setTimeLeft((prevTimeLeft) => {
          if (prevTimeLeft > 0) {
            return prevTimeLeft - 1
          }
          // clearInterval(interval) // Clear the interval when timeLeft reaches 0
          // setOtpExpired(true)
          return 0
        })
    }, 1000)

    window.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        const otpInputs = document.querySelectorAll('.otp-input-container input')
        if (otpInputs?.length) {
          const emptyInput = Array.from(otpInputs).filter((input) => !input?.value)[0]
          emptyInput?.focus()
        }
      }
    })

    return () => {
      isMounted.current = false
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    try {
      if (otpError?.length) {
        setLoginLoading && setLoginLoading(false)
      }
    } catch (err) {
      handleErr({ err })
      setLoginLoading && setLoginLoading(false)
    }
  }, [otpError, setLoginLoading])

  useEffect(() => {
    try {
      setTimeout(
        () => {
          setOtpInput(
            <OTPInput
              inputType={'number'}
              value={otp}
              onChange={setOtp}
              numInputs={5}
              renderSeparator={<span className="invisible">-</span>}
              renderInput={(props) => <input type="number" required {...props} />}
              containerStyle="otp-input-container"
              inputStyle="opt-input shadow-hover"
              shouldAutoFocus={true}
            />,
          )
        },
        !otp ? 707 : 0,
      )
    } catch (err) {
      handleErr({ err })
      setLoginLoading && setLoginLoading(false)
    }
  }, [otp, setLoginLoading])

  return (
    <CModal
      id="OTP"
      scrollable={true}
      alignment="center"
      visible={otpModalOpen ? true : false}
      onClose={(e) => {
        e?.preventDefault && e.preventDefault()
        setOtpModalOpen(false)
      }}
      size="lg"
    >
      <CModalHeader className="otp-modal-header">
        <CModalTitle>Verification Code sent to you !</CModalTitle>
      </CModalHeader>
      <CModalBody className="grad-light-blue">
        <p className="w-100 text-center mb-n1">
          {userData?.email?.split('@')[0][0]}...
          {userData?.email?.split('@')[0][userData?.email?.split('@')[0].length - 1]}@
          {userData?.email?.split('@')[1]}
        </p>
        <div className="otp-form-container">
          <form onSubmit={handleOtpSubmit} className="otp-form">
            <div className="pb-2">
              {timeLeft > 0 ? (
                <p className="text-center fw-bold">
                  Code will expire in :{' '}
                  {timeLeft > 59
                    ? `0${Math.floor(timeLeft / 60)}:${
                        timeLeft % 60 < 10 ? `0${timeLeft % 60}` : timeLeft % 60
                      }`
                    : `00:${timeLeft < 10 ? `0${timeLeft}` : timeLeft}`}
                </p>
              ) : (
                <p className="text-center fw-bold text-danger">
                  Verification Code is expired, <br /> Please get a new one!
                </p>
              )}
            </div>
            <div className="input-group mb-3">{otpInput}</div>
            <div className="input-group py-3 mb-3 justify-content-center">
              <div className="p-0 bg-secondary rounded-start">
                <input
                  id="inlineCheckbox1"
                  label="Remember me"
                  className="form-check-input m-1"
                  type="checkbox"
                  // value={saveDevice}
                  checked={saveDevice}
                  onChange={(e) => setSaveDevice(e.target.checked)}
                  name="saveDevice"
                  // defaultChecked={true}
                  role="button"
                />
              </div>
              <span className="px-2 grad-light-blue rounded-end">Save device</span>
              {/* <span className=" px-2 grad-light-blue rounded-end">
                Save device ( Don&apos;t require Otp next time )
              </span> */}
            </div>
            {/* {console.log('otpError: ', otpError)} */}
            {/* {alert('otpError: ', JSON.stringify(otpError))} */}
            {otpError?.length ? <AlertMessage type="danger">{otpError}</AlertMessage> : null}
            <div className="verify-otp-button-container justify-content-center">
              {/* <CButton color="primary" type="button" className="verify-OtpButton">
                {verifyOtpLoading ? 'Loading...' : 'Resend Verification Code'}
              </CButton> */}
              {otpError?.length &&
              ((otpError.toLowerCase && otpError.toLowerCase().indexOf('session expired') !== -1) ||
                otpError[0]?.toLowerCase().indexOf('session expired') !== -1) ? (
                <button
                  className="jelly-btn grad-danger-white"
                  onClick={(e) => {
                    e.preventDefault()
                    setOtpModalOpen(false)
                  }}
                >
                  <CIcon icon={cilX} />
                  Close
                </button>
              ) : (
                <>
                  {timeLeft > 0 ? null : (
                    <CButton
                      className="shadow-gray px-3 grad-warn-white text-green fw-bold"
                      type="button"
                      onClick={resendOtpHandler}
                      disabled={timeLeft > 0}
                    >
                      {resendOtpLoading ? <DotsLoader /> : 'RESEND CODE'}
                    </CButton>
                  )}
                  {timeLeft > 0 ? (
                    <CButton
                      id="loginButton"
                      className="shadow-gray px-3 grad-success-white fw-bold"
                      type="submit"
                    >
                      {verifyOtpLoading ? <DotsLoader /> : 'VERIFY CODE'}
                    </CButton>
                  ) : null}
                </>
              )}
            </div>
          </form>
        </div>
      </CModalBody>
    </CModal>
  )
}

OtpModal.propTypes = {
  otpModalOpen: PropTypes.any,
  setOtpModalOpen: PropTypes.any,
  setLoginLoading: PropTypes.any,
  guest: PropTypes.any,
  userData: PropTypes.any,
}

export default OtpModal
