import { getFullName, uniqueByCustomerAndPickup, uniqueById } from 'src/utils/helpers'
import {
  CREATE_USER_RESET,
  CREATE_USER_SUCCESS,
  CREATE_USER_START,
  CREATE_USER_FAIL,
  GET_USERS_START,
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,
  GET_USER_START,
  GET_USER_SUCCESS,
  GET_USER_FAIL,
  UPDATE_USER_RESET,
  UPDATE_USER_START,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  // DELETE_USER_START,
  // DELETE_USER_SUCCESS,
  // DELETE_USER_FAIL,
  DEACTIVATE_USER_START,
  DEACTIVATE_USER_SUCCESS,
  DEACTIVATE_USER_FAIL,
  // CREATE_AREA_RESET,
  // CREATE_AREA_START,
  // CREATE_AREA_SUCCESS,
  // CREATE_AREA_FAIL,
  // GET_AREA_START,
  // GET_AREA_SUCCESS,
  // GET_AREA_FAIL,
  // UPDATE_AREA_RESET,
  // UPDATE_AREA_START,
  // UPDATE_AREA_SUCCESS,
  // UPDATE_AREA_FAIL,
  // DELETE_AREA_START,
  // DELETE_AREA_SUCCESS,
  // DELETE_AREA_FAIL,
  // DEACTIVATE_AREA_START,
  // DEACTIVATE_AREA_SUCCESS,
  // DEACTIVATE_AREA_FAIL,
  // GET_AREAS_START,
  // GET_AREAS_SUCCESS,
  // GET_AREAS_FAIL,
  // GET_HOSPITALS_DESTINATIONS_START,
  // GET_HOSPITALS_DESTINATIONS_SUCCESS,
  // GET_HOSPITALS_DESTINATIONS_FAIL,
  GET_DESTINATION_START,
  GET_DESTINATION_SUCCESS,
  GET_DESTINATION_FAIL,
  GET_DESTINATIONS_START,
  GET_DESTINATIONS_SUCCESS,
  GET_DESTINATIONS_FAIL,
  CREATE_DESTINATIONS_RESET,
  CREATE_DESTINATIONS_START,
  CREATE_DESTINATIONS_SUCCESS,
  CREATE_DESTINATIONS_FAIL,
  UPDATE_DESTINATION_RESET,
  UPDATE_DESTINATION_START,
  UPDATE_DESTINATION_SUCCESS,
  UPDATE_DESTINATION_FAIL,
  DEACTIVATE_DESTINATION_START,
  DEACTIVATE_DESTINATION_SUCCESS,
  DEACTIVATE_DESTINATION_FAIL,
  DELETE_DESTINATION_START,
  DELETE_DESTINATION_SUCCESS,
  DELETE_DESTINATION_FAIL,
  CREATE_TRIP_RESET,
  CREATE_TRIP_START,
  CREATE_TRIP_SUCCESS,
  CREATE_TRIP_FAIL,
  GET_TRIPS_START,
  GET_TRIPS_SUCCESS,
  GET_TRIPS_FAIL,
  GET_TRIPSREPORT_SUCCESS,
  GET_TRIPSREPORT_FAIL,
  GET_TRIP_START,
  GET_TRIP_SUCCESS,
  GET_TRIP_FAIL,
  UPDATE_TRIP_RESET,
  UPDATE_TRIP_START,
  UPDATE_TRIP_SUCCESS,
  UPDATE_TRIP_FAIL,
  DEACTIVATE_TRIP_START,
  DEACTIVATE_TRIP_SUCCESS,
  DEACTIVATE_TRIP_FAIL,
  // DELETE_TRIP_START,
  DELETE_TRIP_SUCCESS,
  // DELETE_TRIP_FAIL,
  DELETE_SCHEDULED_TRIP_START,
  DELETE_SCHEDULED_TRIP_SUCCESS,
  DELETE_SCHEDULED_TRIP_FAIL,
  GET_ALL_COMPANIES_START,
  GET_ALL_COMPANIES_SUCCESS,
  GET_ALL_COMPANIES_FAIL,
  UPDATE_COMPANY_START,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_FAIL,
  UPDATE_COMPANY_RESET,
  GET_COMPANY_START,
  GET_COMPANY_SUCCESS,
  GET_COMPANY_FAIL,
  GET_COMPANY_SETTINGS_START,
  GET_COMPANY_SETTINGS_SUCCESS,
  GET_COMPANY_SETTINGS_FAIL,
  DEACTIVATE_COMPANY_START,
  DEACTIVATE_COMPANY_SUCCESS,
  DEACTIVATE_COMPANY_FAIL,
  DELETE_COMPANY_START,
  DELETE_COMPANY_SUCCESS,
  DELETE_COMPANY_FAIL,
  CREATE_COMPANY_RESET,
  CREATE_COMPANY_START,
  CREATE_COMPANY_SUCCESS,
  CREATE_COMPANY_FAIL,
  CREATE_COUPON_CLEAN,
  CREATE_COUPON_START,
  CREATE_COUPON_SUCCESS,
  CREATE_COUPON_FAIL,
  GET_COUPONS_START,
  GET_COUPONS_SUCCESS,
  GET_COUPONS_FAIL,
  DELETE_COUPON_START,
  DELETE_COUPON_SUCCESS,
  DELETE_COUPON_FAIL,
  UPDATE_COUPON_START,
  UPDATE_COUPON_RESET,
  UPDATE_COUPON_SUCCESS,
  UPDATE_COUPON_FAIL,
  GET_COUPON_START,
  GET_COUPON_SUCCESS,
  GET_COUPON_FAIL,
  GET_TRANSACTIONS_START,
  GET_TRANSACTIONS_SUCCESS,
  GET_TRANSACTIONS_FAIL,
  DELETE_TRANSACTION_START,
  DELETE_TRANSACTION_SUCCESS,
  DELETE_TRANSACTION_FAIL,
  UPDATE_BILLING_AGREEMENT_FAIL,
  UPDATE_BILLING_AGREEMENT_SUCCESS,
  UPDATE_BILLING_AGREEMENT_START,
  CANCEL_BILLING_AGREEMENT_FAIL,
  CANCEL_BILLING_AGREEMENT_SUCCESS,
  // UPDATE_AGREEMENT_PRICING_START,
  // CANCEL_AGREEMENT_PRICING_FAIL,
  // CANCEL_AGREEMENT_PRICING_SUCCESS,
  CANCEL_BILLING_AGREEMENT_START,
  GET_BILLING_AGREEMENT_FAIL,
  GET_BILLING_AGREEMENT_SUCCESS,
  GET_BILLING_AGREEMENT_START,
  CREATE_VEHICLE_RESET,
  CREATE_VEHICLE_START,
  CREATE_VEHICLE_SUCCESS,
  CREATE_VEHICLE_FAIL,
  DELETE_VEHICLE_START,
  DELETE_VEHICLE_SUCCESS,
  DELETE_VEHICLE_FAIL,
  UPDATE_VEHICLE_RESET,
  UPDATE_VEHICLE_START,
  UPDATE_VEHICLE_SUCCESS,
  UPDATE_VEHICLE_FAIL,
  GET_VEHICLES_START,
  GET_VEHICLES_SUCCESS,
  GET_VEHICLES_FAIL,
  GET_VEHICLE_START,
  GET_VEHICLE_SUCCESS,
  GET_VEHICLE_FAIL,
  DEACTIVATE_VEHICLE_START,
  DEACTIVATE_VEHICLE_SUCCESS,
  DEACTIVATE_VEHICLE_FAIL,
  GET_INSPECTION_START,
  GET_INSPECTION_SUCCESS,
  GET_INSPECTION_FAIL,
  GET_INSPECTIONS_START,
  GET_INSPECTIONS_SUCCESS,
  GET_INSPECTIONS_FAIL,
  CREATE_INSPECTION_RESET,
  CREATE_INSPECTION_START,
  CREATE_INSPECTION_SUCCESS,
  CREATE_INSPECTION_FAIL,
  UPDATE_INSPECTION_RESET,
  UPDATE_INSPECTION_START,
  UPDATE_INSPECTION_SUCCESS,
  UPDATE_INSPECTION_FAIL,
  GET_SIGNATURE_SUCCESS,
  GET_SIGNATURE_FAIL,
  UPDATE_SIGNATURE_SUCCESS,
  UPDATE_SIGNATURE_FAIL,
  GET_PRODUCTS_START,
  GET_PRODUCTS_SUCCESS,
  UPDATE_PRODUCT_FAIL,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_START,
  UPDATE_PRODUCT_RESET,
  CREATE_PRODUCTS_FAIL,
  CREATE_PRODUCT_SUCCESS,
  CREATE_PRODUCTS_START,
  CREATE_PRODUCTS_RESET,
  GET_PRODUCTS_FAIL,
  GET_CUSTOMER_PRODUCTS_START,
  GET_CUSTOMER_PRODUCTS_SUCCESS,
  GET_CUSTOMER_PRODUCTS_FAIL,
  GET_PRODUCT_LOGS_START,
  GET_PRODUCT_LOGS_SUCCESS,
  GET_PRODUCT_LOGS_FAIL,
  GET_VEHICLE_PRODUCTS_START,
  GET_VEHICLE_PRODUCTS_SUCCESS,
  GET_VEHICLE_PRODUCTS_FAIL,
  DELETE_PRODUCT_SUCCESS,
  GET_VEHICLES_RESET,
} from '../constants/index'
import isEmpty from 'src/utils/is-empty'
import { isCustomer } from 'src/utils/check-user-level'
import insurances from 'src/utils/insurances'

const initialState = {
  createdUser: {
    isLoading: false,
    data: {},
    errors: [],
  },
  deactivatedUser: {
    isLoading: false,
    data: {},
    errors: [],
  },
  usersList: {
    isLoading: false,
    data: [],
    errors: [],
  },
  updatedbroker: {
    // isLoading: false,
    data: {},
    errors: [],
  },
  broker: {
    // isLoading: false,
    data: {},
    errors: [],
  },
  user: {
    isLoading: false,
    data: {},
    errors: [],
  },
  updatedUser: {
    isLoading: false,
    data: {},
    errors: [],
  },
  createdDestination: {
    isLoading: false,
    data: {},
    errors: [],
  },
  deactivatedDestination: {
    isLoading: false,
    data: {},
    errors: [],
  },
  destinationsList: {
    isLoading: false,
    data: [],
    errors: [],
  },
  hospitalsList: {
    isLoading: false,
    data: [],
    errors: [],
  },
  updatedDestination: {
    isLoading: false,
    data: {},
    errors: [],
  },
  destination: {
    isLoading: false,
    data: {},
    errors: [],
  },
  deactivatedTrip: {
    isLoading: false,
    errors: [],
    data: {},
  },
  createdTrip: {
    isLoading: false,
    data: {},
    errors: [],
  },
  trip: {
    isLoading: false,
    msg: '',
    data: {},
    errors: [],
  },
  // scheduledTripsList: [],
  tripsList: {
    success: 'pending',
    isLoading: false,
    data: [],
    errors: [],
  },
  tripsReport: {
    isLoading: false,
    data: [],
    errors: [],
  },
  updatedTrip: {
    isLoading: false,
    data: {},
    errors: [],
  },
  companiesList: {
    isLoading: false,
    data: [],
    errors: [],
  },
  deactivatedCompany: {
    isLoading: false,
    data: {},
    errors: [],
  },
  updatedCompany: {
    isLoading: false,
    data: {},
    errors: [],
  },
  company: {
    isLoading: false,
    data: {},
    errors: [],
  },
  companySettings: {
    isLoading: false,
    data: {},
    errors: [],
  },
  deletedCompany: {
    isLoading: false,
    data: {},
    errors: [],
  },
  couponsList: {
    loading: false,
    data: [],
    errors: [],
  },
  createdCoupon: {
    isLoading: false,
    data: {},
    errors: [],
  },
  deletedCoupon: {
    isLoading: false,
    data: {},
    errors: [],
  },
  updatedCoupon: {
    isLoading: false,
    data: {},
    errors: [],
  },
  coupon: {
    isLoading: false,
    data: {},
    errors: [],
  },
  transactionsList: {
    isLoading: false,
    data: [],
    errors: [],
  },
  deletedTransaction: {
    isLoading: false,
    data: {},
    errors: [],
  },
  agreementsList: {
    isLoading: false,
    data: [],
    errors: [],
  },
  updatedAgreement: {
    isLoading: false,
    data: {},
    errors: [],
  },
  cancelledAgreement: {
    isLoading: false,
    data: {},
    errors: [],
  },
  createdVehicle: {
    isLoading: false,
    data: {},
    errors: [],
  },
  deactivatedVehicle: {
    isLoading: false,
    data: {},
    errors: [],
  },
  vehiclesList: {
    isLoading: false,
    data: [],
    errors: [],
  },
  vehicle: {
    isLoading: false,
    data: {},
    errors: [],
  },
  updatedVehicle: {
    isLoading: false,
    data: {},
    errors: [],
  },
  inspectionsList: {
    isLoading: false,
    data: [],
    errors: [],
  },
  inspection: {
    isLoading: false,
    data: {},
    errors: [],
  },
  updatedInspection: {
    isLoading: false,
    data: {},
    errors: [],
  },
  signaturesList: {
    isLoading: false,
    data: [],
    errors: [],
  },
  signature: {
    isLoading: false,
    data: {},
    errors: [],
  },
  updatedSignature: {
    data: {},
    errors: [],
  },
  productsList: {
    isLoading: false,
    errors: [],
    data: [],
  },
  createdProduct: {
    isLoading: false,
    data: {},
    errors: [],
  },
  updatedProduct: {
    isLoading: false,
    data: {},
    errors: [],
  },
  productsLogs: {
    isLoading: false,
    data: [],
    errors: [],
  },
  selectedCustomerProducts: {
    isLoading: false,
    data: [],
    errors: [],
  },
  selectedVehicleProducts: {
    isLoading: false,
    data: [],
    errors: [],
  },
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // CREATE USER

    case CREATE_USER_RESET:
      return {
        ...state,
        createdUser: {
          success: false,
          isLoading: false,
          errors: [],
          data: {},
          msg: '',
        },
        deactivatedUser: {
          msg: '',
          data: {},
          success: false,
          isLoading: false,
          errors: [],
        },
      }

    case CREATE_USER_START:
      return {
        ...state,
        createdUser: {
          ...state.createdUser,
          success: false,
          isLoading: true,
          errors: [],
          msg: 'Creatring...',
        },
      }
    case CREATE_USER_SUCCESS:
      const foundIdx = state.usersList.data.findIndex((u) => u._id === action?.payload?.user?._id)
      const userData =
        foundIdx === -1 ? [...state.usersList.data, action.payload?.user] : state.usersList.data
      const newDestData =
        foundIdx === -1 && isCustomer(action.payload?.user)
          ? [
              ...state.destinationsList.data,
              { ...action.payload.user, name: getFullName({ user: action.payload?.user }) },
            ]
          : state.destinationsList.data
      return {
        ...state,
        createdUser: { ...action.payload.user, isLoading: false, success: true },
        usersList: {
          data: userData,
          isLoading: false,
          success: true,
        },
        destinationsList: {
          data: newDestData,
          isLoading: false,
          success: true,
        },
      }
    case CREATE_USER_FAIL:
      return {
        ...state,
        createdUser: { ...state.payload, isLoading: false, errors: action?.payload },
      }

    // GET COMPANY USERS

    case GET_USERS_START:
      return {
        ...state,
        usersList: { ...state.usersList, success: false, isLoading: true, errors: [] },
      }
    case GET_USERS_SUCCESS:
      const custsToDest = action?.payload?.length
        ? action.payload
            .filter((u) => isCustomer(u) && !u.isDeactivated)
            .map((c) => ({
              ...c,
              name: `${c.firstName} ${c.lastName}'s Home`,
            }))
        : []
      return {
        ...state,
        usersList: {
          data: uniqueById([
            ...state?.usersList?.data?.filter((u) => !u.isDeactivated),
            ...(action?.payload || []),
          ]),
          count: action?.count || state?.usersList?.count,
          // data: action?.payload,
          isLoading: false,
          success: true,
        },
        destinationsList: {
          ...state.destinationsList,
          data: uniqueById([...state?.destinationsList?.data, ...custsToDest]), // getRemainingItems(state?.destinationsList?.data, custsToDest),
        },
      }
    case GET_USERS_FAIL:
      return {
        ...state,
        usersList: { ...state.usersList, isLoading: false, errors: action?.payload },
      }

    // GET USER

    case GET_USER_START:
      return {
        ...state,
        user: {
          ...state.user,
          msg: '',
          data: {},
          success: false,
          isLoading: true,
          errors: [],
        },
      }
    case GET_USER_SUCCESS:
      return {
        ...state,
        user: {
          success: true,
          isLoading: false,
          data: action?.payload,
          errors: [],
        },
      }
    case GET_USER_FAIL:
      return {
        ...state,
        user: {
          ...state.user,
          isLoading: false,
          errors: action?.payload,
        },
      }

    // UPDATE USER

    case UPDATE_USER_RESET:
      return {
        ...state,
        updatedUser: {
          isLoading: false,
          msg: '',
          data: {},
          errors: [],
        },
        deactivatedUser: {
          msg: '',
          data: {},
          isLoading: false,
          errors: [],
        },
      }
    case UPDATE_USER_START:
      return {
        ...state,
        updatedUser: {
          ...state.updatedUser,
          msg: 'Updating...',
          data: {},
          success: false,
          isLoading: true,
          errors: [],
        },
      }
    case UPDATE_USER_SUCCESS:
      const { user: updatedU } = action?.payload
      const filteredUsers = state?.usersList?.data?.filter(
        (u) => u._id !== action?.payload?.user?._id,
      )
      const cToDest = {
        ...updatedU,
        name: `${updatedU.firstName} ${updatedU.lastName}'s Home`,
      }
      const filteredDsts = state?.destinationsList?.data?.filter((d) => d._id !== updatedU?._id)
      return {
        ...state,
        updatedUser: {
          ...action.payload,
          isLoading: false,
          success: true,
          errors: [],
        },
        usersList: {
          data: [...filteredUsers, updatedU],
          success: true,
          isLoading: false,
        },
        destinationsList: {
          ...state.destinationsList,
          data: [...filteredDsts, cToDest], // getRemainingItems(state?.destinationsList?.data, custsToDest),
        },
      }
    case UPDATE_USER_FAIL:
      return {
        ...state,
        updatedUser: {
          ...state.updatedUser,
          isLoading: false,
          errors: action?.payload,
        },
      }

    // DEACTIVATE COMPANY USER

    case DEACTIVATE_USER_START:
      return {
        ...state,
        deactivatedUser: {
          ...state.deactivatedUser,
          success: false,
          isLoading: true,
          errors: [],
          msg: 'Loading...',
        },
      }
    case DEACTIVATE_USER_SUCCESS:
      const activeUsers = state.usersList.data.filter((u) => u._id !== action?.payload?.user?._id)
      return {
        ...state,
        deactivatedUser: {
          ...action.payload,
          success: true,
          isLoading: false,
          errors: [],
        },
        usersList: {
          data: [...activeUsers, action.payload.user],
          success: true,
          isLoading: false,
        },
      }
    case DEACTIVATE_USER_FAIL:
      return {
        ...state,
        deactivatedUser: { ...state.payload, isLoading: false, errors: action?.payload, msg: '' },
      }

    // CREATE AREAS

    // case CREATE_AREA_RESET:
    //   return {
    //     ...state,
    //     createdArea: {
    //       isLoading: false,
    //       errors: [],
    //       data: {},
    //       msg: '',
    //     },
    //     deactivatedArea: {
    //       msg: '',
    //       data: {},
    //       isLoading: false,
    //       errors: [],
    //     },
    //   }

    // case CREATE_AREA_START:
    //   return {
    //     ...state,
    //     createdArea: {
    //       ...state.createdArea,
    //       msg: 'Creatring...',
    //       data: {},
    //       success: false, isLoading: true,
    //       errors: [],
    //     },
    //   }
    // case CREATE_AREA_SUCCESS:
    //   return {
    //     ...state,
    //     createdArea: {
    //       success: true,
    //       isLoading: false,
    //       data: action?.payload?.user,
    //       msg: action?.payload?.msg,
    //       errors: [],
    //     },
    //   }
    // case CREATE_AREA_FAIL:
    //   return {
    //     ...state,
    //     createdArea: {
    //       ...state.createdArea,
    //       isLoading: false,
    //       errors: action?.payload,
    //     },
    //   }

    // GET AREA

    // case GET_AREA_START:
    //   return {
    //     ...state,
    //     area: {
    //       ...state.area,
    //       msg: '',
    //       data: {},
    //       success: false, isLoading: true,
    //       errors: [],
    //     },
    //   }
    // case GET_AREA_SUCCESS:
    //   return {
    //     ...state,
    //     area: {
    //       success: true,
    //       isLoading: false,
    //       data: action?.payload,
    //       errors: [],
    //     },
    //   }
    // case GET_AREA_FAIL:
    //   return {
    //     ...state,
    //     area: {
    //       ...state.area,
    //       isLoading: false,
    //       errors: action?.payload,
    //     },
    //   }

    // GET AREAS

    // case GET_AREAS_START:
    //   return {
    //     ...state,
    //     areasList: {
    //       ...state.areasList,
    //       msg: '',
    //       data: [],
    //       success: false, isLoading: true,
    //       errors: [],
    //     },
    //   }
    // case GET_AREAS_SUCCESS:
    //   return {
    //     ...state,
    //     areasList: {
    //       success: true,
    //       isLoading: false,
    //       data: action?.payload,
    //       errors: [],
    //     },
    //   }
    // case GET_AREAS_FAIL:
    //   return {
    //     ...state,
    //     areasList: {
    //       ...state.areasList,
    //       isLoading: false,
    //       errors: action?.payload,
    //     },
    //   }

    // UPDATE AREA

    // case UPDATE_AREA_RESET:
    //   return {
    //     ...state,
    //     updatedArea: {
    //       msg: '',
    //       data: {},
    //       success: false, isLoading: true,
    //       errors: [],
    //     },
    //     deactivatedArea: {
    //       msg: '',
    //       data: {},
    //       isLoading: false,
    //       errors: [],
    //     },
    //   }

    // case UPDATE_AREA_START:
    //   return {
    //     ...state,
    //     updatedArea: {
    //       ...state.updatedArea,
    //       msg: 'Updating! Please wait...',
    //       data: {},
    //       success: false, isLoading: true,
    //       errors: [],
    //     },
    //   }
    // case UPDATE_AREA_SUCCESS:
    //   return {
    //     ...state,
    //     updatedArea: {
    //       success: true,
    //       isLoading: false,
    //       data: action?.payload,
    //       msg: action?.payload?.msg,
    //       errors: [],
    //     },
    //   }
    // case UPDATE_AREA_FAIL:
    //   return {
    //     ...state,
    //     updatedArea: {
    //       ...state.updatedArea,
    //       isLoading: false,
    //       errors: action?.payload,
    //     },
    //   }

    // DEACTIVATE COMPANY AREA

    // case DEACTIVATE_AREA_START:
    //   return {
    //     ...state,
    //     deactivatedArea: {
    //       ...state.deactivatedArea,
    //       success: false, isLoading: true,
    //       errors: [],
    //       msg: 'Deactivating...',
    //     },
    //   }
    // case DEACTIVATE_AREA_SUCCESS:
    //   return {
    //     ...state,
    //     deactivatedArea: { ...action.payload, success: true, isLoading: false },
    //   }
    // case DEACTIVATE_AREA_FAIL:
    //   return {
    //     ...state,
    //     deactivatedArea: { ...state.payload, isLoading: false, errors: action?.payload },
    //   }

    // GET HOSPITALS DESTINATIONS

    // case GET_HOSPITALS_DESTINATIONS_START:
    //   return {
    //     ...state,
    //     hospitalsList: {
    //       msg: 'Loading! Please wait...',
    //       data: [],
    //       success: false, isLoading: true,
    //       errors: [],
    //     },
    //   }
    // case GET_HOSPITALS_DESTINATIONS_SUCCESS:
    //   return {
    //     ...state,
    //     hospitalsList: {
    //       success: true,
    //       isLoading: false,
    //       data: action?.payload,
    //       errors: [],
    //     },
    //   }
    // case GET_HOSPITALS_DESTINATIONS_FAIL:
    //   return {
    //     ...state,
    //     hospitalsList: {
    //       ...state.hospitalsList,
    //       isLoading: false,
    //       errors: action?.payload,
    //     },
    //   }

    case GET_DESTINATION_START:
      return {
        ...state,
        destination: {
          ...state.destination,
          msg: 'Loading destinations ...',
          data: {},
          success: false,
          isLoading: true,
          errors: [],
        },
      }
    case GET_DESTINATION_SUCCESS:
      return {
        ...state,
        destination: {
          success: true,
          isLoading: false,
          data: action?.payload,
          errors: [],
        },
      }
    case GET_DESTINATION_FAIL:
      return {
        ...state,
        destination: {
          ...state.destination,
          isLoading: false,
          errors: action?.payload,
        },
      }

    // GET DESTINATIONS

    case GET_DESTINATIONS_START:
      return {
        ...state,
        destinationsList: {
          ...state.destinationsList,
          msg: 'Loading! Please wait...',
          success: false,
          isLoading: true,
          errors: [],
        },
      }
    case GET_DESTINATIONS_SUCCESS:
      const custsToDestin = state?.usersList?.data?.length
        ? state.usersList.data
            .filter((u) => isCustomer(u) && !u.isDeactivated)
            .map((c) => ({
              ...c,
              name: `${c.firstName} ${c.lastName}'s Home`,
            }))
        : []
      const destToCust = [action?.payload || []]?.filter((d) => d?.level)
      return {
        ...state,
        destinationsList: {
          ...state.destinationsList,
          // data: [...state?.destinationsList?.data, ...action?.payload],
          // data: uniqueById([...state?.destinationsList?.data, ...action?.payload]),
          data: uniqueById([
            ...(custsToDestin || []),
            ...(state?.destinationsList?.data || []),
            // ...state?.destinationsList?.data?.filter((u) => !u.isDeactivated),
            ...(action?.payload || []),
          ]),
          // data: [...custsToDestin, ...action?.payload],
          count: action?.count || state?.destinationsList?.count,
          success: true,
          isLoading: false,
        },
        usersList: {
          ...state.usersList,
          data: uniqueById([...state.usersList?.data, ...destToCust]),
        },
      }
    case GET_DESTINATIONS_FAIL:
      return {
        ...state,
        destinationsList: {
          ...state.destinationsList,
          isLoading: false,
          errors: action?.payload,
        },
      }

    // CREATE DESTINATION

    case CREATE_DESTINATIONS_RESET:
      return {
        ...state,
        createdDestination: {
          isLoading: false,
          errors: [],
          data: {},
          msg: '',
        },
      }
    case CREATE_DESTINATIONS_START:
      return {
        ...state,
        createdDestination: {
          ...state.createdDestination,
          msg: 'Creating ...',
          success: false,
          isLoading: true,
          errors: [],
        },
      }
    case CREATE_DESTINATIONS_SUCCESS:
      const destList = [...state.destinationsList.data] //slice()
      const foundDestIdx = destList.findIndex((d) => d?._id === action?.payload?.destination?._id)
      const destData = foundDestIdx === -1 ? [...destList, action.payload?.destination] : destList
      return {
        ...state,
        createdDestination: {
          ...action.payload,
          success: true,
          isLoading: false,
        },
        destinationsList: {
          data: destData,
          success: true,
          isLoading: false,
        },
      }
    case CREATE_DESTINATIONS_FAIL:
      return {
        ...state,
        createdDestination: {
          ...state.createdDestination,
          isLoading: false,
          errors: action?.payload,
        },
      }

    // UPDATE DESTINATION

    case UPDATE_DESTINATION_RESET:
      return {
        ...state,
        updatedDestination: {
          msg: '',
          data: {},
          success: false,
          isLoading: true,
          errors: [],
        },
        deactivatedDestination: {
          msg: '',
          data: {},
          isLoading: false,
          errors: [],
        },
      }
    case UPDATE_DESTINATION_START:
      return {
        ...state,
        updatedDestination: {
          msg: 'Updating! Please wait ...',
          data: {},
          success: false,
          isLoading: true,
          errors: [],
        },
      }
    case UPDATE_DESTINATION_SUCCESS:
      const filteredDests = state.destinationsList.data.filter(
        (d) => d._id !== action?.payload?.destination?._id,
      )
      return {
        ...state,
        updatedDestination: {
          ...action?.payload,
          success: true,
          isLoading: false,
        },
        destinationsList: {
          data: [...filteredDests, action?.payload?.destination],
          success: true,
          isLoading: false,
        },
      }
    case UPDATE_DESTINATION_FAIL:
      return {
        ...state,
        updatedDestination: {
          ...state.updatedDestination,
          isLoading: false,
          errors: action?.payload,
        },
      }

    // DEACTIVATE COMPANY DESTINATION

    case DEACTIVATE_DESTINATION_START:
      return {
        ...state,
        deactivatedDestination: {
          ...state.deactivatedDestination,
          success: false,
          isLoading: true,
          errors: [],
          msg: 'Deactivating destination...',
        },
      }
    case DEACTIVATE_DESTINATION_SUCCESS:
      const activeDests = state.destinationsList.data.filter(
        (d) => d._id !== action?.payload?.destination?._id,
      )
      return {
        ...state,
        deactivatedDestination: {
          ...action.payload,
          success: true,
          isLoading: false,
        },
        destinationsList: {
          data: [...activeDests, action.payload.destination],
          success: true,
          isLoading: false,
        },
      }
    case DEACTIVATE_DESTINATION_FAIL:
      return {
        ...state,
        deactivatedDestination: { ...state.payload, isLoading: false, errors: action?.payload },
      }

    // DELETE DESTINATION

    case DELETE_DESTINATION_START:
      return {
        ...state,
        deletedDestination: {
          msg: 'Deleting! Please wait...',
          success: false,
          isLoading: true,
          errors: [],
        },
      }
    case DELETE_DESTINATION_SUCCESS:
      const remainingDests = state.destinationsList.data.filter(
        (d) => d._id !== action?.payload?.destination?._id,
      )
      return {
        ...state,
        deletedDestination: {
          ...action?.payload,
          success: true,
          isLoading: false,
        },
        destinationsList: {
          data: remainingDests,
          success: true,
          isLoading: false,
        },
      }
    case DELETE_DESTINATION_FAIL:
      return {
        ...state,
        deletedDestination: {
          ...state.deletedDestination,
          isLoading: false,
          errors: action?.payload,
        },
      }

    // CREATE TRIP

    case GET_TRIPS_START:
      return {
        ...state,
        // tripsList: { ...state.tripsList, isLoading: true, errors: [] },
        tripsList: {
          ...state.tripsList,
          data: state.tripsList?.data || action?.payload,
          msg: 'Getting Trips...',
          success: false,
          isLoading: true,
          errors: [],
        },
      }
    case GET_TRIPS_SUCCESS:
      return {
        ...state,
        tripsList: {
          success: true,
          isLoading: false,
          data: uniqueByCustomerAndPickup(uniqueById(action?.payload)),
          errors: [],
        },
        // scheduledTripsList: action.scheduledTrips,
      }
    case GET_TRIPS_FAIL:
      return {
        ...state,
        tripsList: {
          ...state.tripsList,
          isLoading: false,
          errors: action?.payload,
        },
      }

    // CREATE TRIP

    case CREATE_TRIP_RESET:
      return {
        ...state,
        createdTrip: {
          isLoading: false,
          msg: '',
          data: {},
          errors: [],
        },
        deactivatedTrip: {
          msg: '',
          data: {},
          isLoading: false,
          errors: [],
        },
      }
    case CREATE_TRIP_START:
      return {
        ...state,
        createdTrip: {
          ...state.createdTrip,
          msg: 'Creating Trip ...',
          data: {},
          success: false,
          isLoading: true,
          errors: [],
        },
      }
    case CREATE_TRIP_SUCCESS:
      const { trip: createdT } = action?.payload || {}
      // const foundTripIdx = state.tripsList.data.findIndex((t) => t?._id === createdT?._id)
      const tData = state.tripsList?.data?.filter((t) => t?._id !== createdT?._id) // foundTripIdx === -1 ? [...state.tripsList.data, createdT] : state.tripsList.data
      return {
        ...state,
        createdTrip: {
          ...action.payload,
          success: true,
          isLoading: false,
        },
        tripsList: {
          data: uniqueByCustomerAndPickup([...tData, createdT]),
          success: true,
          isLoading: false,
        },
      }
    case CREATE_TRIP_FAIL:
      return {
        ...state,
        createdTrip: { ...state.payload, isLoading: false, errors: action?.payload },
      }

    // UPDATE TRIP

    case UPDATE_TRIP_RESET:
      return {
        ...state,
        updatedTrip: {
          msg: '',
          data: {},
          isLoading: false,
          errors: [],
        },
        deactivatedTrip: {
          msg: '',
          data: {},
          isLoading: false,
          errors: [],
        },
      }

    case UPDATE_TRIP_START:
      return {
        ...state,
        updatedTrip: {
          ...state.trip,
          msg: 'Updating! Please wait ...',
          data: {},
          success: false,
          isLoading: true,
          errors: [],
        },
      }
    case UPDATE_TRIP_SUCCESS:
      const { trip } = action?.payload || {}
      const filteredTrips = state.tripsList?.data?.filter(
        (t) => t?._id !== trip?._id,
        // &&
        // !(
        //   t?.customer === trip?.customer &&
        //   t?.times?.expectedPickupTime1 === trip?.times?.expectedPickupTime1
        // ),
      )
      return {
        ...state,
        updatedTrip: {
          ...action?.payload,
          success: true,
          isLoading: false,
        },
        tripsList: {
          data: uniqueByCustomerAndPickup([...filteredTrips, trip]),
          success: true,
          isLoading: false,
        },
      }
    case UPDATE_TRIP_FAIL:
      return {
        ...state,
        updatedTrip: {
          ...state.trip,
          isLoading: false,
          errors: action?.payload,
        },
      }

    // DEACTIVATE TRIP

    case DEACTIVATE_TRIP_START:
      return {
        ...state,
        deactivatedTrip: {
          ...state.deactivatedTrip,
          success: false,
          isLoading: true,
          errors: [],
          msg: 'Deactivating...',
        },
      }
    case DEACTIVATE_TRIP_SUCCESS:
      return {
        ...state,
        deactivatedTrip: { ...action.payload, success: true, isLoading: false },
      }
    case DEACTIVATE_TRIP_FAIL:
      return {
        ...state,
        deactivatedTrip: { ...state.payload, isLoading: false, errors: action?.payload },
      }

    case DELETE_TRIP_SUCCESS:
      // const newTrips = [...state.tripsList.data] //copy arr
      // const foundIndex = newTrips.findIndex((t) => t?.schescheduledTripId === action.payload._id)
      // if (foundIndex > -1) {
      //   newTrips.splice(foundIndex, 1)
      // }
      const remainigTripsDeleted = state.tripsList?.data?.filter(
        (t) => t?._id !== action?.payload?._id,
      )
      return {
        ...state,
        tripsList: {
          ...state.tripsList,
          data: remainigTripsDeleted,
        },
      }

    // DELETE SCHEDULED TRIP

    case DELETE_SCHEDULED_TRIP_START:
      return {
        ...state,
        deletedScheduledTrip: {
          ...state.deletedScheduledTrip,
          success: false,
          isLoading: true,
          errors: [],
          msg: 'Deleting...',
        },
      }
    case DELETE_SCHEDULED_TRIP_SUCCESS:
      // const newTrips = [...state.tripsList.data] //copy arr
      // const foundIndex = newTrips.findIndex((t) => t?.schescheduledTripId === action.payload._id)
      // if (foundIndex > -1) {
      //   newTrips.splice(foundIndex, 1)
      // }
      const remainigTripsDel = state.tripsList?.data?.filter(
        (t) => t?._id !== action?.payload?.scheduleId,
      )
      return {
        ...state,
        deletedScheduledTrip: { ...action.payload, success: true, isLoading: false },
        tripsList: {
          ...state.tripsList,
          data: remainigTripsDel,
        },
      }

    case DELETE_SCHEDULED_TRIP_FAIL:
      return {
        ...state,
        deletedScheduledTrip: { ...state.payload, isLoading: false, errors: action?.payload },
      }

    // GET TRIP

    case GET_TRIP_START:
      return {
        ...state,
        trip: {
          ...state.trip,
          msg: 'Loading! Please wait...',
          success: false,
          isLoading: true,
          data: {},
          errors: [],
        },
      }
    case GET_TRIP_SUCCESS:
      return {
        ...state,
        trip: {
          success: true,
          isLoading: false,
          data: action?.payload,
          errors: [],
        },
      }
    case GET_TRIP_FAIL:
      return {
        ...state,
        trip: {
          ...state.trip,
          isLoading: false,
          errors: action?.payload,
        },
      }

    // GET ALL COMPANIES

    case GET_ALL_COMPANIES_START:
      return {
        ...state,
        companiesList: { ...state.companiesList, success: false, isLoading: true, errors: [] },
      }
    case GET_ALL_COMPANIES_SUCCESS:
      return {
        ...state,
        companiesList: { data: [...action.payload], success: true, isLoading: false },
      }
    case GET_ALL_COMPANIES_FAIL:
      return {
        ...state,
        companiesList: { ...state.companiesList, isLoading: false, errors: action?.payload },
      }

    // UPDATE COMPANY

    case UPDATE_COMPANY_RESET:
      return {
        ...state,
        updatedCompany: {
          msg: '',
          data: {},
          isLoading: false,
          errors: [],
        },
        deactivatedCompany: {
          msg: '',
          data: {},
          isLoading: false,
          errors: [],
        },
      }

    case UPDATE_COMPANY_START:
      return {
        ...state,
        updatedCompany: {
          ...state.company,
          msg: 'Updating! Please wait ...',
          data: {},
          success: false,
          isLoading: true,
          errors: [],
        },
      }
    case UPDATE_COMPANY_SUCCESS:
      const restComp = state.companiesList?.data?.filter((c) => c?._id !== action?.payload?._id)
      return {
        ...state,
        updatedCompany: {
          success: true,
          isLoading: false,
          data: action?.payload,
          msg: action?.payload?.msg,
          errors: [],
        },
        companiesList: {
          ...state.companiesList,
          data: [...restComp, action?.payload],
        },
      }
    case UPDATE_COMPANY_FAIL:
      return {
        ...state,
        updatedCompany: {
          ...state.company,
          isLoading: false,
          errors: action?.payload,
        },
      }

    // GET COMPANY

    case GET_COMPANY_START:
      return {
        ...state,
        company: {
          // ...state.company,
          msg: '',
          data: {},
          success: false,
          isLoading: true,
          errors: [],
        },
      }
    case GET_COMPANY_SUCCESS:
      const restCompnies = state.companiesList?.data?.filter((c) => c?._id !== action?.payload?._id)
      return {
        ...state,
        company: {
          success: true,
          isLoading: false,
          data: action?.payload,
          errors: [],
        },
        companiesList: {
          ...state.companiesList,
          data: [...restCompnies, action?.payload],
        },
      }
    case GET_COMPANY_FAIL:
      return {
        ...state,
        company: {
          ...state.company,
          isLoading: false,
          errors: action?.payload,
        },
      }

    // GET COMPANY SETTINGS

    case GET_COMPANY_SETTINGS_START:
      return {
        ...state,
        companySettings: {
          // ...state.companySettings,
          msg: '',
          data: {},
          success: false,
          isLoading: true,
          errors: [],
        },
      }
    case GET_COMPANY_SETTINGS_SUCCESS:
      const stateSettings = state?.companySettings?.data || {}
      const newSettings = action?.payload?.companySettings || {}
      return {
        ...state,
        companySettings: {
          success: true,
          isLoading: false,
          data: { ...stateSettings, ...newSettings },
          errors: [],
        },
      }
    case GET_COMPANY_SETTINGS_FAIL:
      return {
        ...state,
        companySettings: {
          ...state.companySettings,
          isLoading: false,
          errors: action?.payload,
        },
      }

    // DEACTIVATE COMPANY USER

    case DEACTIVATE_COMPANY_START:
      return {
        ...state,
        deactivatedCompany: {
          ...state.deactivatedCompany,
          success: false,
          isLoading: true,
          errors: [],
          msg: 'Deactivating...',
        },
      }
    case DEACTIVATE_COMPANY_SUCCESS:
      return {
        ...state,
        deactivatedCompany: { ...action.payload, success: true, isLoading: false },
      }
    case DEACTIVATE_COMPANY_FAIL:
      return {
        ...state,
        deactivatedCompany: { ...state.payload, isLoading: false, errors: action?.payload },
      }

    // CREATE COMPANY

    case CREATE_COMPANY_RESET:
      return {
        ...state,
        createdCompany: {
          isLoading: false,
          errors: [],
          data: {},
          msg: '',
        },
      }
    case CREATE_COMPANY_START:
      return {
        ...state,
        createdCompany: {
          ...state.createdCompany,
          msg: 'Creating! Please wait...',
          data: {},
          success: false,
          isLoading: true,
          errors: [],
        },
      }
    case CREATE_COMPANY_SUCCESS:
      return {
        ...state,
        createdCompany: {
          success: true,
          isLoading: false,
          data: action?.payload,
          msg: action?.payload?.msg,
          errors: [],
        },
      }
    case CREATE_COMPANY_FAIL:
      return {
        ...state,
        createdCompany: {
          ...state.createdCompany,
          isLoading: false,
          errors: action?.payload,
        },
      }

    // DELETE COMPANY

    case DELETE_COMPANY_START:
      return {
        ...state,
        deletedCompany: {
          msg: 'Deleting! Please wait...',
          success: false,
          isLoading: true,
          errors: [],
        },
      }
    case DELETE_COMPANY_SUCCESS:
      return {
        ...state,
        deletedCompany: {
          success: true,
          isLoading: false,
          msg: action?.payload?.msg,
          errors: [],
        },
      }
    case DELETE_COMPANY_FAIL:
      return {
        ...state,
        deletedCompany: {
          ...state.deletedCompany,
          isLoading: false,
          errors: action?.payload,
        },
      }

    // CREATE COUPON

    case CREATE_COUPON_CLEAN:
      return {
        ...state,
        createdCoupon: {
          isLoading: false,
          errors: [],
          data: {},
          msg: '',
        },
      }
    case CREATE_COUPON_START:
      return {
        ...state,
        createdCoupon: {
          ...state.createdCoupon,
          msg: 'Creating! Please wait...',
          data: {},
          success: false,
          isLoading: true,
          errors: [],
        },
      }
    case CREATE_COUPON_SUCCESS:
      return {
        ...state,
        createdCoupon: {
          success: true,
          isLoading: false,
          data: action?.payload,
          msg: action?.payload?.msg,
          errors: [],
        },
      }
    case CREATE_COUPON_FAIL:
      return {
        ...state,
        createdCoupon: {
          ...state.createdCoupon,
          isLoading: false,
          errors: action?.payload,
        },
      }

    // GET ALL COUPONS

    case GET_COUPONS_START:
      return {
        ...state,
        couponsList: { ...state.couponsList, success: false, isLoading: true, errors: [] },
      }
    case GET_COUPONS_SUCCESS:
      return {
        ...state,
        couponsList: { data: action.payload, success: true, isLoading: false },
      }
    case GET_COUPONS_FAIL:
      return {
        ...state,
        couponsList: { ...state.couponsList, isLoading: false, errors: action?.payload },
      }

    // DELETE COUPONS

    case DELETE_COUPON_START:
      return {
        ...state,
        deletedCoupon: {
          msg: 'Deleting! Please wait...',
          success: false,
          isLoading: true,
          errors: [],
        },
      }
    case DELETE_COUPON_SUCCESS:
      return {
        ...state,
        deletedCoupon: {
          success: true,
          isLoading: false,
          msg: action?.payload?.msg,
          errors: [],
        },
      }
    case DELETE_COUPON_FAIL:
      return {
        ...state,
        deletedCoupon: {
          ...state.deletedCoupon,
          isLoading: false,
          errors: action?.payload,
        },
      }

    // GET COUPON

    case GET_COUPON_START:
      return {
        ...state,
        coupon: {
          // ...state.coupon,
          msg: '',
          data: {},
          success: false,
          isLoading: true,
          errors: [],
        },
      }
    case GET_COUPON_SUCCESS:
      return {
        ...state,
        coupon: {
          success: true,
          isLoading: false,
          data: action?.payload,
          errors: [],
        },
      }
    case GET_COUPON_FAIL:
      return {
        ...state,
        coupon: {
          ...state.coupon,
          isLoading: false,
          errors: action?.payload,
        },
      }

    // UPDATE COUPON

    case UPDATE_COUPON_RESET:
      return {
        ...state,
        updatedCoupon: {
          msg: '',
          data: {},
          isLoading: false,
          errors: [],
        },
        deactivatedCoupon: {
          msg: '',
          data: {},
          isLoading: false,
          errors: [],
        },
      }

    case UPDATE_COUPON_START:
      return {
        ...state,
        updatedCoupon: {
          ...state.coupon,
          msg: 'Updating! Please wait ...',
          data: {},
          success: false,
          isLoading: true,
          errors: [],
        },
      }
    case UPDATE_COUPON_SUCCESS:
      return {
        ...state,
        updatedCoupon: {
          success: true,
          isLoading: false,
          data: action?.payload,
          msg: action?.payload?.msg,
          errors: [],
        },
      }
    case UPDATE_COUPON_FAIL:
      return {
        ...state,
        updatedCoupon: {
          ...state.coupon,
          isLoading: false,
          errors: action?.payload,
        },
      }

    // GET ALL TRANSACTIONS

    case GET_TRANSACTIONS_START:
      return {
        ...state,
        transactionsList: {
          ...state.transactionsList,
          success: false,
          isLoading: true,
          errors: [],
        },
      }
    case GET_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactionsList: { data: [...action.payload], success: true, isLoading: false },
      }
    case GET_TRANSACTIONS_FAIL:
      return {
        ...state,
        transactionsList: { ...state.transactionsList, isLoading: false, errors: action?.payload },
      }

    // DELETE TRANSACTION

    case DELETE_TRANSACTION_START:
      return {
        ...state,
        deletedTransaction: {
          msg: 'Deleting! Please wait...',
          success: false,
          isLoading: true,
          errors: [],
        },
      }
    case DELETE_TRANSACTION_SUCCESS:
      return {
        ...state,
        deletedTransaction: {
          success: true,
          isLoading: false,
          msg: action?.payload?.msg,
          errors: [],
        },
      }
    case DELETE_TRANSACTION_FAIL:
      return {
        ...state,
        deletedTransaction: {
          ...state.deletedTransaction,
          isLoading: false,
          errors: action?.payload,
        },
      }

    case GET_BILLING_AGREEMENT_START:
      return {
        ...state,
        agreementsList: {
          ...state.agreementsList,
          data: [],
          success: false,
          isLoading: true,
          errors: [],
        },
      }
    case GET_BILLING_AGREEMENT_SUCCESS:
      return {
        ...state,
        agreementsList: { data: [...action.payload], success: true, isLoading: false },
      }
    case GET_BILLING_AGREEMENT_FAIL:
      return {
        ...state,
        agreementsList: {
          ...state.agreementsList,
          success: false,
          isLoading: false,
          errors: action?.payload,
        },
      }

    // CANCEL BILLING AGREEMENT

    case CANCEL_BILLING_AGREEMENT_START:
      return {
        ...state,
        cancelledAgreement: {
          ...state.cancelledAgreement,
          msg: '',
          data: {},
          success: false,
          isLoading: true,
          errors: [],
        },
      }
    case CANCEL_BILLING_AGREEMENT_SUCCESS:
      return {
        ...state,
        cancelledAgreement: {
          success: true,
          isLoading: false,
          data: action?.payload,
          errors: [],
        },
      }
    case CANCEL_BILLING_AGREEMENT_FAIL:
      return {
        ...state,
        cancelledAgreement: {
          ...state.cancelledAgreement,
          isLoading: false,
          errors: action?.payload,
        },
      }

    case UPDATE_BILLING_AGREEMENT_START:
      return {
        ...state,
        updatedAgreement: {
          ...state.agreement,
          msg: 'Updating! Please wait ...',
          data: {},
          success: false,
          isLoading: true,
          errors: [],
        },
      }
    case UPDATE_BILLING_AGREEMENT_SUCCESS:
      return {
        ...state,
        updatedAgreement: {
          success: true,
          isLoading: false,
          data: action?.payload,
          msg: action?.payload?.msg,
          errors: [],
        },
      }
    case UPDATE_BILLING_AGREEMENT_FAIL:
      return {
        ...state,
        updatedAgreement: {
          ...state.agreement,
          isLoading: false,
          errors: action?.payload,
        },
      }

    // case UPDATE_AGREEMENT_PRICING_START:
    //   return {
    //     ...state,
    //     updatedAgreement: {
    //       ...state.agreement,
    //       msg: 'Updating! Please wait ...',
    //       data: {},
    //       success: false, isLoading: true,
    //       errors: [],
    //     },
    //   }
    // case UPDATE_AGREEMENT_PRICING_SUCCESS:
    //   return {
    //     ...state,
    //     updatedAgreement: {
    //       success: true,
    //       isLoading: false,
    //       data: action?.payload,
    //       msg: action?.payload?.msg,
    //       errors: [],
    //     },
    //   }
    // case UPDATE_AGREEMENT_PRICING_FAIL:
    //   return {
    //     ...state,
    //     updatedAgreement: {
    //       ...state.agreement,
    //       isLoading: false,
    //       errors: action?.payload,
    //     },
    //   }

    // GET VEHICLE

    case GET_VEHICLE_START:
      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          msg: '',
          data: {},
          success: false,
          isLoading: true,
          errors: [],
        },
      }
    case GET_VEHICLE_SUCCESS:
      return {
        ...state,
        vehicle: {
          success: true,
          isLoading: false,
          data: action?.payload,
          errors: [],
        },
      }
    case GET_VEHICLE_FAIL:
      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          isLoading: false,
          errors: action?.payload,
        },
      }

    // GET VEHICLES

    case GET_VEHICLES_RESET:
      return {
        ...state,
        vehiclesList: {
          ...state.vehiclesList,
          msg: '',
          data: [],
          success: false,
          isLoading: true,
          errors: [],
        },
      }
    case GET_VEHICLES_START:
      return {
        ...state,
        vehiclesList: {
          ...state.vehiclesList,
          msg: '',
          data: state.vehiclesList?.data,
          success: false,
          isLoading: true,
          errors: [],
        },
      }
    case GET_VEHICLES_SUCCESS:
      const vehiclesFromDB = action?.payload?.length ? action?.payload : []
      const remainingV = state?.vehiclesList?.data
        ?.filter((v) => !v.isDeactivated)
        ?.filter((v) => vehiclesFromDB.every((vp) => vp?._id !== v?._id))
      return {
        ...state,
        vehiclesList: {
          success: true,
          isLoading: false,
          data: [...remainingV, ...vehiclesFromDB],
          errors: [],
        },
      }
    case GET_VEHICLES_FAIL:
      return {
        ...state,
        vehiclesList: {
          ...state.vehiclesList,
          isLoading: false,
          errors: action?.payload,
        },
      }

    // CREATE VEHICLES

    case CREATE_VEHICLE_RESET:
      return {
        ...state,
        createdVehicle: {
          isLoading: false,
          errors: [],
          data: {},
          msg: '',
        },
        deactivatedVehicle: {
          msg: '',
          data: {},
          isLoading: false,
          errors: [],
        },
      }
    case CREATE_VEHICLE_START:
      return {
        ...state,
        createdVehicle: {
          ...state.createdVehicle,
          msg: 'Creating...',
          data: {},
          success: false,
          isLoading: true,
          errors: [],
        },
      }
    case CREATE_VEHICLE_SUCCESS:
      const vList = [...state.vehiclesList.data] //slice()
      const foundVehicleIdx = vList.findIndex((d) => d?._id === action?.payload?.vehicle?._id)
      const vData = foundVehicleIdx === -1 ? [...vList, action.payload?.vehicle] : vList
      return {
        ...state,
        createdVehicle: {
          ...action?.payload,
          success: true,
          isLoading: false,
        },
        vehiclesList: {
          data: vData,
          success: true,
          isLoading: false,
        },
      }
    case CREATE_VEHICLE_FAIL:
      return {
        ...state,
        createdVehicle: {
          ...state.createdVehicle,
          isLoading: false,
          errors: action?.payload,
        },
      }

    // UPDATE VEHICLE

    case UPDATE_VEHICLE_RESET:
      return {
        ...state,
        updatedVehicle: {
          msg: '',
          data: {},
          success: false,
          isLoading: false,
          errors: [],
        },
        deactivatedVehicle: {
          msg: '',
          data: {},
          isLoading: false,
          errors: [],
        },
      }
    case UPDATE_VEHICLE_START:
      return {
        ...state,
        updatedVehicle: {
          ...state.updatedVehicle,
          msg: 'Updating! Please wait...',
          data: {},
          success: false,
          isLoading: true,
          errors: [],
        },
      }
    case UPDATE_VEHICLE_SUCCESS:
      const remVehicles = state?.vehiclesList?.data?.filter(
        (v) => v._id !== action?.payload?.vehicle?._id,
      )
      return {
        ...state,
        updatedVehicle: {
          success: true,
          isLoading: false,
          data: action?.payload,
          msg: action?.payload?.msg,
          errors: [],
        },
        vehiclesList: {
          success: true,
          isLoading: false,
          data: [...remVehicles, action?.payload?.vehicle],
        },
      }
    case UPDATE_VEHICLE_FAIL:
      return {
        ...state,
        updatedVehicle: {
          ...state.updatedVehicle,
          isLoading: false,
          errors: action?.payload,
        },
      }

    // DEACTIVATE COMPANY VEHICLE

    case DEACTIVATE_VEHICLE_START:
      return {
        ...state,
        deactivatedVehicle: {
          ...state.deactivatedVehicle,
          success: false,
          isLoading: true,
          errors: [],
          msg: 'Deactivating...',
        },
      }
    case DEACTIVATE_VEHICLE_SUCCESS:
      const activeVehicles = state.vehiclesList.data.filter(
        (v) => v._id !== action?.payload?.vehicle?._id,
      )
      return {
        ...state,
        deactivatedVehicle: { ...action.payload, success: true, isLoading: false },
        vehiclesList: {
          data: [...activeVehicles, action?.payload.vehicle],
          success: true,
          isLoading: false,
        },
      }
    case DEACTIVATE_VEHICLE_FAIL:
      return {
        ...state,
        deactivatedVehicle: { ...state.payload, isLoading: false, errors: action?.payload },
      }

    // DELETE VEHICLE

    case DELETE_VEHICLE_START:
      return {
        ...state,
        deletedVehicle: {
          msg: 'Deleting! Please wait...',
          success: false,
          isLoading: true,
          errors: [],
        },
      }
    case DELETE_VEHICLE_SUCCESS:
      const remainingVehicles = state.vehiclesList.data.filter(
        (v) => v._id !== action?.payload?.vehicle?._id,
      )
      return {
        ...state,
        deletedVehicle: {
          success: true,
          isLoading: false,
          msg: action?.payload?.msg,
          vehicle: action?.payload?.vehicle,
          errors: [],
        },
        vehiclesList: {
          data: remainingVehicles,
        },
      }
    case DELETE_VEHICLE_FAIL:
      return {
        ...state,
        deletedVehicle: {
          ...state.deletedVehicle,
          isLoading: false,
          errors: action?.payload,
        },
      }

    // GET INSPECTION

    case GET_INSPECTION_START:
      return {
        ...state,
        inspection: {
          ...state.inspection,
          msg: '',
          data: {},
          success: false,
          isLoading: true,
          errors: [],
        },
      }
    case GET_INSPECTION_SUCCESS:
      return {
        ...state,
        inspection: {
          success: true,
          isLoading: false,
          data: action?.payload,
          errors: [],
        },
        inspectionsList: {
          success: true,
          isLoading: false,
          data: !isEmpty(action?.payload)
            ? uniqueById([...state.inspectionsList.data, action?.payload])
            : state.inspectionsList.data,
          errors: [],
        },
      }
    case GET_INSPECTION_FAIL:
      return {
        ...state,
        inspection: {
          ...state.inspection,
          isLoading: false,
          errors: action?.payload,
        },
      }

    // GET INSPECTIONS

    case GET_INSPECTIONS_START:
      return {
        ...state,
        inspectionsList: {
          ...state.inspectionsList,
          msg: '',
          data: state.inspectionsList.data,
          success: false,
          isLoading: true,
          errors: [],
        },
      }
    case GET_INSPECTIONS_SUCCESS:
      return {
        ...state,
        inspectionsList: {
          success: true,
          isLoading: false,
          data: uniqueById([...state.inspectionsList.data, ...action?.payload]),
          errors: [],
        },
      }
    case GET_INSPECTIONS_FAIL:
      return {
        ...state,
        inspectionsList: {
          ...state.inspectionsList,
          isLoading: false,
          errors: action?.payload,
        },
      }

    // CREATE INSPECTION

    case CREATE_INSPECTION_RESET:
      return {
        ...state,
        createdInspection: {
          isLoading: false,
          errors: [],
          data: {},
          msg: '',
        },
      }
    case CREATE_INSPECTION_START:
      return {
        ...state,
        createdInspection: {
          msg: 'Creating...',
          data: {},
          success: false,
          isLoading: true,
          errors: [],
        },
      }
    case CREATE_INSPECTION_SUCCESS:
      const inspList = [...state.inspectionsList.data] //slice()
      const foundInspIdx = inspList.findIndex((d) => d?._id === action?.payload?.inspection?._id)
      const inspData = foundInspIdx === -1 ? [...inspList, action.payload?.inspection] : inspList
      return {
        ...state,
        createdInspection: {
          ...action?.payload,
          success: true,
          isLoading: false,
        },
        inspectionsList: {
          data: inspData,
          success: true,
          isLoading: false,
        },
      }
    case CREATE_INSPECTION_FAIL:
      return {
        ...state,
        createdInspection: {
          ...state.createdInspection,
          isLoading: false,
          errors: action?.payload,
        },
      }

    // UPDATE INSPECTION

    case UPDATE_INSPECTION_RESET:
      return {
        ...state,
        updatedInspection: {
          msg: '',
          data: {},
          success: false,
          isLoading: true,
          errors: [],
        },
      }
    case UPDATE_INSPECTION_START:
      return {
        ...state,
        updatedInspection: {
          ...state.updatedInspection,
          msg: 'Updating! Please wait...',
          data: {},
          success: false,
          isLoading: true,
          errors: [],
        },
      }
    case UPDATE_INSPECTION_SUCCESS:
      const updatedInspections = action?.payload?.inspection?._id
        ? state.inspectionsList.data.filter(
            (ins) => String(ins._id) !== action?.payload?.inspection?._id,
          )
        : state.inspectionsList.data
      return {
        ...state,
        updatedInspection: {
          success: true,
          isLoading: false,
          data: action?.payload,
          msg: action?.payload?.msg,
          errors: [],
        },
        inspectionsList: {
          data: action?.payload?.inspection?._id
            ? [...updatedInspections, action?.payload.inspection]
            : state.inspectionsList.data,
        },
      }
    case UPDATE_INSPECTION_FAIL:
      return {
        ...state,
        updatedInspection: {
          ...state.updatedInspection,
          isLoading: false,
          errors: action?.payload,
        },
      }

    // GET SIGNATURE

    case GET_SIGNATURE_SUCCESS:
      return {
        ...state,
        signaturesList: {
          success: true,
          isLoading: false,
          data: uniqueById([...state.signaturesList.data, action?.payload]),
          errors: [],
        },
      }
    case GET_SIGNATURE_FAIL:
      return {
        ...state,
        signaturesList: {
          ...state.signaturesList,
          isLoading: false,
          errors: action?.payload,
        },
      }

    // UPDATE SIGNATURE

    case UPDATE_SIGNATURE_SUCCESS:
      const updatedSignatures = action?.payload?.signature?._id
        ? state.signaturesList.data.filter(
            (sig) => String(sig?._id) !== action?.payload?.signature?._id,
          )
        : state.signaturesList.data
      return {
        ...state,
        updatedSignature: {
          success: true,
          isLoading: false,
          data: action?.payload,
          msg: action?.payload?.msg,
          errors: [],
        },
        signaturesList: {
          success: true,
          isLoading: false,
          data: action?.payload?.signature?._id
            ? [...updatedSignatures, action?.payload.signature]
            : state.signaturesList.data,
        },
      }
    case UPDATE_SIGNATURE_FAIL:
      return {
        ...state,
        updatedSignature: {
          ...state.updatedSignature,
          isLoading: false,
          errors: action?.payload,
        },
      }

    // GET PRODUCTS

    case GET_PRODUCTS_START:
      return {
        ...state,
        productsList: { ...state.productsList, success: false, isLoading: true, errors: [] },
      }
    case GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        productsList: {
          data: action.payload,
          success: true,
          isLoading: false,
        },
      }
    case GET_PRODUCTS_FAIL:
      return {
        ...state,
        productsList: {
          ...state.productsList,
          isLoading: false,
          errors: action?.payload,
        },
      }

    // CREATE PRODUCT

    case CREATE_PRODUCTS_RESET:
      return {
        ...state,
        createdProduct: {
          isLoading: false,
          errors: [],
          data: {},
          msg: '',
        },
      }
    case CREATE_PRODUCTS_START:
      return {
        ...state,
        createdDProduct: {
          ...state.createdProduct,
          msg: 'Creating ...',
          success: false,
          isLoading: true,
          errors: [],
        },
      }
    case CREATE_PRODUCT_SUCCESS:
      const prodList = [...state.productsList.data] //slice()
      const foundProdIdx = prodList.findIndex((d) => d?._id === action?.payload?.product?._id)
      const prodData = foundProdIdx === -1 ? [...prodList, action.payload?.product] : prodList
      return {
        ...state,
        createdProduct: {
          ...action.payload,
          success: true,
          isLoading: false,
        },
        productsList: {
          data: prodData,
          success: true,
          isLoading: false,
        },
      }
    case CREATE_PRODUCTS_FAIL:
      return {
        ...state,
        createdProduct: {
          ...state.createdProduct,
          isLoading: false,
          errors: action?.payload,
        },
      }

    // UPDATE PRODUCT

    case UPDATE_PRODUCT_RESET:
      return {
        ...state,
        updatedProduct: {
          msg: '',
          data: {},
          success: false,
          isLoading: true,
          errors: [],
        },
        deactivatedProduct: {
          msg: '',
          data: {},
          isLoading: false,
          errors: [],
        },
      }
    case UPDATE_PRODUCT_START:
      return {
        ...state,
        updatedProduct: {
          msg: 'Updating! Please wait ...',
          data: {},
          success: false,
          isLoading: true,
          errors: [],
        },
      }
    case UPDATE_PRODUCT_SUCCESS:
      const filteredProds = state.productsList.data.filter(
        (d) => d._id !== action?.payload?.product?._id,
      )
      return {
        ...state,
        updatedProduct: {
          ...action?.payload,
          success: true,
          isLoading: false,
        },
        productsList: {
          data: [...filteredProds, action?.payload?.product],
          // data: [...state.productsList.data, action.payload],
          success: true,
          isLoading: false,
        },
      }
    case UPDATE_PRODUCT_FAIL:
      return {
        ...state,
        updatedProduct: {
          ...state.updatedProduct,
          isLoading: false,
          errors: action?.payload,
        },
      }

    // DELETE PRODUCT
    case DELETE_PRODUCT_SUCCESS:
      const remainingProds = state.productsList.data.filter(
        (d) => d._id !== action?.payload?.product?._id,
      )
      return {
        ...state,
        updatedProduct: {
          ...action?.payload,
          success: true,
          isLoading: false,
        },
        productsList: {
          data: remainingProds,
          // data: [...state.productsList.data, action.payload],
          success: true,
          isLoading: false,
        },
      }

    // CUSTOMER PRODUCTS

    case GET_CUSTOMER_PRODUCTS_START:
      return {
        ...state,
        selectedCustomerProducts: {
          data: [],
          success: false,
          isLoading: true,
          errors: [],
        },
      }
    case GET_CUSTOMER_PRODUCTS_SUCCESS:
      return {
        ...state,
        selectedCustomerProducts: {
          data: action.payload,
          success: true,
          isLoading: false,
        },
      }
    case GET_CUSTOMER_PRODUCTS_FAIL:
      return {
        ...state,
        selectedCustomerProducts: {
          ...state.selectedCustomerProducts,
          isLoading: false,
          errors: action?.payload,
        },
      }

    // PRODUCT LOGS

    case GET_PRODUCT_LOGS_START:
      return {
        ...state,
        productsLogs: { ...state.productsLogs, success: false, isLoading: true, errors: [] },
      }
    case GET_PRODUCT_LOGS_SUCCESS:
      return {
        ...state,
        productsLogs: {
          data: action.payload,
          success: true,
          isLoading: false,
        },
      }
    case GET_PRODUCT_LOGS_FAIL:
      return {
        ...state,
        productsLogs: {
          ...state.productsLogs,
          isLoading: false,
          errors: action?.payload,
        },
      }

    // VEHICLE PRODUCTS

    case GET_VEHICLE_PRODUCTS_START:
      return {
        ...state,
        selectedVehicleProducts: {
          data: [],
          success: false,
          isLoading: true,
          errors: [],
        },
      }
    case GET_VEHICLE_PRODUCTS_SUCCESS:
      return {
        ...state,
        selectedVehicleProducts: {
          data: action.payload,
          success: true,
          isLoading: false,
        },
      }
    case GET_VEHICLE_PRODUCTS_FAIL:
      return {
        ...state,
        selectedVehicleProducts: {
          ...state.selectedVehicleProducts,
          isLoading: false,
          errors: action?.payload,
        },
      }

    // REPORTS

    case GET_TRIPSREPORT_SUCCESS:
      const trList = [...state.tripsReport.data] //slice()
      const foundTrIdx = trList.findIndex((tr) => tr?.constraint === action?.payload?.constraint)
      const trData =
        foundTrIdx === -1
          ? [...trList, action.payload]
          : trList.splice(foundTrIdx, 1, action.payload)
      return {
        ...state,
        tripsReport: {
          success: true,
          isLoading: false,
          data: trData,
          errors: [],
        },
      }
    case GET_TRIPSREPORT_FAIL:
      return {
        ...state,
        tripsReport: {
          ...state.tripsReport,
          isLoading: false,
          errors: action?.payload,
        },
      }

    default:
      return state
  }
}

export default reducer
