import React from 'react'
import PropTypes from 'prop-types'

function AlertMessage({ children, type }) {
  return (
    <div
      className={`alert alert-${type ? type : 'info'} grad-${
        type === 'danger' ? 'danger' : type === 'warning' ? 'warn' : 'success'
      } shadow-gray border-0`}
      role="alert"
    >
      {children}
    </div>
  )
}

AlertMessage.propTypes = {
  children: PropTypes.any,
  type: PropTypes.any,
}

export default React.memo(AlertMessage)
