//DO NOT CHANGE PLAN NAMES
const subscriptionsPlans = [
  {
    name: 'basic',
    price: 15,
    features: [
      '7-day FREE Trial',
      'Branding',
      'Unlimited Facilities',
      // 'Unilimited Drivers'
    ],
  },
  { name: 'basic_SMS', price: 25, features: ['...All Basic features', 'Send SMS to Clients'] },
  { name: 'basic_GPS', price: 25, features: ['...All Basic features', 'GPS Device Service'] },
  {
    name: 'basic_DASHCAM',
    price: 65,
    features: ['...All Basic features', 'GPS Device Service', 'Dash Cam Service'],
  },
  {
    name: 'basic_GPS_DASHCAM',
    price: 75,
    features: ['...All Basic features', 'GPS Device Service', 'Dash Cam Service'],
  },
  {
    name: 'SMS_GPS',
    price: 35,
    features: ['...All Basic features', 'Send SMS to Clients', 'GPS Device Service'],
  },
  {
    name: 'SMS_GPS_DASHCAM',
    price: 85,
    features: [
      '...All Basic features',
      'Send SMS to Clients',
      'GPS Device Service',
      'Dash Cam Service',
    ],
  },
  // { name: 'plus', price: 25, features: ['...All Basic features', 'Send SMS to Clients'] },
  {
    name: 'enterprise',
    price: 0,
    features: ['For Large Companies', 'Contact us'],
  },
  // { name: 'premium', price: 35 },
]

export default subscriptionsPlans
