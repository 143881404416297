import { toast } from 'react-toastify'
import socket from 'src/services/socket'
import { axiosPrivate, axiosPublic } from '../services/api'
import { BASE_URL } from '../services/api'
import {
  GET_BROKERS_PRICING_START,
  GET_BROKERS_PRICING_SUCCESS,
  // GET_BROKERS_PRICING_FAIL,
  UPDATE_BROKER_PRICING_START,
  UPDATE_BROKER_PRICING_FAIL,
} from '../constants'

import handleErr from 'src/utils/handle-error'
import { defaultBrokerPricing } from 'src/utils/defaultBrokerPricing'

export const getBrokersPricingAction = (payload) => async (dispatch) => {
  dispatch({ type: GET_BROKERS_PRICING_START })
  const { cb, company, broker, publicReq } = payload
  const defBrokerPricing = [{ company, broker, ...defaultBrokerPricing }]
  try {
    const url = `${BASE_URL}/brokersPricing/companies?${broker ? `&broker=${broker}` : ''}${
      company ? `&company=${company}` : ''
    }`.trim()

    // const res = await fetchPrivate({ url })
    // const data = await res.json()
    const ax = publicReq ? axiosPublic : await axiosPrivate()
    const { data = {} } = await ax.get(url)
    const { success, brokersPricing } = data
    const dBrokersPricing = brokersPricing?.length ? brokersPricing : defBrokerPricing
    if (success) {
      dispatch({ type: GET_BROKERS_PRICING_SUCCESS, payload: dBrokersPricing })
    } else {
      const err = 'Something went wrong, please try again later'
      handleErr({
        err,
      })
    }
    cb && cb({ brokersPricing: defBrokerPricing })
  } catch (err) {
    handleErr({ err })
    cb && cb({ brokersPricing: defBrokerPricing })
  }
}
//to app.js with socket

//to app.js with socket
export const updateBrokerPricingAction = (payload) => async (dispatch) => {
  dispatch({ type: UPDATE_BROKER_PRICING_START })
  try {
    const { userInfo, cb } = payload
    // console.log(payload)
    if (!socket.connected && userInfo?._id) socket.connect()
    socket.emit(`updateBrokerPricing`, payload)
    socket.once(`brokerPricingUpdated${userInfo._id}`, (dbPayload) => {
      // console.log(dbPayload)
      const { success, errors } = dbPayload
      if (success) {
        toast.success(`Updated Succesfully`, { toastId: userInfo._id })
        cb && cb()
      } else {
        toast.success(`Error Updating`, { toastId: userInfo._id })
        dispatch({ type: UPDATE_BROKER_PRICING_FAIL, payload: errors })
      }
    })
  } catch (err) {
    handleErr({
      err,
      dispatch,
      alert: {
        msg: `Error Updating`,
      },
      reduxType: UPDATE_BROKER_PRICING_FAIL,
    })
  }
}
