import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { CModal, CModalHeader, CModalTitle, CModalBody, CButton, CModalFooter } from '@coreui/react'
import { dragger } from 'src/utils/dragger'
// import CIcon from '@coreui/icons-react'
// import { cilCameraControl } from '@coreui/icons'
// import Draggable from 'react-draggable'

const AppModal = ({ ...props }) => {
  const {
    customClassName,
    modalDraggable = true,
    modalResize,
    modalId,
    noHeader,
    noCloseBtn,
    saveModal,
    modalTitle,
    modalBody,
    modalBeforeBodyEnd,
    footerContent,
    modalVisible,
    toggleModal,
    modalType, //filterTripByDateModal, onTripModal, confirmActionModal, liveLocationModal, tripNotesModal, tripOptionsModal
    // modalIsOnTrip,
    userIsDriver,
    cb,
  } = props
  // console.log(props)

  // const [isVisible, setIsVisible] = useState(modalVisible)

  const mounted = useRef(false)

  useEffect(() => {
    mounted.current = true
    // const gModal = document.querySelectorAll('.global-modal')

    // gModal?.classList?.add('show-easy')
    // Array.from(gModal).forEach((m) => m?.classList?.add('show-easy'))
    // setTimeout(() => {
    //   Array.from(gModal).forEach((m) => m?.classList?.remove('opacity0'))

    //   // document.querySelector('.opacity0')?.remove('opacity0')
    // }, 1)
    return () => {
      mounted.current = false
    }
  }, [props])

  // useEffect(() => {
  //   setIsVisible(modalVisible)
  // }, [modalVisible])

  useEffect(() => {
    setTimeout(() => {
      if (document.querySelectorAll(`[aria-label="Close"]`)?.length) {
        document.querySelectorAll(`[aria-label="Close"]`).forEach((closeBtn) => {
          // console.log(closeBtn)
          // console.log(noCloseBtn)
          if (noCloseBtn) {
            closeBtn?.classList?.add('invisible')
            return
          }
          closeBtn?.addEventListener('click', (e) => {
            e?.preventDefault && e.preventDefault()
            // if (mounted.current) setIsVisible(false)
            if (mounted.current && toggleModal) toggleModal({ closeModal: true })
            // document.querySelectorAll(`[class*="modal"]`).forEach((modal) => modal.remove())
            if (
              userIsDriver
              //   modalType !== 'addEditTripModal' &&
              //   modalType !== 'liveLocationModal' &&
              //   modalType !== 'dateModal' &&
              //   modalType !== 'tripOptionsModal' &&
              //   modalType !== 'tripNotesModal'
            ) {
              window.scrollTo(0, 0)
            }
          })
        })
      }
    }, 77)
  }, [modalVisible, noCloseBtn, toggleModal, userIsDriver])

  useEffect(() => {
    if (mounted.current && modalDraggable) {
      setTimeout(() => {
        dragger({
          handle: document.querySelector(`.modal-header`),
          // handle: document.querySelector(`.modal-drag-handle`),
          el: document.querySelector(`.modal-content`),
          // offSet: {
          //   y: 150,
          //   x: 100,
          // },
        })
      }, 707)
    }
  }, [modalDraggable])

  return (
    // <Draggable
    //   disabled={!modalDraggable}
    //   handle={`.modal`}
    //   // defaultPosition={{ x: 0, y: 0 }}
    //   // position={modalType !== 'liveLocationModal' ? { x: 0, y: 0 } : null}
    // >
    <CModal
      // draggable={modalDraggable}
      id={modalId}
      className={`global-modal ${customClassName || ''} ${modalVisible ? 'show' : ''} ${
        modalType === 'onTripModal' || modalType === 'confirmActionModal'
          ? `${modalType} d-flex`
          : `d-block position-static ${modalType}`
      } ${modalResize ? 'resize' : ''}`}
      alignment="center"
      // fullscreen={true}
      fullscreen={modalType === 'onTripModal'}
      // backdrop={'static'}
      backdrop={!modalDraggable}
      // keyboard={true} //Closes the modal when escape key is pressed.
      transition={false}
      // portal={false}
      // size={} 'sm' | 'lg' | 'xl'
      scrollable={true}
      // scrollable
      // visible={isVisible}
      visible={modalVisible}
      // onShow={}
      // onClosePrevented={}
      unmountOnClose={false}
      onClose={(e) => {
        // e?.preventDefault()
        if (
          userIsDriver
          // modalType !== 'addEditTripModal' &&
          //   modalType !== 'confirmActionModal' &&
          //   modalType !== 'liveLocationModal' &&
          //   modalType !== 'dateModal' &&
          //   modalType !== 'tripOptionsModal' &&
          //   modalType !== 'tripNotesModal'
        ) {
          window.scrollTo(0, 0)
        }
        // if (modalVisible) toggleModal({ closeModal: true })
        // toggleModal({ modalVisible: false })
        cb && cb()
      }}
    >
      {/* {modalIsOnTrip && userIsDriver ? null : ( */}
      {props.isOnLine === false ? (
        // <span className="modalOfflineNotification">Connection Error !!!</span>
        <div
          className={`modalOfflineNotification ${
            modalType === 'onTripModal' ? ' p-0' : ' bg-danger'
          }`}
        >
          <span>{modalType === 'onTripModal' ? 'Working Offline' : 'Connection Error !!!'}</span>
        </div>
      ) : null}
      {noHeader ? null : (
        <CModalHeader className={`${modalDraggable ? 'c-grab' : ''} float-end text-end`}>
          {/* {modalDraggable ? (
            <CIcon className="modal-drag-handle" icon={cilCameraControl} size="xl" />
          ) : null} */}
          {modalTitle ? <CModalTitle>{modalTitle}</CModalTitle> : ''}
        </CModalHeader>
      )}
      <CModalBody
        className={modalType !== 'addEditTripModal' ? 'text-center' : ''}
        style={{ transform: 'none' }}
      >
        {modalBody}
        {modalBeforeBodyEnd}
      </CModalBody>
      {saveModal || footerContent ? (
        <CModalFooter>
          {footerContent}
          {saveModal && <CButton color="primary">Save</CButton>}
        </CModalFooter>
      ) : (
        ''
      )}
    </CModal>
    // </Draggable>
  )
}

AppModal.propTypes = {
  modalId: PropTypes.string,
  noHeader: PropTypes.bool,
  noCloseBtn: PropTypes.bool,
  saveModal: PropTypes.func,
  cb: PropTypes.func,
  toggleModal: PropTypes.func,
  modalTitle: PropTypes.string,
  modalBody: PropTypes.any,
  customClassName: PropTypes.string,
  modalBeforeBodyEnd: PropTypes.any,
  footerContent: PropTypes.any,
  modalVisible: PropTypes.any,
  modalType: PropTypes.string,
  modalIsOnTrip: PropTypes.any,
  userIsDriver: PropTypes.any,
  modalDraggable: PropTypes.bool,
  modalResize: PropTypes.bool,
  isOnLine: PropTypes.bool,
}

export default React.memo(AppModal)
