import { getPlanName } from './helpers'
import subscriptionsPlans from './subscriptions-plan'

const getPlanPrice = ({ activeAgreement }) =>
  subscriptionsPlans.find(
    (p) =>
      p?.name.toLowerCase() ===
      getPlanName({ agreement: activeAgreement })?.toLowerCase().replace('+', '_'),
  )?.price || 1

export default getPlanPrice
